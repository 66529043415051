import {
  AdditionalFeaturesIcon,
  BedOutlineIcon,
  CommunityDetailIcon,
  FinancialDetailIcon,
  FloorIcon,
  HomeDesignIcon,
  HomeSecurityIcon,
  InteriorSpaceIcon,
  KitchenIcon,
  LaundaryIcon,
  LotDetailIcon,
  OutDoorFeatureIcon,
  ParkingIcon,
  SchoolsIcon,
  UtilitiesIcon,
  ViewsIcon
} from '../../../../svgIcons/Icons'
import s from './HomeDetails.module.css'
import style from '../../PropertyDetails.module.css'
import { data } from '../../PropertyDetailsData'

const HomeDetails = () => {
  return (
    <div>
      <h2 className={style.PropDetails_hedings}>Home Details</h2>
      <div className={s.HomeDetaislcategory_container}>
        {data?.HomeDetails?.Views && (
          <div className={s.subCategory_container}>
            <div className={s.sub_category}>
              <div className={s.category}>
                <ViewsIcon />
                <p>Views</p>
              </div>
              <ul className={style.ListingDetails_listMain}>
                {data?.HomeDetails?.Views?.map((item, index) => {
                  return <li key={index}>{item}</li>
                })}
              </ul>
            </div>
          </div>
        )}

        {data?.HomeDetails?.HomeDesign && (
          <div className={s.subCategory_container}>
            <div className={s.sub_category}>
              <div className={s.category}>
                <HomeDesignIcon />
                <p>HomeDesign</p>
              </div>
              <ul className={style.ListingDetails_listMain}>
                {data?.HomeDetails?.HomeDesign?.map((item, index) => {
                  return <li key={index}>{item}</li>
                })}
              </ul>
            </div>
          </div>
        )}

        {data?.HomeDetails?.interiorSpace && (
          <div className={s.subCategory_container}>
            <div className={s.sub_category}>
              <div className={s.category}>
                <InteriorSpaceIcon />
                <p>Interior Spaces</p>
              </div>
              <ul className={style.ListingDetails_listMain}>
                {data?.HomeDetails?.interiorSpace?.map((item, index) => {
                  return <li key={index}>{item}</li>
                })}
              </ul>
            </div>
          </div>
        )}

        {data?.HomeDetails?.flooring && (
          <div className={s.subCategory_container}>
            <div className={s.sub_category}>
              <div className={s.category}>
                <FloorIcon />
                <p>Flooring</p>
              </div>
              <ul className={style.ListingDetails_listMain}>
                {data?.HomeDetails?.flooring?.map((item, index) => {
                  return <li key={index}>{item}</li>
                })}
              </ul>
            </div>
          </div>
        )}

        {data?.HomeDetails?.bedAndBathDetails && (
          <div className={s.subCategory_container}>
            <div className={s.sub_category}>
              <div className={s.category}>
                <BedOutlineIcon color={'#7e7e7e'} size={'18'} />
                <p>Bedrooms and Bathrooms</p>
              </div>
              <ul className={style.ListingDetails_listMain}>
                {data?.HomeDetails?.bedAndBathDetails?.map((item, index) => {
                  return <li key={index}>{item}</li>
                })}
              </ul>
            </div>
          </div>
        )}

        {data?.HomeDetails?.laundryDetails && (
          <div className={s.subCategory_container}>
            <div className={s.sub_category}>
              <div className={s.category}>
                <LaundaryIcon />
                <p>Laundry</p>
              </div>
              <ul className={style.ListingDetails_listMain}>
                {data?.HomeDetails?.laundryDetails?.map((item, index) => {
                  return <li key={index}>{item}</li>
                })}
              </ul>
            </div>
          </div>
        )}

        {data?.HomeDetails?.securityDetails && (
          <div className={s.subCategory_container}>
            <div className={s.sub_category}>
              <div className={s.category}>
                <HomeSecurityIcon />
                <p>Home Security</p>
              </div>
              <ul className={style.ListingDetails_listMain}>
                {data?.HomeDetails?.securityDetails?.map((item, index) => {
                  return <li key={index}>{item}</li>
                })}
              </ul>
            </div>
          </div>
        )}

        {data?.HomeDetails?.parkingDetails && (
          <div className={s.subCategory_container}>
            <div className={s.sub_category}>
              <div className={s.category}>
                <ParkingIcon color={'#7e7e7e'} size={'18'} />
                <p>ParKing</p>
              </div>
              <ul className={style.ListingDetails_listMain}>
                {data?.HomeDetails?.parkingDetails?.map((item, index) => {
                  return <li key={index}>{item}</li>
                })}
              </ul>
            </div>
          </div>
        )}

        {data?.HomeDetails?.outdoorFeatures && (
          <div className={s.subCategory_container}>
            <div className={s.sub_category}>
              <div className={s.category}>
                <OutDoorFeatureIcon />
                <p>Outdoor Features</p>
              </div>
              <ul className={style.ListingDetails_listMain}>
                {data?.HomeDetails?.outdoorFeatures?.map((item, index) => {
                  return <li key={index}>{item}</li>
                })}
              </ul>
            </div>
          </div>
        )}

        {data?.HomeDetails?.kitchenDetails && (
          <div className={s.subCategory_container}>
            <div className={s.sub_category}>
              <div className={s.category}>
                <KitchenIcon />
                <p>Kitchen</p>
              </div>
              <ul className={style.ListingDetails_listMain}>
                {data?.HomeDetails?.kitchenDetails?.map((item, index) => {
                  return <li key={index}>{item}</li>
                })}
              </ul>
            </div>
          </div>
        )}

        {data?.HomeDetails?.schoolDetails && (
          <div className={s.subCategory_container}>
            <div className={s.sub_category}>
              <div className={s.category}>
                <SchoolsIcon />
                <p>Schools</p>
              </div>
              <ul className={style.ListingDetails_listMain}>
                {data?.HomeDetails?.schoolDetails?.map((item, index) => {
                  return <li key={index}>{item}</li>
                })}
              </ul>
            </div>
          </div>
        )}

        {data?.HomeDetails?.utilitiesDetails && (
          <div className={s.subCategory_container}>
            <div className={s.sub_category}>
              <div className={s.category}>
                <UtilitiesIcon />
                <p>Utilities</p>
              </div>
              <ul className={style.ListingDetails_listMain}>
                {data?.HomeDetails?.utilitiesDetails?.map((item, index) => {
                  return <li key={index}>{item}</li>
                })}
              </ul>
            </div>
          </div>
        )}

        {data?.HomeDetails?.additionalFeatureDetails && (
          <div className={s.subCategory_container}>
            <div className={s.sub_category}>
              <div className={s.category}>
                <AdditionalFeaturesIcon />
                <p>Additional Features</p>
              </div>
              <ul className={style.ListingDetails_listMain}>
                {data?.HomeDetails?.additionalFeatureDetails?.map(
                  (item, index) => {
                    return <li key={index}>{item}</li>
                  }
                )}
              </ul>
            </div>
          </div>
        )}

        {data?.HomeDetails?.lotWidthDetails && (
          <div className={s.subCategory_container}>
            <div className={s.sub_category}>
              <div className={s.category}>
                <LotDetailIcon />
                <p>Lot details</p>
              </div>
              <ul className={style.ListingDetails_listMain}>
                {data?.HomeDetails?.lotWidthDetails?.map((item, index) => {
                  return <li key={index}>{item}</li>
                })}
              </ul>
            </div>
          </div>
        )}

        {data?.HomeDetails?.communityDetails && (
          <div className={s.subCategory_container}>
            <div className={s.sub_category}>
              <div className={s.category}>
                <CommunityDetailIcon />
                <p>Community Details</p>
              </div>
              <ul className={style.ListingDetails_listMain}>
                {data?.HomeDetails?.communityDetails?.map((item, index) => {
                  return <li key={index}>{item}</li>
                })}
              </ul>
            </div>
          </div>
        )}

        {data?.HomeDetails?.financialDetails && (
          <div className={s.subCategory_container}>
            <div className={s.sub_category}>
              <div className={s.category}>
                <FinancialDetailIcon />
                <p>Listing and Financial Details</p>
              </div>
              <ul className={style.ListingDetails_listMain}>
                {data?.HomeDetails?.financialDetails?.map((item, index) => {
                  return <li key={index}>{item}</li>
                })}
              </ul>
            </div>
          </div>
        )}
      </div>
    </div>
  )
}

export default HomeDetails
