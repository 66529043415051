import style from '../../PropertyDetails.module.css'
import { data } from '../../PropertyDetailsData'
import s from './PricingAndFee.module.css'

const PricingAndFee = () => {
  return (
    <div>
      <h2 className={style.PropDetails_hedings}>Pricing and fees</h2>
      <div className={s.fee_subcategorycontainer}>
        <div className={`${s.fee_category} ${s.fee_headAndDes}`}>
          <div>
            <h6 className={style.sub_headings}>Available unit price</h6>
            <p>From $419,900 to over $1,029,900</p>
          </div>
          <div>
            <h6 className={style.sub_headings}>C.C/maint</h6>
            <p>$0.69 Per SqFt per Month</p>
          </div>
          <div>
            <h6 className={style.sub_headings}>Cost to purchase parking</h6>
            <p>$7,500.00 (LOCKER FEES $19.95)</p>
          </div>
          <div>
            <h6 className={style.sub_headings}>Average price per sqft</h6>
            <p>$835 - $1333 per SqFt</p>
          </div>
          <div>
            <h6 className={style.sub_headings}>Co-op fee realtors</h6>
            <p>4%</p>
          </div>
        </div>

        <div className={s.fee_category}>
          <h6 className={style.sub_headings}>Deposit Structure</h6>
          <ul className={style.ListingDetails_listMain}>
            {data?.PricingAndFee[0]?.DepositStructure.map((item, i) => (
              <li key={i}>{item}</li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  )
}

export default PricingAndFee
