import s from "./Auth.module.css";
import logo from "../../../assets/images/Logo.png";
import Circle1 from "../../../assets/images/AuthCircle_1.png";
import { NavLink } from "react-router-dom";

const AuthLayout = () => {
  return (
    <div className={s.AuthLayout_main}>
      <div className={s.circle1}>
        <img src={Circle1} alt="authlayout_circle1" />
      </div>

      <div className={s.AuthSide_logo}>
        <img src={logo} alt="side_logo" />
      </div>

      <div className={s.Auth_centerLogo}>
        <NavLink to="/">
          <img src={logo} alt="center_logo" />
        </NavLink>
      </div>
    </div>
  );
};

export default AuthLayout;
