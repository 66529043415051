import { MapLocationIcon } from '../../../svgIcons/Icons'

const MapBtn = ({mainClassName,onClick}) => {
  return (
    <div>
      <button
        className={`${mainClassName} d-flex align-items-center`}
        onClick={onClick}
      >
        <MapLocationIcon />
        <span className='ms-1'>Map</span>
      </button>
    </div>
  )
}

export default MapBtn
