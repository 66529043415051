import ProfessionalsDetailLayout from '../Components/layout/ProfessionalDetailsLayout'
import { HomeImprSpecialitiesData } from '../../../../data/constant'

const HomeImprovementDetails = () => {
  const updatedOptionsData = HomeImprSpecialitiesData?.map(item => {
    if (item === 'Any') {
      return 'All Reviews'
    }
    return item
  })
  return (
    <div>
      <ProfessionalsDetailLayout
        options={updatedOptionsData}
        selectedForm={'HomeImpRev'}
      />
    </div>
  )
}

export default HomeImprovementDetails
