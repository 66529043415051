import React, { useEffect } from "react";
import { BrowserRouter, Routes, Route, useLocation } from "react-router-dom";
import {
  Home,
  PropertyDetails,
  LogIn,
  SignUp,
  SuccessfullyRegister,
  ResetPassword,
  ScheduleConsultation,
  AboutUs,
  ContactUs,
  CoShopping,
  Notes,
  Advisor,
  AdvisorDetails,
  EstateAgent,
  EstateAgentDetails,
  PropertyManager,
  PropertyManagerDetails,
  Builders,
  BuilderDetails,
  HomeImprovement,
  HomeImprovementDetails,
  Inspectors,
  InspectorDetails,
  Photographer,
  PhotographerDetails,
  OtherAgents,
  BuyingBlogs,
  SellingBlogs,
  EstateNewsBlogs,
  HeartFindHomesBlogs,
} from "../components/pages";

function ScrollToTop({ children }) {
  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);
  return children;
}

const AppRoutes = () => {
  return (
    <BrowserRouter>
      <ScrollToTop>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/logIn" element={<LogIn />} />
          <Route path="/signUp" element={<SignUp />} />
          <Route path="/reset-password" element={<ResetPassword />} />
          <Route path="/account-created" element={<SuccessfullyRegister />} />
          <Route path="/schedule-consultation" element={<ScheduleConsultation />} />
          <Route path="/about" element={<AboutUs />} />
          <Route path="/contact-us" element={<ContactUs />} />
          <Route path="/co-shopping" element={<CoShopping />} />
          <Route path="/notes" element={<Notes />} />
          <Route path="/property-details/:id" element={<PropertyDetails />} />
          <Route path="/advisors" element={<Advisor />} />
          <Route path="/advisor-details/:id" element={<AdvisorDetails />} />
          <Route path="/estate-agent" element={<EstateAgent />} />
          <Route path="/estate-agent-details/:id" element={<EstateAgentDetails />} />
          <Route path="/property-managers" element={<PropertyManager />} />
          <Route path="/property-manager-details/:id" element={<PropertyManagerDetails />} />
          <Route path="/builders" element={<Builders />} />
          <Route path="/builder-details/:id" element={<BuilderDetails />} />
          <Route path="/home-improvement" element={<HomeImprovement />} />
          <Route path="/home-improvement-details/:id" element={<HomeImprovementDetails />} />
          <Route path="/inspectors" element={<Inspectors />} />
          <Route path="/inspector-details/:id" element={<InspectorDetails />} />
          <Route path="/photographers" element={<Photographer />} />
          <Route path="/photographer-details/:id" element={<PhotographerDetails />} />
          <Route path="/other-agents" element={<OtherAgents />} />
          <Route path="/blogs/buying" element={<BuyingBlogs />} />
          <Route path="/blogs/selling" element={<SellingBlogs />} />
          <Route path="/blogs/real-estate-news" element={<EstateNewsBlogs />} />
          <Route path="/blogs/heart-find-homes" element={<HeartFindHomesBlogs />} />
        </Routes>
      </ScrollToTop>
    </BrowserRouter>
  );
};
export default AppRoutes;
