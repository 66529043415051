import React, { useState, useEffect, useRef } from 'react'
import Box from '@mui/material/Box'
import Modal from '@mui/material/Modal'
import s from '../../../pages/Notes/Notes.module.css'
import {
  CloseIcon,
  DeleteIcon,
  EditIcon,
  SaveNotesIcon
} from '../../../svgIcons/Icons'

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  borderRadius: '20px',
  border: '0.5px solid #D9D9D9',
  boxShadow: 24,
  background: '#fff',
  padding: '20px'
}

const NotePadModal = ({
  isNotepadModalOpen,
  setIsNotepadModalOpen,
  NotesDes,
}) => {
  const [isEdit, setIsEdit] = useState(false)
  const [updatedDes, setUpdatedDes] = useState(NotesDes)
  const textareaRef = useRef(null)

  useEffect(() => {
    if (isEdit && textareaRef.current) {
      textareaRef.current.focus()
      textareaRef.current.setSelectionRange(
        textareaRef.current.value.length,
        textareaRef.current.value.length
      )
    }
  }, [isEdit])

  const handleClose = () => {
    setIsNotepadModalOpen(false)
    setIsEdit(false)
  }

  const handleNotesDesChange = e => {
    setUpdatedDes(e.target.value)
  }

  const handleNoteSave = e => {
    setIsEdit(false)
    setIsNotepadModalOpen(false)
  }

  return (
    <div>
      <Modal
        open={isNotepadModalOpen}
        onClose={handleClose}
        aria-labelledby='modal-modal-title'
        aria-describedby='modal-modal-description'
      >
        <Box sx={style}>
          <header id='modal-modal-title' className={s.Modal_heading}>
            <h3>Note</h3>
            <div className={s.CloseIcon} onClick={handleClose}>
              <CloseIcon />
            </div>
          </header>

          <hr className='mt-2 mb-3' />

          <textarea
            disabled={!isEdit}
            rows={14}
            placeholder='Jot down details, impressions, and important factors to help inform your decision-making process.'
            className={s.ModalTextera}
            value={updatedDes}
            ref={textareaRef}
            onChange={handleNotesDesChange}
          ></textarea>

          <div className='d-flex justify-content-between mt-3'>
            {isEdit && (
              <button
                className={`button ${s.FullViewBtn}`}
                style={{ padding: '.5rem 3.2rem' }}
                onClick={handleNoteSave}
              >
                <SaveNotesIcon /> Save
              </button>
            )}
            {!isEdit && (
              <button
                className={`button ${s.FullViewBtn}`}
                style={{ padding: '.5rem 3.5rem' }}
                onClick={() => setIsEdit(true)}
              >
                <EditIcon /> Edit
              </button>
            )}
            <button
              className={`button ${s.DeleteBtn}`}
              style={{ padding: '.5rem 3rem' }}
            >
              <DeleteIcon /> Delete
            </button>
          </div>
        </Box>
      </Modal>
    </div>
  )
}

export default NotePadModal
