import { NotesCard, Footer } from '../../common'
import s from './Notes.module.css'

const Notes = () => {
  const data = [
    {
      propImg: '/assets/images/bridge.jpeg',
      propPrice: '20,00,000',
      propAddress: '2151 Kingdom Road, Toronto, ON',
      propBed: '2',
      propName: 'Emerson House',
      propBath: '4',
      propArea: '350',
      propNotes:
        'Jot down details, impressions, and important factors to help inform your decision-making process.'
    },
    {
      propImg: '/assets/images/bridge.jpeg',
      propPrice: '20,00,000',
      propName: 'Emerson House',
      propAddress: '2151 Kingdom Road, Toronto, ON',
      propBed: '2',
      propBath: '4',
      propArea: '350',
      propNotes:
        'Jot down details, impressions, and important factors to help inform your decision-making process.'
    },
    {
      propImg: '/assets/images/bridge.jpeg',
      propPrice: '20,00,000',
      propName: 'Emerson House',
      propAddress: '2151 Kingdom Road, Toronto, ON',
      propBed: '2',
      propBath: '4',
      propArea: '350',
      propNotes:
        'Jot down details, impressions, and important factors to help inform your decision-making process.'
    },
    {
      propImg: '/assets/images/bridge.jpeg',
      propPrice: '20,00,000',
      propName: 'Emerson House',
      propAddress: '2151 Kingdom Road, Toronto, ON',
      propBed: '2',
      propBath: '4',
      propArea: '350',
      propNotes:
        'Jot down details, impressions, and important factors to help inform your decision-making process.'
    },
    {
      propImg: '/assets/images/bridge.jpeg',
      propPrice: '20,00,000',
      propName: 'Emerson House',
      propAddress: '2151 Kingdom Road, Toronto, ON',
      propBed: '2',
      propBath: '4',
      propArea: '350',
      propNotes:
        'Jot down details, impressions, and important factors to help inform ot down details, impressions, and your decision-making process. Jot down details, impressions, and important factors to help inform Jot down details, impressions, and important factor your decision'
    }
  ]

  return (
    <>
      <div className={`${s.main} mt-5`}>
        <div className={s.Main_heading}>
          <h1>NoteStream</h1>
        </div>

        <div className={s.cardsAll_main}>
          {data?.map((item, i) => {
            return <NotesCard key={i} data={item} />
          })}
        </div>
      </div>

      <div className='mt-5'>
        <Footer />
      </div>
    </>
  )
}

export default Notes
