import s from '../Professionals.module.css'
import { useState } from 'react'
import { Footer, ProfessionalsCard } from '../../../common'
import FilterSearchCriteria from '../Components/FilterSearchCriteria'
import FilterPagesHeader from '../Components/FilterPagesHeader'
import { data } from '../PropertyManagers/PropertyManagerData'
import { HomeImprSpecialitiesData } from '../../../../data/constant'

const HomeImprovement = () => {
  const [filteredCardData, setFilteredCardData] = useState([])

  return (
    <>
      <div className={s.AgentMain}>
        <div>
          <FilterPagesHeader page={'Home Improvement'} />
        </div>

        <div className={`${s.Prof_mainContainer} mt-4`}>
          <FilterSearchCriteria
            data={data}
            setFilteredCardData={setFilteredCardData}
            placeholder={'Name'}
            options={HomeImprSpecialitiesData}
          />

          <div className={`${s.pageMain_heading} mt-3`}>
            <h2>Home Imrovements</h2>
            {filteredCardData?.length > 0 ? (
              <p>{`${filteredCardData?.length.toLocaleString(
                'en-Us'
              )} professionals found`}</p>
            ) : (
              <p>{`No professionals found`}</p>
            )}
          </div>

          <hr
            className='mt-4 mb-4'
            style={{
              color: '#d9d9d9',
              height: '2px',
              backgroundColor: '#d9d9d9'
            }}
          />

          <div className={s.agentsCardSec}>
            <div className='w-100'>
              <ProfessionalsCard
                deatilsPath={'home-improvement-details'}
                data={filteredCardData}
              />
            </div>
          </div>
        </div>
      </div>

      <div className='mt-5'>
        <Footer />
      </div>
    </>
  )
}

export default HomeImprovement
