import "./style.css";
import StarRating from "../../Rating/StarRating";
import { AgentRatingStar } from "../../../svgIcons/Icons";

const ProfessionalReviewCard = () => {
  return (
    <div className="agentReviews_card">
      <div className="d-flex w-100 justify-content-between">
        <div className="d-flex reviewerCard_profile">
          <img
            src={"/assets/images/CoShopping1.jpeg"}
            alt="EstateAgent_profile"
          />
          <div className="flex-column ms-3">
            <h6>{`Shahnoor Ali`}</h6>
            <p>{`32 Dec 2024 - jasoncapon`}</p>
          </div>
        </div>
        <div>
          <StarRating size={"20"} color={"#B0D95F"} rating={"4.0"} />
        </div>
      </div>

      <div className="tagAbout_review">
        <p>{`Sold a home in 2019 in ON L7B`}.</p>
      </div>

      <div className="review_para">
        Brian Lewis came highly recommended by my sister and brother-in-law, and
        he did not disapoint. Brian is an expert in his field, honest, reliable
        and very patient. Brian provided me with all information I needed, and
        walked me through the rental application process, without his knowledge
        I would have been lost! I wouldn't hesitate to recommend Brian to anyone
        looking for an agent. Thank you
      </div>

      <div className="mt-4">
        <div className="d-flex mt-2">
            <p className="fW-6 rating_star">{`4.0`}</p>
            <AgentRatingStar size={'20'} color={'#B0D95F'}/>
            <p className="ms-2 rating_name">Local Knowledge</p>
        </div>
        <div className="d-flex mt-2">
            <p className="fW-6 rating_star">{`4.0`}</p>
            <AgentRatingStar size={'20'} color={'#B0D95F'}/>
            <p className="ms-2 rating_name">Process expertise</p>
        </div>
        <div className="d-flex mt-2">
            <p className="fW-6 rating_star">{`4.0`}</p>
            <AgentRatingStar size={'20'} color={'#B0D95F'}/>
            <p className="ms-2 rating_name">Responsiveness</p>
        </div>
        <div className="d-flex mt-2">
            <p className="fW-6 rating_star">{`4.0`}</p>
            <AgentRatingStar size={'20'} color={'#B0D95F'}/>
            <p className="ms-2 rating_name">Negotiation skills</p>
        </div>
        </div>
    </div>
  );
};

export default ProfessionalReviewCard;
