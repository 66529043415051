import React from "react";
import { Rating } from "react-simple-star-rating";
import { AgentRatingStar, AgentRatingStarOutline } from "../../svgIcons/Icons";

const ReviewsRating = ({
  value,
  ratingChanged,
  size,
  color,
  mainClassName,
}) => {
  return (
    <Rating
      iconsCount={5}
      initialValue={0}
      allowFraction={false}
      onClick={ratingChanged}
      fillIcon={<AgentRatingStar size={size} color={color} />}
      emptyIcon={<AgentRatingStarOutline size={size} color={color} />}
      fillClassName={mainClassName}
      emptyClassName={mainClassName}
    />
  );
};

export default ReviewsRating;
