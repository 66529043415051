import Circle2 from "../../../assets/images/AuthCircle_2.png";
import { NavLink } from "react-router-dom";
import s from "../../layout/AuthSection/Auth.module.css";

const AuthFooter = () => {
  return (
    <div className={s.AuthFooterMain}>
      <NavLink to="/privacy-policy">
        <p>Privacy Policy</p>
      </NavLink>
      <NavLink to="/terms-and-conditions">
        <p>Website Terms of Use</p>
      </NavLink>
      <NavLink to="/contact-us">
        <p>Contact Us</p>
      </NavLink>

      <div className={s.circle2}>
        <img src={Circle2} alt="authlayout_circle2" />
      </div>
    </div>
  );
};

export default AuthFooter;
