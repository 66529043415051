import style from '../../PropertyDetails.module.css'
import { data } from '../../PropertyDetailsData'
import s from './CurrentIncentives.module.css'

const CurrentIncentives = () => {
  return (
    <div>
      <h2 className={style.PropDetails_hedings}>Current incentives</h2>
      <div className={s.Incentive_subcategorycontainer}>
        <div className={s.incentive_category}>
          <h6 className={style.sub_headings}>
            TOWNHOMES - LIMITED TIME INCENTIVE PROGRAM*
          </h6>
          <ul className={style.ListingDetails_listMain}>
            {data?.incentiveData[0]?.LimitedTimeProgram.map((item, i) => (
              <li key={i}>{item}</li>
            ))}
          </ul>
        </div>

        <div className={s.incentive_category}>
          <h6 className={style.sub_headings}>THE CITADEL - LIMITED TIME</h6>
          <ul className={style.ListingDetails_listMain}>
            {data?.incentiveData[0]?.citadelTimeProgram.map((item, i) => (
              <li key={i}>{item}</li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  )
}

export default CurrentIncentives
