import style from '../../PropertyDetails.module.css'
import s from '../PropertyScores/PropertyScores.module.css'
import { data } from '../../PropertyDetailsData'
import { AeroplaneIcon } from '../../../../svgIcons/Icons'

const TransitScores = () => {
  return (
    <div>
      <h2 className={style.PropDetails_hedings}>Transit</h2>

      <div className={s.scoreCard_container}>
        <div className={`${s.score_card} ${s.transitScore_card}`}>
          <div className='d-flex align-items-center'>
            <AeroplaneIcon />
            <div className='ms-3'>
              <h3>Airport</h3>
              <p>Austin-Bergstrom International</p>
            </div>
          </div>
          <div >
            <p className={s.drive_distance}>
                {data?.PropScores?.driveDistance}
            </p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default TransitScores
