import { useState } from 'react'
import './ContactAgentCard.css' // Assuming you have a 'ContactAgentCard.css' file
import { Form, Formik } from 'formik'
import {
  CustomInput,
  NumberInput,
  TimePicker,
  DatePicker
} from '../../../../common'

const ContactAgentCard = () => {
  const [timeChange, setTimeChange] = useState()
  const [dateChange, setDateChange] = useState()
  const [scheduleMeeting, setScheduleMeeting] = useState(false)
  const [isSubmited, setIsSubmited] = useState(false)
  const [message, setMessage] = useState(
    'Could you please provide me with the details 5601 Hudson Bend road Austin TX 78731 | Hudson Bend?'
  )
  const handleMsgChange = e => {
    setMessage(e.target.value)
  }

  const [data, setData] = useState({
    name: '',
    email: '',
    phoneNumber: '',
    message: message
  })

  const handleSubmit = () => {
    console.log(data)
    setIsSubmited(true)
  }

  return (
    <Formik
      initialValues={{
        data
      }}
      validate={values => values}
      onSubmit={handleSubmit}
    >
      {({ errors, touched }) => (
        <Form>
          <div className='contactAgentCard_main'>

            <div className='card_Profile'>
              <div className='card_PrImage'>
                <img
                  src={'/assets/images/CoShopping1.jpeg'}
                  alt='Card-Profile'
                />
              </div>
              <div className='ms-3'>
                <p className='Person_name'>Allah Ditta Lonywala</p>
                <p className='Person_status'>Listing Agent</p>
              </div>
            </div>

            <p className='Person_portfolioHeding'>
              KW-Austin Portfolio RealEstate
            </p>

            <div className='contactForm'>
              <div className='agentName_mail'>
                <div className='ContactInputs_div'>
                  <CustomInput
                    placeholder='Naz Gujjar'
                    name='name'
                    type='text'
                    data={data}
                    setData={setData}
                  />
                  {isSubmited && (
                    <div className='w-100 text-start'>
                      <p className='Invaild_errors m-0'>{errors.name}</p>
                    </div>
                  )}
                </div>

                <div className='ContactInputs_div'>
                  <CustomInput
                    placeholder='ghar.chahiay@gmail.com'
                    name='email'
                    type='text'
                    data={data}
                    setData={setData}
                  />
                  {isSubmited && (
                    <div className='w-100 text-start'>
                      <p className='Invaild_errors m-0'>{errors.email}</p>
                    </div>
                  )}
                </div>
              </div>

              <div className='ContactInputs_div AgentCont_inp mt-3'>
                <NumberInput name='phoneNumber' data={data} setData={setData} />
                {isSubmited && (
                  <div className='w-100 text-start'>
                    <p className='Invaild_errors m-0'>{errors.phoneNumber}</p>
                  </div>
                )}
              </div>

              <div className='mt-3 w-100'>
                <textarea
                  rows={6}
                  className='message'
                  value={message}
                  onChange={e => handleMsgChange(e)}
                ></textarea>
                {isSubmited && (
                  <div className='w-100 text-start'>
                    <p className='Invaild_errors m-0'>{errors.message}</p>
                  </div>
                )}
              </div>

              <div className='meeting_Arrange'>
                <label >
                  <input type='checkbox' name='scheduleMeeting' onClick={() => setScheduleMeeting(!scheduleMeeting)}/>
                  <span className='ms-2'>Schedule a meeting</span>
                </label>
              </div>
             

             {scheduleMeeting &&(
               <div className='dateAndTimeSchedule'>
               <TimePicker
                 mainclassName={'Agent_card_date_and_time'}
                 timeChange={timeChange}
                 setTimeChange={setTimeChange}
               />
               <DatePicker
                 mainclassName={'Agent_card_date_and_time'}
                 dateChange={dateChange}
                 setDateChange={setDateChange}
               />
             </div>
             )}

              <div className='AgentCard_contactBtn'>
                <button className={`button sendMsg_btn`}>Send Message</button>
                <button className={`button ReqCall_btn`}>Request Call</button>
              </div>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  )
}

export default ContactAgentCard
