import { NavLink } from 'react-router-dom'
import s from '../../../pages/Articles/Articles.module.css'
import { ArrowRight } from '../../../svgIcons/Icons'

const ArticleLandscapeCard = ({ item }) => {
  return (
    <div className={`${s.ArticleCard} ${s.ArticleLsCard}`}>
      <div style={{ width: '35%' }}>
        <div className={s.ArticleLsCard_Image}>
          <img src={item?.imgUrl} alt='Article_Card' />
        </div>
      </div>

      <div className='ps-2' style={{ width: '65%' }}>
        <div className={s.categoryAndRead}>
          <div className={s.ArticleCard_category}>
            <h6>{item?.category}</h6>
          </div>
          <div className={s.ArticleCard_ReadTime}>
            <h6>5 min read</h6>
          </div>
        </div>

        <div className={s.ArticleLsCardTitleDes}>
          <h3>{item?.title}</h3>
          <p>{item?.description}</p>
        </div>

        <div className={`mt-1 ${s.ArticleCardcont_read}`}>
          <NavLink>
            <p>
              Continue reading
              <span className='ps-1'>
                <ArrowRight />
              </span>
            </p>
          </NavLink>
        </div>
      </div>
    </div>
  )
}

export default ArticleLandscapeCard
