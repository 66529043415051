import { CustomSelect } from '../../../../common'
import s from '../../Professionals.module.css'
import { useState } from 'react'
import { InspectorSpecialitiesData } from '../../../../../data/constant'

const InspectorRevForm = ({ detailsData }) => {
  const [year, setYear] = useState('')
  const [service, setService] = useState('Any')

  const handleYearChange = e => {
    const currentYear = new Date().getFullYear()
    const value = e.target.value
    if (/^\d*$/.test(value) && value <= currentYear) {
      setYear(value)
    }
  }

  const handleServiceChange = e => {
    setService(e.target.value)
  }

  return (
    <>
      <div className={`${s.experience_form}`}>
        <label>Which service did he provided?</label>
        <div className='col-md-3 mt-3'>
          <CustomSelect
            name={'Select Service'}
            onChange={handleServiceChange}
            value={service}
            mainClassName={s.Agent_dropDown}
            options={InspectorSpecialitiesData}
          />
        </div>
        <div className='col-md-6 mt-3'>
          <label>Which property did they assist you with?</label>
          <input placeholder='Search for an address' type='text' />
        </div>
        <div className='mt-3'>
          <label>During which year did you collaborate?</label>

          <div className='col-md-2 col-12'>
            <input
              type='text'
              value={year}
              onChange={handleYearChange}
              placeholder='YYYY*'
            />
          </div>
        </div>
      </div>
    </>
  )
}

export default InspectorRevForm
