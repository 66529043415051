import { useNavigate } from 'react-router-dom'
import s from '../../Professionals.module.css'

const FilterPagesHeader = ({ page }) => {
  const pagesData = [
    'Agents',
    'Advisors',
    'Property Managers',
    'Builders',
    'Home Improvement',
    'Inspectors',
    'Photographers',
    'Other'
  ]

  const navigate = useNavigate()

  const handlePageFilter = item => {
    const formattedPath = item?.toLowerCase().replace(/\s+/g, '-')
    if (formattedPath === 'agents') {
      navigate('/estate-agent')
    } else if (formattedPath === 'other') {
      navigate('/other-agents')
    } else {
      navigate(`/${formattedPath}`)
    }
  }

  return (
    <main className={s.FilterHeader_main}>
      <header className={s.FilterPagesHeader}>
        <ul  className={s.pagesFilter}>
          {pagesData.map((item, index) => {
            return (
              <li
                className={item === page ? `${s.ActivePage}` : ''}
                key={index}
                onClick={() => handlePageFilter(item)}
              >
                {item}
              </li>
            )
          })}
        </ul>
      </header>
    </main>
  )
}

export default FilterPagesHeader
