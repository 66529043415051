import { NavLink } from 'react-router-dom';
import { SearchIcon } from '../../../svgIcons/Icons';
import BuyingBlogsCover from '../../../../assets/images/BlogBuying-cover.png';
import s from '../Articles.module.css';
import {ArticePortraitCard, ArticeLandscapeCard} from '../../../common';
import { data } from '../Articledata';
import Footer from '../../../common/FooterMain';

const BuyingBlogs = () => {
  const handleSearchInputChange = event => {
    console.log(event.target.value)
  }

  const Articledata = data?.filter(item => item.category === 'All about buying')

  return (
    <div>
      <div className={s.Article_cover}>
        <img src={BuyingBlogsCover} alt='BuyingBlogs-cover' />
        <div className={s.Article_coverContent}>
          <h1>All about Buying</h1>
        </div>
      </div>

      <div className={`${s.Blogs_mainPara} mt-5 px-3`}>
        <p>
          It seems like you've provided a statement about real estate
          information. If you have specific questions or need advice on buying
          real estate or the steps involved, feel free to ask!
        </p>
      </div>

      <div style={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
        <div className={`row mt-5 ${s.ArticleCard_filterSec}`}>
          {/* -------Pages Navigation Section-----*/}
          <div className={s.Article_filter}>
            <div>
              <div className={s.artile_Searching}>
                <input
                  type='text'
                  placeholder='Search'
                  onChange={handleSearchInputChange}
                />
                <SearchIcon size='24' color='#7E7E7E' />
              </div>
              <button className={`button mt-4 ${s.BlogPathBtn} ${s.ActiveBtn}`}>
                Buying
              </button>
              <NavLink to='/blogs/selling'>
                <button className={`button mt-4 ${s.BlogPathBtn}`}>
                  Selling
                </button>
              </NavLink>
              <NavLink to='/blogs/real-estate-news'>
                <button className={`button mt-4 ${s.BlogPathBtn}`}>News</button>
              </NavLink>
              <NavLink to='/blogs/heart-find-homes'>
                <button className={`button mt-4 ${s.BlogPathBtn}`}>
                  Heart finds home
                </button>
              </NavLink>
            </div>
          </div>

          {/* -------All Cards Section-----*/}

          <div className={s.ArticleCardSec}>
            {/* -------LandScape Cards Section-----*/}
            <div className='row'>
              {Articledata.slice(0, 2)?.map((item, index) => {
                return (
                  <div className='col-lg-6 col-12' key={index}>
                    <ArticeLandscapeCard item={item} />
                  </div>
                )
              })}
            </div>

            {/* -------All Reading Cards Section-----*/}

            <div className={`${s.AllArticleRead_cards}`}>
              {Articledata.slice(2, Articledata?.length)?.map((item, index) => {
                return <ArticePortraitCard key={index} item={item} />
              })}
            </div>
          </div>
        </div>
      </div>

      {/* -------Footer Section-----*/}

      <div style={{ marginTop: '5rem' }}>
        <Footer />
      </div>
    </div>
  )
}

export default BuyingBlogs
