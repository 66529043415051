import s from '../../../pages/Co-Shopping/CoShopping.module.css'

const InvitationCard = () => {
  return (
    <div className={s.card}>
      <div className={s.cardHeading}>
        <h4>Co-Shopper</h4>
      </div>
      <div className={`${s.Share_card} mt-2 w-100`}>
        <p>
          "Effortlessly share your favorite discoveries and personal notes with
          loved ones, making memories together in a seamless and delightful
          way."
        </p>

        <input type='text' placeholder='Email Address' className='mt-3' />
        <button className={`button ${s.invite_btn}`}>Invite Co-Shopper</button>
      </div>
    </div>
  )
}

export default InvitationCard
