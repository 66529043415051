import ProfessionalsDetailLayout from '../Components/layout/ProfessionalDetailsLayout'
import { InspectorSpecialitiesData } from '../../../../data/constant'

const InspectorDetails = () => {
  const updatedOptionsData = InspectorSpecialitiesData?.map(item => {
    if (item === 'Any') {
      return 'All Reviews'
    }
    return item
  })

  return (
    <div>
      <ProfessionalsDetailLayout
        options={updatedOptionsData}
        selectedForm={'InspectorRev'}
      />
    </div>
  )
}

export default InspectorDetails
