import s from './galleryModal.module.css'
import { useState } from 'react'
import { NotePadModal, FavouriteBtn, AddNoteBtn } from '../../../common'
import ImageGallery from './SubComponents/ImageGallery'
import ListingGalleryMap from './SubComponents/ListingGalleryMap'
import {
  SqftIcon,
  ParkingIcon,
  BathOutlineIcon,
  BedOutlineIcon,
  CloseIcon
} from '../../../svgIcons/Icons'
import { useNavigate, useLocation } from 'react-router-dom'

const ListingGalleryModal = ({ gallerySlideNum, galleryType }) => {
  const navigate = useNavigate()
  const location = useLocation();
  const [ActiveModal, setActiveModal] = useState(galleryType)
  const [isNotepadModalOpen, setIsNotepadModalOpen] = useState(false)

  const HandleNotePadModal = () => {
    setIsNotepadModalOpen(true)
  }

  const handleFilterChange = filter => {
    navigate(`?type=${filter}`)
    setActiveModal(filter)
  }

  const handleCloseBtn = () => {
    const { pathname } = location;
    navigate(pathname);
  };

  return (
    <div className={s.galleryModal_main}>
      {/* ----- Modal Header Section ----*/}
      <div className={s.gallery_des}>
        <div className={s.prop_det}>
          <p>
            5601 Hudson Bend Rd Austin,{' '}
            <span className='text-decoration-underline'>TX 78734</span> |{' '}
            <span className='text-decoration-underline'>Hudson Bend</span>
          </p>
          <div>
            <div className='d-flex align-items-center'>
              <h6 className={s.gallery_desPrice}>$150,000</h6>
              <div className={s.gallery_amenityDes}>
                <div className='d-flex align-items-center'>
                  <BedOutlineIcon />
                  <p>4</p>
                </div>
                <div className='d-flex align-items-center'>
                  <BathOutlineIcon />
                  <p>4</p>
                </div>
                <div className='d-flex align-items-center'>
                  <ParkingIcon size={'24'} color={'white'} />
                  <p>{`1`}</p>
                </div>
                <div className='d-flex align-items-center'>
                  <SqftIcon size={'20'} color={'white'} />
                  <p>{`1456 Sqft`}</p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className={s.gallery_btns}>
          <button
            onClick={() => handleFilterChange('images')}
            className={ActiveModal === 'images' ? `${s.ActiveBtn}` : ''}
          >
            Photos
          </button>
          <button
            onClick={() => handleFilterChange('map')}
            className={ActiveModal === 'map' ? `${s.ActiveBtn}` : ''}
          >
            Map
          </button>
          <button
            onClick={() => handleFilterChange('3Dtour')}
            className={ActiveModal === '3Dtour' ? `${s.ActiveBtn}` : ''}
          >
            3D Tour
          </button>
          <button
            onClick={() => handleFilterChange('streetView')}
            className={ActiveModal === 'streetView' ? `${s.ActiveBtn}` : ''}
          >
            Street View
          </button>
        </div>

        <div className={s.AddDataBtn}>
          <AddNoteBtn
            onClick={HandleNotePadModal}
            mainClassName={s.AddNoteBtn}
          />

          <FavouriteBtn mainClassName={s.HeartBtn} />

          <button className={s.closeBtn} onClick={handleCloseBtn}>
            <CloseIcon size={36} />
          </button>
        </div>
      </div>

      {/* ----- Modal Active Component Section ----*/}
      {ActiveModal === 'images' && <ImageGallery />}
      {ActiveModal === 'map' && <ListingGalleryMap />}

      {/* ----- Notepad Modal Component Section ----*/}
      <NotePadModal
        isNotepadModalOpen={isNotepadModalOpen}
        setIsNotepadModalOpen={setIsNotepadModalOpen}
      />
    </div>
  )
}

export default ListingGalleryModal
