import s from '../Professionals.module.css'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { data } from './BuildersData'
import { Footer } from '../../../common'
import {
  AgentRatingStar,
  HomeFillIcon,
  HomeOutlineIcon
} from '../../../svgIcons/Icons'
import FilterPagesHeader from '../Components/FilterPagesHeader'
import FilterSearchCriteria from '../Components/FilterSearchCriteria'

const Builders = () => {
  const navigate = useNavigate()
  const [filteredCardData, setFilteredCardData] = useState([])

  const handleDetails = id => {
    navigate(`/builder-details/${id}`)
  }

  return (
    <>
      <div className={s.AgentMain}>
        <div>
          <FilterPagesHeader page={'Builders'} />
        </div>

        <div className={`mt-4 ${s.Prof_mainContainer}`}>
        <FilterSearchCriteria
            data={data}
            setFilteredCardData={setFilteredCardData}
            placeholder={'Builder Name'}
            isLangOptions={'No'}
            isSpecialitiesOption={'No'}
          />

          <div className={`${s.pageMain_heading} mt-3`}>
            <h2>Home Builders</h2>
            {filteredCardData?.length > 0 ? (
              <p>{`${filteredCardData?.length.toLocaleString(
                'en-Us'
              )} home found`}</p>
            ) : (
              <p>{`No home found`}</p>
            )}
          </div>
          <hr
            className='mt-4 mb-4'
            style={{
              color: '#d9d9d9',
              height: '2.5px',
              backgroundColor: '#d9d9d9'
            }}
          />

          <div className={s.agentsCardSec}>
            <div className={`${s.Agent_cards}`}>
              {filteredCardData?.map((item, i) => {
                return (
                  <div className={s.Agent_card} key={i}>
                    <div
                      className='d-flex w-100'
                      onClick={() => handleDetails(item?.id)}
                    >
                      <div className={s.Agent_cardprofile}>
                        <img
                          src={`/assets/images/CoShopping1.jpeg`}
                          alt='AgentProfilePic'
                        />
                      </div>
                      <div className={s.AgentCardContent}>
                        <h3>{item?.name}</h3>
                        <div className={s.Builder_communities}>
                          <div className='d-flex'>
                            <HomeFillIcon />
                            <p>{item?.communities} Communities</p>
                          </div>
                          <div className='d-flex ms-3'>
                            <HomeOutlineIcon />
                            <p>{item?.homes} Homes</p>
                          </div>
                        </div>
                        <div
                          className={`${s.Agent_rating} ${s.esAgent_rating} mt-3`}
                        >
                          <AgentRatingStar />
                          <p>
                            {item?.rating}{' '}
                            <span>{`(${item?.reviews} Reviews)`}</span>
                          </p>
                        </div>

                        <div className={s.Builder_plansPrice}>
                          <p>
                            Plans staring from{' '}
                            <span>{`$${item?.startingPlan}`}</span>
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                )
              })}
            </div>
          </div>
        </div>
      </div>

      <div className='mt-5'>
        <Footer />
      </div>
    </>
  )
}

export default Builders
