import { NavLink } from "react-router-dom";
import AuthLayout from "../../../layout/AuthSection";
import s from "../../../layout/AuthSection/Auth.module.css";
import { AuthFooter } from "../../../common";

const ResetPassword = () => {
  return (
    <>
    <div className={s.AuthAll_main}>
      <AuthLayout />
      <div className={s.AuthField_mainContainer}>
        <div className={s.AuthField_container}>
          <div className={`${s.Authmain_heading} w-100 text-start mt-4`}>
            <h1 style={{fontSize:'40px',marginBottom:'20px',textAlign:'start'}}>Forgot Password</h1>
          </div>

          <div className={`mt-2 ${s.Auth_desciption}`}>
            <p>
              An email containing a link to reset your password will be sent to
              the address linked with your account.
            </p>
          </div>

          <div className={`${s.AuthMail_value} ${s.Forgot_Mail}`}>
            <p>saif@gmail.com</p>
          </div>

          <div className="mt-1" style={{ width: "100%" }}>
            <button className={`${s.AuthButton} button`} style={{marginTop:'8px'}}>
              Reset my password
            </button>
          </div>

          <div className={s.Forgot_email} style={{ justifyContent: "center" }}>
            <NavLink to="/login">
              <p>Cancel</p>
            </NavLink>
          </div>
        </div>
      </div>
    </div>

    <div style={{ marginTop: "70px" }}>
    <AuthFooter />
  </div>
  </>
  );
};

export default ResetPassword;
