import style from '../../PropertyDetails.module.css'

const CommunityQuestion = () => {
  return (
    <div>
      <h2 className={style.PropDetails_hedings}>Is this your community?</h2>
      <p className={style.community_des}>
        Want to make additions to this community page and unlock its analytics?
        Admins can edit their listings and gain access to their Builder
        Dashboard.
      </p>
    </div>
  )
}

export default CommunityQuestion
