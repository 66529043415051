import s from '../../Professionals.module.css'
import { NavLink, useParams } from 'react-router-dom'
import { useState } from 'react'
import { data } from '../../PropertyManagers/PropertyManagerData'
import { ProfessionalReviewCard, Footer, StarRating } from '../../../../common'
import WriteReviews from '../../Components/WriteReviews'
import RatingReviewsFilter from '../RatingAndReviewsFilter'
import BreadCrumbs from '../BreadCrumbs'
import {
  FacebookIcon,
  AttachementIcon,
  LinkdinIcon,
  GoogleIcon,
  InstagramIcon,
  TwitterIcon,
  LocationIcon,
  CallIcon,
} from '../../../../svgIcons/Icons'

const ProfessionalsDetailLayout = ({
  options,
  selectedForm,
  selecteddetpage
}) => {
  const { id } = useParams()
  const detailsData = data.find(card => card.id === parseInt(id))
  const [ratingFilter, setRatingFilter] = useState('')
  const [reviewsFilter, setReviewsFilter] = useState('All Reviews')
  const [sortByFilter, setSortByFilter] = useState('Newest First')
  const [addReviews, setAddReviews] = useState(false)

  return (
    <div>
      <div className='container mt-5'>
      <BreadCrumbs />

        <div className='d-flex w-100 mt-3'>
          <div className={`${s.img_container}`}>
            <img src={detailsData?.imgUrl} alt='EstateAgent_profile' />
          </div>
          <div className={`${s.Agent_profileDes} ms-4`}>
            <h3>{detailsData?.name}</h3>
            <p>Screen name: bestmovingcompany</p>
            <p className='fW-4'>
              License <span className='fW-6'> {`#432334423`}</span>
            </p>
            <p className='fW-6'>19 years experience</p>

            <div>
              <p className='fW-6'>{`${detailsData?.language} Speaking`}</p>
            </div>

            <div className={s.AgentD_rating}>
              <div style={{ marginRight: '0.7rem' }}>
                <GoogleIcon size={'20'} />
              </div>
              <StarRating
                rating={detailsData?.rating}
                color={'#B0D95F'}
                size={'20'}
              />
              <p>
                {detailsData?.rating}
                <span className='fW-5'>{`(${detailsData?.reviews} reviews)`}</span>
              </p>
            </div>

            <div className={s.esAgent_links}>
              <NavLink>
                <div className={s.esAgent_linkIcon}>
                  <AttachementIcon size={'30'} color={'black'} />
                </div>
              </NavLink>
              <NavLink>
                <div className={s.esAgent_linkIcon}>
                  <LinkdinIcon size={'17'} color={'black'} />
                </div>
              </NavLink>
              <NavLink>
                <div className={s.esAgent_linkIcon}>
                  <FacebookIcon size={'30'} color={'black'} />
                </div>
              </NavLink>
              <NavLink>
                <div className={s.esAgent_linkIcon}>
                  <InstagramIcon size={'18'} color={'black'} />
                </div>
              </NavLink>
              <NavLink>
                <div className={s.esAgent_linkIcon}>
                  <TwitterIcon size={'18'} color={'black'} />
                </div>
              </NavLink>
            </div>
          </div>
        </div>

        <hr className='mt-5 mb-5' />

        {/* -------Description Section-----*/}

        <div className='row d-flex justify-content-center'>
          <div className='col-md-8 col-12'>
            <div className={s.EsAgent_des}>
              <h2>About me</h2>
              {selecteddetpage === 'photographerDetails' && (
                <h5>Cupid Boutique – The FUN Begins</h5>
              )}
              {detailsData?.specialities && (
                <div className='mt-4'>
                  <h5 className='mb-3'>Specialities</h5>
                  {detailsData?.specialities?.map((item, index) => {
                    return (
                      <ul key={index}>
                        <li>{item}</li>
                      </ul>
                    )
                  })}
                </div>
              )}

              {detailsData?.description && (
                <div className='mt-3'>
                  <p>
                    Cupid Boutique is your one-stop-shop offering a vast array
                    of sexual wellness products in Canada. We’ve been in the
                    business for more than two decades, and today we’re one of
                    the most sought-after sellers of adult toys (and related
                    merchandise) in Canada and the US. We started our journey in
                    1999 as Kinky Finky in Sault Ste. Marie, Ontario. Now, as
                    Cupid Boutique, we provide high-quality adult novelties
                    combined with first-class customer service. We have stores
                    in seven different locations across Southern Ontario. Each
                    store provides discreet packaging and delivery of a wide
                    range of sex toys, lubricants, massagers, condoms, lingerie
                    and BDSM products, to our customers’ doorsteps. Further,
                    Cupid Boutique is also one of the trusted online sex toy
                    stores in Canada. With more than 15,000 high-quality
                    products for sale, we have many ways for couples and
                    individuals from every community to pleasure themselves.
                  </p>
                </div>
              )}
            </div>

            {/* -------Servises Area Section-----*/}

            <div className={`mt-5 ${s.Rating_heading}`}>
              <h5>Services Areas {`(9)`}</h5>

              <div className='row mt-4'>
                <div className='col-lg-8 col-12 row'>
                  {detailsData?.ServicesAreas.map((city, index) => (
                    <div key={index} className='col-md-3'>
                      <p className={s.services_area}>
                        <NavLink>{city}</NavLink>
                      </p>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>

          {/* -------Timing Card Section-----*/}
          <div className={`col-md-4 ${s.AdvisorD_timingD}`}>
            <div>
              <h5>Professional Information</h5>
              <div className='d-flex mt-3'>
                <LocationIcon size={'25'} />
                <p className={s.AdvisorD_loc}>
                  Unit 1143 - 4700 KingswayBurnaby, BC, V5H 4M1
                </p>
              </div>
            </div>

            <hr className={s.break_line} />

            <h5 className='mt-4'>Call</h5>
            <div className='d-flex mt-3'>
              <div>
                <CallIcon size={'20'} color={'#222'} />
              </div>
              <div className='d-flex w-100 ms-3'>
                <div className={s.AdvisorDay_call}>
                  <p>Cell Phone:</p>
                  <p className='mt-2'>Office:</p>
                </div>
                <div>
                  <p>1-778-654-7931</p>
                  <p className='mt-2'>1-778-654-7931</p>
                </div>
              </div>
            </div>

            <hr className={s.break_line} />

            <div className='mt-4'>
              <p>
                Member since: <span className='ps-3'>26/5/2023</span>
              </p>
            </div>
          </div>
        </div>

        {/* -------Rating Section-----*/}

        {selecteddetpage !== 'photographerDetails' && (
          <>
            <RatingReviewsFilter
              options={options}
              reviewsFilter={reviewsFilter}
              sortByFilter={sortByFilter}
              ratingFilter={ratingFilter}
              setRatingFilter={setRatingFilter}
              setReviewsFilter={setReviewsFilter}
              setSortByFilter={setSortByFilter}
              setAddReviews={setAddReviews}
            />

            {addReviews && (
              <div className={s.IntFormopen}>
                <WriteReviews selectedForm={selectedForm} data={detailsData} />
              </div>
            )}

            {/* -------Reviews Card Section-----*/}

            <div className={`row mt-5 ${s.reviewsAll_cards}`}>
              <ProfessionalReviewCard />
              <ProfessionalReviewCard />
              <ProfessionalReviewCard />
              <ProfessionalReviewCard />
              <ProfessionalReviewCard />
            </div>
          </>
        )}
      </div>

      <div className='mt-5'>
        <Footer />
      </div>
    </div>
  )
}

export default ProfessionalsDetailLayout
