import { useState, useEffect } from 'react'
import { SearchIcon } from '../../../../svgIcons/Icons'
import { RatingStarData, langOptionsData } from '../../../../../data/constant'
import s from '../../Professionals.module.css'
import { CustomSelect } from '../../../../common'
import '../../Professionals.css'

const FilterSearchCriteria = ({
  data,
  setFilteredCardData,
  placeholder,
  options,
  isSpecialitiesOption,
  isLangOptions
}) => {
  const [searchQuery, setSearchQuery] = useState('')
  const [selectedLang, setSelectedLang] = useState('')
  const [selectedRating, setSelectedRating] = useState('')
  const [selectedSpecialities, setSelectedSpecialities] = useState('')

  const handleLangChange = event => {
    setSelectedLang(event.target.value)
  }

  const handleRatingChange = event => {
    setSelectedRating(event.target.value)
  }

  const handleSpecialitiesChange = event => {
    setSelectedSpecialities(event.target.value)
  }

  const handleSearchInputChange = e => {
    setSearchQuery(e.target.value)
  }

  useEffect(() => {
    const filteredData = data?.filter(
      item =>
        (selectedLang === '' ||
          selectedLang === 'All Languages' ||
          item.language === selectedLang) &&
        (selectedRating === '' ||
          selectedRating === 'All' ||
          item.rating === selectedRating) &&
        item.name.toLowerCase().includes(searchQuery.toLowerCase())
    )

    setFilteredCardData(filteredData)
  }, [searchQuery, selectedLang, selectedRating, data, setFilteredCardData])

  return (
    <div className={s.SearchandButton}>
      <div className={`${s.searchandLoc}`}>
        <input
          placeholder='Enter Location'
          onChange={handleSearchInputChange}
        />
      </div>

      <div className={`${s.searchandLoc} ${s.profSearch}`}>
        <input placeholder={placeholder} onChange={handleSearchInputChange} />
        <SearchIcon size={'24'} color={'#222'} />
      </div>

      {isLangOptions !== 'No' && (
        <div className={s.selectLang}>
          <CustomSelect
            name={'Select Language'}
            onChange={handleLangChange}
            value={selectedLang}
            mainClassName={s.Agent_dropDown}
            selectMainClassName={s.Agent_dropdownDiv}
            options={langOptionsData}
            mainDivClassName={'prof_filter'}
          />
        </div>
      )}

      {isSpecialitiesOption !== 'No' && (
        <div className={s.selectSpecialities}>
          <CustomSelect
            name={'Specialities'}
            onChange={handleSpecialitiesChange}
            value={selectedSpecialities}
            mainClassName={s.Agent_dropDown}
            selectMainClassName={s.Agent_dropdownDiv}
            options={options}
            mainDivClassName={'prof_filter'}
          />
        </div>
      )}

      <div className={s.selectRating}>
        <CustomSelect
          name={'Rating'}
          onChange={handleRatingChange}
          value={selectedRating}
          mainClassName={s.Agent_dropDown}
          selectMainClassName={s.Agent_dropdownDiv}
          options={RatingStarData}
          mainDivClassName={'prof_filter'}
        />
      </div>
    </div>
  )
}

export default FilterSearchCriteria
