import s from './BasicDetails.module.css'
import {useState} from 'react'
import { data } from '../../PropertyDetailsData'
import { formatNumber } from '../../../../../actions/commonFunctions'
import {
  AddNoteBtn,
  CoShoppingBtn,
  FavouriteBtn,
  ListingMenuBtn,
  MapBtn,
  ShareBtn,
  NotePadModal
} from '../../../../common'
import { NavLink, useNavigate } from 'react-router-dom'
import {
  BathOutlineIcon,
  BedOutlineIcon,
  GarageIcon,
  SqftIcon
} from '../../../../svgIcons/Icons'

const BasicDetails = () => {
  const navigate = useNavigate()
  const [isNotepadModalOpen, setIsNotepadModalOpen] = useState(false)

  let chipStyle = {
    background: '#ffe5ec',
    color: '#ff0061'
  }

  if (data?.propType === 'FSBO') {
    chipStyle.background = '#D7F4F1'
    chipStyle.color = '#098275'
  } else if (data?.propType === 'Resale') {
    chipStyle.background = '#BDE0FE'
    chipStyle.color = '#023047'
  } else if (data?.propType === 'Rent') {
    chipStyle.background = '#DEF5B0'
    chipStyle.color = '#113A00'
  }

  const handleMapbtnClick = () => {
    navigate(`?type=map`)
  }

  const handleAddNoteClick = () => {
    setIsNotepadModalOpen(true)
  }

  

  return (
    <div className={s.Details_cotainer}>
      <div className='d-flex justify-content-between'>
        <div className='d-flex align-items-center'>
          <h2 className={s.prop_price}>{`$${formatNumber(data?.priice)}`}</h2>
          <div className={s.prop_Chip} style={chipStyle}>
            <h6 className='m-0'>{data?.propType}</h6>
          </div>
        </div>
        <div className={s.ListingBtns}>
          <NavLink to='/co-shopping'>
            <CoShoppingBtn mainClassName={s.desBtn_withcontent} />
          </NavLink>
          <AddNoteBtn onClick={handleAddNoteClick} mainClassName={s.desBtn_withcontent} />
          <FavouriteBtn mainClassName={s.desBtn_withoutcontent} />
          <MapBtn
            onClick={handleMapbtnClick}
            mainClassName={s.desBtn_withcontent}
          />
          <ShareBtn mainClassName={s.desBtn_withoutcontent} />
          <ListingMenuBtn mainClassName={s.desBtn_withoutcontent} />
        </div>
      </div>

      <div className={s.AddressandAmenities}>
        <div className={s.propValueAndAddress}>
          <p>
            {data?.propFullName}{' '}
            <span>
              <NavLink>{data?.propCity}</NavLink>
            </span>{' '}
            | &nbsp;
            <span>
              <NavLink>{data?.propCountry}</NavLink>
            </span>
          </p>
          <p>{`Estimated payment $${formatNumber(
            data?.estimatedPayment
          )}/month`}</p>
        </div>
        <div className={s.PropAmenities}>
          <div className='d-flex align-items-center'>
            <BedOutlineIcon color={'#7e7e7e'} size={'24'} />
            <p className='my-0 ms-2'>{data?.bed}</p>
          </div>
          <div className='d-flex align-items-center'>
            <BathOutlineIcon color={'#7e7e7e'} size={'24'} />
            <p className='my-0 ms-2'>{data?.bath}</p>
          </div>
          <div className='d-flex align-items-center'>
            <GarageIcon />
            <p className='my-0 ms-2'>{data?.parking}</p>
          </div>
          <div className='d-flex align-items-center'>
            <SqftIcon color={'#7e7e7e'} size={'20'} />
            <p className='my-0 ms-2'>{`${formatNumber(data?.sqft)} ft²`}</p>
          </div>
        </div>
      </div>

      <div className={s.propertyOwnerLinks}>
        <p>
          By{' '}
          <span>
            <a href='/'>Marydel Homes</a>
          </span>
        </p>
        <p>
          Featured{' '}
          <span>
            <a href='/'>www.marydelHomes.com</a>
          </span>
        </p>
      </div>

      <hr className='my-3' />

      <div className={s.PropDes}>
        <p>{data?.propDescription}</p>
      </div>

      {/* ----- Notepad Modal Component Section ----*/}
      <NotePadModal
        isNotepadModalOpen={isNotepadModalOpen}
        setIsNotepadModalOpen={setIsNotepadModalOpen}
      />
    </div>
  )
}

export default BasicDetails
