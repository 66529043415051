import s from '../../Professionals.module.css'
import { GiveReviewsRating } from '../../../../common'
import { useState } from 'react'
import { Formik, Form } from 'formik'
import AgentReviewsForm from '../../RealEstateAgent/SubComponent/AgentReviewsForm'
import PropManForm from '../../PropertyManagers/SubComponent/PropManReviewsForm'
import HomeImpForm from '../../HomeImprovement/SubComponent/HomeImpReviewsForm'
import InspectorRevForm from '../../Inspectors/SubComponent/InscpectorReviewsForm'

const WriteReviewsForAgent = ({ data, selectedForm }) => {
  const detailsData = data
  const [knowledgeRating, setKnowledgeRating] = useState()
  const [expertiseRating, setExpertiseRating] = useState()
  const [responsivenessRating, setResponsivenessRating] = useState()
  const [negotiationSkillsRating, setNegotiationSkillsRating] = useState()

  const handleKnowledgeRating = rating => {
    setKnowledgeRating(rating)
  }

  const handleExpertiseRating = rating => {
    setExpertiseRating(rating)
  }

  const handleResponsivenessRating = rating => {
    setResponsivenessRating(rating)
  }

  const handleNegotationSkillsRating = rating => {
    setNegotiationSkillsRating(rating)
  }

  return (
    <div className='mt-5'>
      <div className={s.WriteRev_mainHeading}>
        <h2>Share your experience</h2>
        <p>
          We're dedicated to guiding everyone to their dream home, ensuring
          fairness and respect in every interaction. Your feedback is valuable
          and greatly appreciated, as it supports our network of real estate
          professionals in continuously improving.
        </p>
      </div>

      <hr className='mt-5 mb-5' />

      <Formik>
        <Form>
          {/* -------Reviews Description Section-----*/}

          <div className={s.WriteRevDes}>
            <label>What was your experience like with Tony?</label>
            <textarea
              rows={5}
              placeholder='Tony delivered exceptional assistance during our condo purchase. His in-depth knowledge of the local market was crucial in establishing a fair buying price that also took into account potential future resale value. He was thoroughly engaged throughout the entire closing process, maintained regular communication with weekly Tuesday updates, and consistently provided valuable guidance with a friendly and responsive approach.'
            ></textarea>
          </div>

          {/* -------Reviews Rating Section-----*/}

          <div className='mt-5'>
            <div className={`mt-4 ${s.GiveRating_stars}`}>
              <label>Local Knowledge</label>
              <div>
                <GiveReviewsRating
                  mainClassName={s.rating_stars}
                  color={'#B0D95F'}
                  size={'32'}
                  value={knowledgeRating}
                  ratingChanged={handleKnowledgeRating}
                />
              </div>
            </div>
            <div className={`mt-4 ${s.GiveRating_stars}`}>
              <label>Process expertise</label>
              <div>
                <GiveReviewsRating
                  mainClassName={s.rating_stars}
                  color={'#B0D95F'}
                  size={32}
                  value={expertiseRating}
                  ratingChanged={handleExpertiseRating}
                />
              </div>
            </div>
            <div className={`mt-4 ${s.GiveRating_stars}`}>
              <label>Responsiveness</label>
              <div>
                <GiveReviewsRating
                  mainClassName={s.rating_stars}
                  color={'#B0D95F'}
                  size={32}
                  value={responsivenessRating}
                  ratingChanged={handleResponsivenessRating}
                />
              </div>
            </div>
            <div className={`mt-4 ${s.GiveRating_stars}`}>
              <label>Negotiation skills</label>
              <div>
                <GiveReviewsRating
                  mainClassName={s.rating_stars}
                  color={'#B0D95F'}
                  size={32}
                  value={negotiationSkillsRating}
                  ratingChanged={handleNegotationSkillsRating}
                />
              </div>
            </div>
          </div>

          <hr className='mt-5 mb-5' />

          {selectedForm === 'WriteAgentRev' && (
            <AgentReviewsForm detailsData={detailsData} />
          )}

          {selectedForm === 'PropManRev' && (
            <PropManForm detailsData={detailsData} />
          )}

          {selectedForm === 'HomeImpRev' && (
            <HomeImpForm detailsData={detailsData} />
          )}

          {selectedForm === 'InspectorRev' && (
            <InspectorRevForm detailsData={detailsData} />
          )}

          <div className={s.submit_reviewbtn}>
            <button type='submit' className='button'>
              Submit Review
            </button>
          </div>
        </Form>
      </Formik>
    </div>
  )
}

export default WriteReviewsForAgent
