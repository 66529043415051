import { useParams } from 'react-router-dom'
import { useState } from 'react'
import s from '../Professionals.module.css'
import { data } from './BuildersData'
import BreadCrumbs from '../Components/BreadCrumbs'
import {
  BuilderCommunityCard,
  CustomSelect,
  Footer,
  StarRating
} from '../../../common'
import {
  GoogleIcon,
  HomeFillIcon,
  HomeOutlineIcon
} from '../../../svgIcons/Icons'

const EstateAgentDetails = () => {
  const { id } = useParams()
  const detailsData = data.find(card => card.id === parseInt(id))
  const Specialities = detailsData?.specialities?.join(', ')
  const [locationFilter, setLocationFilter] = useState('All Location')
  const options = ['All Location', 'Toronto', 'Missisuaga', 'Brampton']

  const handleLocationFilter = event => {
    setLocationFilter(event.target.value)
  }

  return (
    <div>
      <div className='container mt-5'>
      <BreadCrumbs />

        <div className='w-100 d-flex mt-3'>
          <div>
            <div className={`${s.img_container}`}>
              <img src={detailsData?.imgUrl} alt='Builders-profile' />
            </div>
          </div>

          <div className={`${s.Agent_profileDes} ms-4`}>
            <h3>{detailsData?.name}</h3>
            <p className='fW-6'>
              Plans staring from{' '}
              <span
                style={{ color: '#113A00' }}
              >{`$${detailsData?.startingPlan}`}</span>
            </p>
            <div className='d-flex mt-2'>
              <HomeFillIcon />
              <p className='ms-2'>{`${detailsData.homes} Communities`}</p>
            </div>
            <div className='d-flex mt-2'>
              <HomeOutlineIcon />
              <p className='ms-2'>{`${detailsData.homes} Homes`}</p>
            </div>

            <div className={s.AgentD_rating}>
              <div style={{ marginRight: '0.7rem' }}>
                <GoogleIcon size={'20'} />
              </div>
              <StarRating
                rating={detailsData?.rating}
                color={'#B0D95F'}
                size={'20'}
              />
              <p className=''>
                {detailsData?.rating}
                <span className='fW-5'>{`(${detailsData?.reviews} reviews)`}</span>
              </p>
            </div>

            <div className={s.inspector_detdes}>
              <p>
                Mattamy Homes is the largest privately owned homebuilder in
                North America, with a 40-year history of operations across
                Canada and the United States. We have built more than 100,000
                homes in hundreds of communities. We make places people are
                proud to call home.
              </p>
            </div>

            <div className={`${s.builder_spec} mt-4`}>
              <h5>Specialities</h5>
              <p className='mt-3'>{Specialities}</p>
            </div>
          </div>
        </div>

        <hr className='mt-5 mb-5' />

        <div className={s.Builder_comHeading}>
          <h6>{`Communities in Toronto`}</h6>
          <div className={s.Builder_dropDown}>
            <CustomSelect
              name={'Search'}
              onChange={handleLocationFilter}
              value={locationFilter}
              mainClassName={s.Agent_ProdropDown}
              options={options}
            />
          </div>
        </div>

        <div className='row d-flex mt-4'>
          <div className={s.Builders_commCards}>
            {detailsData?.communityData?.map((item, i) => {
              return (
                <div key={i} style={{ marginBottom: '15px' }}>
                  <BuilderCommunityCard data={item} />
                </div>
              )
            })}
          </div>

          <div className={s.Builders_map}>
            <iframe
              title='Google Map'
              width='100%'
              height='100%'
              style={{ border: '1px solid #676767', borderRadius: '20px' }}
              src='https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3243.595248679713!2d-122.08398878461643!3d37.4224243396231!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x808f80e66614f07d%3A0xd8f1e4b24d887f87!2sGolden%20Gate%20Bridge!5e0!3m2!1sen!2sus!4v1632809337498!5m2!1sen!2sus'
              allowFullScreen
            ></iframe>
          </div>
        </div>
      </div>

      <div className='mt-5'>
        <Footer />
      </div>
    </div>
  )
}

export default EstateAgentDetails
