import Footer from '../../common/FooterMain'
import s from './CoShopping.module.css'
import { CoShopperCard, ShopperInvCard } from '../../common'

const CoShopping = () => {
  const data = [
    {
      name: 'Slahudinn Khan',
      status: 'Awaiting',
      email: 'Slahudinn@gmail.com',
      image: '/assets/images/CoShopping1.jpeg'
    },
    {
      name: 'Usama Aslam',
      email: 'usama786@gmail.com',
      image: '/assets/images/CoShopping2.jpeg'
    },
    {
      name: 'Slahudinn Khan',
      email: 'Slahudinn@gmail.com',
      image: '/assets/images/CoShopping1.jpeg'
    },
    {
      name: 'Usama Aslam',
      status: 'Awaiting',
      email: 'usama786@gmail.com',
      image: '/assets/images/CoShopping2.jpeg'
    },
    {
      name: 'Slahudinn Khan',
      email: 'Slahudinn@gmail.com',
      image: '/assets/images/CoShopping1.jpeg'
    },
    {
      name: 'Usama Aslam',
      status: 'Awaiting',
      email: 'usama786@gmail.com',
      image: '/assets/images/CoShopping2.jpeg'
    },
    {
      name: 'Slahudinn Khan',
      email: 'Slahudinn@gmail.com',
      image: '/assets/images/CoShopping1.jpeg'
    },
    {
      name: 'Usama Aslam',
      email: 'usama786@gmail.com',
      image: '/assets/images/CoShopping2.jpeg'
    },
    {
      name: 'Slahudinn Khan',
      status: 'Awaiting',
      email: 'Slahudinn@gmail.com',
      image: '/assets/images/CoShopping1.jpeg'
    }
  ]
  return (
    <>
      <div className={`${s.main} mt-5`}>
        <div className={`${s.Main_heading}`}>
          <h1>Co-shopping</h1>
          <p>
            "Embark on the journey of finding your dream home together -
            co-shopping, co-dreaming, and co-creating lasting memories."
          </p>
        </div>

        <div className={s.cardsAll_main}>
          {data?.map((item, i) => {
            return <CoShopperCard key={i} item={item} />
          })}
          <ShopperInvCard
            cardTitle={'Co-Shopper'}
            buttonText={'Invite Co-Shopper'}
            cardDescription={`"Effortlessly share your favorite discoveries and personal notes with loved ones, making memories together in a seamless and delightful way."`}
          />
        </div>
      </div>

      <div className='mt-5'>
        <Footer />
      </div>
    </>
  )
}

export default CoShopping
