import { NavLink } from "react-router-dom";
import AuthLayout from "../../../layout/AuthSection";
import s from "../../../layout/AuthSection/Auth.module.css";
import { AuthFooter } from "../../../common";

const UserRegSuccess = () => {
  return (
    <>
      <div className={s.AuthAll_main}>
        <AuthLayout />
        <div className={s.AuthField_mainContainer}>
          <div className={s.AuthField_container}>
            <div className={s.Authmain_heading}>
              <h5>
                Congratulations! your account has been created successfully
              </h5>
            </div>

            <div className={`${s.planing_sell} ${s.BrokerFree}`}>
              <p>Selling broker-free is easier than you think.</p>
            </div>
            <div className={`mt-2 ${s.Auth_desciption}`}>
              <p>
                With NeedHome, you get access to all the resources you need to
                sell successfully, without paying commission!
              </p>
            </div>

            <div className={`${s.planing_sell} ${s.BrokerFree}`}>
              <p>How?</p>
            </div>
            <div className={`mt-2 ${s.Auth_desciption}`}>
              <p>
                Discover all the details on the support and visibility we offer
                by talking to one of our advisors.
              </p>
            </div>

            <div className="mt-4" style={{ width: "100%" }}>
              <NavLink to='/schedule-consultation'>
              <button className={`${s.AuthButton} button`}>
                Schedule a telephone consultation.
              </button>
              </NavLink>
            </div>

            <div
              className={s.Forgot_email}
              style={{ justifyContent: "center" }}
            >
              <NavLink to="/">
                <p>No thank you</p>
              </NavLink>
            </div>
          </div>
        </div>
      </div>

      <div style={{ marginTop: "70px" }}>
        <AuthFooter />
      </div>
    </>
  );
};

export default UserRegSuccess;
