import "./style.css";

const SoldPropertGrid = ({data}) => {
  const Solddata = data

  return (
    <div className="sold-propAllcards">
      <div className={`sold-propertycard card-1 `}>
        <img src={Solddata.soldPropImg} alt="Sold-property" />
        <div className="sold-propertyCardPr">
          <p>{`For Sale $440K`}</p>
        </div>
      </div>

      <div className="columns_card1">
        <div className={`sold-propertycard card-2`}>
          <img src={Solddata.soldPropImg}   alt="Sold-property" />
          <div className="sold-propertyCardPr">
            <p>{`For Sale $20M`}</p>
          </div>
        </div>
        <div className={`sold-propertycard card-3`}>
          <img src={Solddata.soldPropImg}  alt="Sold-property" />
          <div className="sold-propertyCardPr">
            <p>{`For Sale $440M`}</p>
          </div>
        </div>
      </div>

      <div className="columns_card2">
        <div className={`sold-propertycard card-4`}>
          <img src={Solddata.soldPropImg}  alt="Sold-property" />
          <div className="sold-propertyCardPr">
            <p>{`Sold $2.4M`}</p>
          </div>
        </div>
        <div className={`sold-propertycard card-5`}>
          <img src={Solddata.soldPropImg}   alt="Sold-property" />
          <div className="sold-propertyCardPr">
            <p>{`Sold $44040`}</p>
          </div>
        </div>
      </div>

      <div className={`sold-propertycard card-6`}>
        <img src={Solddata.soldPropImg}   alt="Sold-property" />
        <div className="sold-propertyCardPr">
          <p>{`Sold $44040`}</p>
        </div>
      </div>
    </div>
  );
};

export default SoldPropertGrid;
