import s from "../../../pages/Articles/Articles.module.css";


const ArticleCard = ({item}) => {

  return (
    <div className={s.ArticleCard}>
      <div className={s.categoryAndRead}>
        <div className={s.ArticleCard_category}>
          <h6>{item?.category}</h6>
        </div>
        <div className={s.ArticleCard_ReadTime}>
          <h6>5 min read</h6>
        </div>
      </div>

      <div className={s.ArticleCard_Image}>
        <img src={item?.imgUrl} alt="Article_Card" />
      </div>

      <div className={s.ArticleCardTitleDes}>
        <h3>{item?.title}</h3>
        <p>
        {`${item?.description.slice(0,150)}...`}
        </p>
      </div>
    </div>
  );
};

export default ArticleCard;
