import {
  validateEmail,
  validatePassword,
  validatePhoneNumber,
} from "./commonFunctions";

export const LogInValidation = (values) => {
  const errors = {};
  if (!values.email) {
    errors.email = "Please enter email";
  } else if (!validateEmail(values.email)) {
    errors.email = "Please enter valid email";
  }
  if (!values.password) {
    errors.password = "Password Required";
  } else if (!validatePassword(values.password)) {
    errors.password =
      "Password must be atleast 8 character, contain a number or character";
  }
  return errors;
};

export const LogInPassValidation = (values) => {
  const errors = {};
  if (!values.password) {
    errors.password = "Password Required";
  } else if (!validatePassword(values.password)) {
    errors.password =
      "Password must be atleast 8 character, contain a number or character";
  }
  return errors;
};

export const SignUpInfo = (values) => {
  const errors = {};
  if (!values.email) {
    errors.email = "Please enter email";
  } else if (!validateEmail(values.email)) {
    errors.email = "Please enter valid email";
  }
  if (!values.password) {
    errors.password = "Password Required";
  } else if (!validatePassword(values.password)) {
    errors.password =
      "Password must be atleast 8 character, contain a number or character";
  }
  if (!values.newsTerms) {
    errors.newsTerms = "You must agree to the terms";
  }
  if (!values.dataTerms) {
    errors.dataTerms = "You must agree to the terms";
  }
  return errors;
};

export const ScheduleConsultation = (values) => {
  const errors = {};
  if (!values.firstName) {
    errors.firstName = "Please enter First Name";
  }
  if (!values.lastName) {
    errors.lastName = "Please enter Last Name";
  }
  if (!values.phoneNumber) {
    errors.phoneNumber = "Please enter Phone Number";
  } else if (!validatePhoneNumber(values.phoneNumber)) {
    errors.phoneNumber = "Please enter valid Phone Number";
  }
  return errors;
};
