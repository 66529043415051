import { ListingMenuIcon } from '../../../svgIcons/Icons'

const ListingMenuBtn = ({ mainClassName }) => {
  return (
    <div>
      {/* <button className={mainClassName}> */}
        <ListingMenuIcon />
      {/* </button> */}
    </div>
  )
}

export default ListingMenuBtn
