import React from "react";
import { AgentRatingStar, AgentRatingStarOutline } from "../../svgIcons/Icons";

const StarRating = ({ color, size, rating }) => {
  const stars = Array.from({ length: 5 }, (_, index) => {
    const isFilled = index < Math.round(rating);
    return (
      <span key={index}>
        {isFilled ? (
          <AgentRatingStar size={size} color={color}/>
        ) : (
          <AgentRatingStarOutline size={size} color={color}/>
        )}
      </span>
    );
  });

  return <div>{stars}</div>;
};

export default StarRating;
