import { NavLink } from "react-router-dom";
import { useState } from "react";
import { Form, Formik } from "formik";
import AuthLayout from "../../../layout/AuthSection";
import s from "../../../layout/AuthSection/Auth.module.css";
import { AuthFooter, CustomInput, NumberInput } from "../../../common";
import MenuItem from "@mui/material/MenuItem";
import { Box, FormControl } from "@mui/material";
import Select from "@mui/material/Select";
import {DropdownInActive} from '../../../svgIcons/Icons'
import { ScheduleConsultation } from "../../../../actions/validation";

const ScheduleConsulatation = () => {
  const [bestCalltime, setBestCalltime] = useState("Monday");
  const [isSubmited, setIsSubmited] = useState(false);

  const handleCallTime = (event) => {
    setBestCalltime(event.target.value);
    setData((prevData) => ({
      ...prevData,
      bestCalltime: event.target.value,
    }));
  };

  const [data, setData] = useState({
    firstName: "",
    lastName: "",
    bestCalltime: bestCalltime,
    phoneNumber: "",
  });

  const handleSubmit = () => {
    console.log(data);
  };
  return (
    <>
      <Formik
        initialValues={{
          data,
        }}
        validate={(values) => ScheduleConsultation(values)}
        onSubmit={handleSubmit}
      >
        {({ errors, touched }) => (
          <Form>
            <div className={s.AuthAll_main}>
              <AuthLayout />
              <div className={s.AuthField_mainContainer}>
                <div className={s.AuthField_container}>
                  <div className={`${s.planing_sell} ${s.BrokerFree} m-2`}>
                    <p className="w-100">Schedule a free consultation</p>
                  </div>
                  <div className={`mt-1 ${s.Auth_desciption} text-start w-100`}>
                    <p>
                      Enter the following information to receive a phone call.
                    </p>
                  </div>

                  <div className="mt-4 w-100">
                    <div className="w-100">
                      <CustomInput
                        placeholder="First Name"
                        name="firstName"
                        type="text"
                        data={data}
                        setData={setData}
                      />
                    </div>
                    {isSubmited && (
                      <div className="w-100 text-start">
                        <p className="Invaild_errors m-0">{errors.firstName}</p>
                      </div>
                    )}
                    <div className="w-100">
                      <CustomInput
                        placeholder="Last Name"
                        name="lastName"
                        type="text"
                        data={data}
                        setData={setData}
                      />
                    </div>
                    {isSubmited && (
                      <div className="w-100 text-start">
                        <p className="Invaild_errors m-0">{errors.lastName}</p>
                      </div>
                    )}
                  </div>

                  <div className="w-100 mt-3">
                    <NumberInput
                      name="phoneNumber"
                      data={data}
                      setData={setData}
                    />
                  </div>
                  {isSubmited && (
                    <div className="w-100 text-start">
                      <p className="Invaild_errors m-0">{errors.phoneNumber}</p>
                    </div>
                  )}

                  <div className={s.planing_sell}>
                    <p>What is the best time to call you?</p>
                  </div>

                  <div style={{ width: "100%" }} className="mt-3 mb-3">
                    <Box>
                      <FormControl fullWidth>
                        <Select
                          labelId="Select"
                          id="CallTime"
                          value={bestCalltime}
                          onChange={handleCallTime}
                          
                          sx={{
                            color: "#76787A",
                            fontWeight: "500",
                            borderRadius:'10px',
                            paddingRight: '8px'
                          }}
                          IconComponent={DropdownInActive}
                        >
                          <MenuItem value={"Monday"}>Monday</MenuItem>
                          <MenuItem value={"Tuesday"}>Tuesday</MenuItem>
                          <MenuItem value={"Wednesday"}>Wednesday</MenuItem>
                          <MenuItem value={"Thursday"}>Thursday</MenuItem>
                          <MenuItem value={"Friday"}>Friday</MenuItem>
                        </Select>
                      </FormControl>
                    </Box>
                  </div>

                  <div className="w-100">
                    <button
                      type="submit"
                      onClick={() => setIsSubmited(true)}
                      className={`${s.AuthButton} button`}
                    >
                      Submit
                    </button>
                  </div>

                  <div
                    className={s.Forgot_email}
                    style={{ justifyContent: "center" }}
                  >
                    <NavLink to="/">
                      <p>Cancel</p>
                    </NavLink>
                  </div>
                </div>
              </div>
            </div>

            <div style={{ marginTop: "70px" }}>
              <AuthFooter />
            </div>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default ScheduleConsulatation;
