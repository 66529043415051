import s from '../../../pages/Professionals/Professionals.module.css';

const BuilderCommunityCard = ({ data }) => {
  return (
    <div className='d-flex'>
      <div className={s.BuilderCom_cardImg}>
        <img src={data?.imgUrl} alt='community-profile' />
      </div>
      <div className={s.BuilderCom_cardDes}>
        <h6>{data?.name}</h6>
        <p>{`From ${data?.price}`}</p>
        <p className={s.builderComm_address}>{data?.address}</p>
      </div>
    </div>
  )
}

export default BuilderCommunityCard
