import { AddNotesIcon } from '../../../svgIcons/Icons'

const AddNoteBtn = ({mainClassName,onClick}) => {
  return (
    <div>
      <button
        className={`${mainClassName} d-flex align-items-center`}
        onClick={onClick}
      >
        <AddNotesIcon />
        <span className='ms-1'>Add Notes</span>
      </button>
    </div>
  )
}

export default AddNoteBtn
