import * as React from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

export default function Faqs() {
  return (
    <div className="w-100">

      <Accordion className="mb-2 rounded">
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography className="fs-5 fw-bold">
            Is there is any free trail available?
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            Financeit is a Canadian company that allows businesses like
            DuProprio to offer financing plans to their clients. In Canada, a
            number of companies also do business with them, such as Home Depot,
            Trevi, Qualinet and Fenplast. Financeit offers respected and secure
            service in French.
          </Typography>
        </AccordionDetails>
      </Accordion>

      <Accordion className="my-3 rounded">
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2a-content"
          id="panel2a-header"
        >
          <Typography className="fs-5 fw-bold">
            Can I cancel my Ad any time?
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            A Need Home advisor will be in touch to guide you through the next
            steps. They'll arrange a meeting for property photography and set up
            your financing with Financeit after the visit.
          </Typography>
        </AccordionDetails>
      </Accordion>

      <Accordion className="my-3 rounded">
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel3a-content"
          id="panel3a-header"
        >
          <Typography className="fs-5 fw-bold">
            How does billing work?
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            If your financing request is declined, you can consider adding a co-
            applicant to improve your chances of approval. Need Home advisors
            can help you with this if it applies to your situation. Keep in
            mind, you also have the option to pay for your package using a Visa
            or Mastercard.
          </Typography>
        </AccordionDetails>
      </Accordion>

      <Accordion className="my-3 rounded">
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel3a-content"
          id="panel3a-header"
        >
          <Typography className="fs-5 fw-bold">
            {" "}
            How do I change my account email?
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            The equal payments come with a 15.99% interest rate. For Silver or
            Gold packages, these payments start after a 6-month period.
          </Typography>
        </AccordionDetails>
      </Accordion>

      <Accordion className="my-3 rounded">
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel3a-content"
          id="panel3a-header"
        >
          <Typography className="fs-5 fw-bold">
            How does support work?
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            To make a partial or full repayment, simply get in touch with
            Financeit directly. They offer services in both French and English.
            You won't incur any penalties or fees for making early repayments.
          </Typography>
        </AccordionDetails>
      </Accordion>

      <Accordion className="my-3 rounded">
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel3a-content"
          id="panel3a-header"
        >
          <Typography className="fs-5 fw-bold">
            Do you provide tutorials?
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            Checking your eligibility won't impact your credit score. However,
            an official financing request might. If you submit a request on
            Financeit's website, any actions that could affect your credit
            rating will be clearly indicated in the second step.
          </Typography>
        </AccordionDetails>
      </Accordion>

      <Accordion className="my-3 rounded">
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel3a-content"
          id="panel3a-header"
        >
          <Typography className="fs-5 fw-bold">
            Can I change my plan later?
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            Checking your eligibility won't impact your credit score. However,
            an official financing request might. If you submit a request on
            Financeit's website, any actions that could affect your credit
            rating will be clearly indicated in the second step.
          </Typography>
        </AccordionDetails>
      </Accordion>

      <Accordion className="my-3 rounded">
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel3a-content"
          id="panel3a-header"
        >
          <Typography className="fs-5 fw-bold">
            Can I change my contact info?
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            Checking your eligibility won't impact your credit score. However,
            an official financing request might. If you submit a request on
            Financeit's website, any actions that could affect your credit
            rating will be clearly indicated in the second step.
          </Typography>
        </AccordionDetails>
      </Accordion>
    </div>
  );
}
