import style from '../../PropertyDetails.module.css'
import { data } from '../../PropertyDetailsData'
import s from './FeatureAndFurnish.module.css'

const FeatureAndFurnishes = () => {
  return (
    <div>
      <h2 className={style.PropDetails_hedings}>Features and finishes</h2>
      {data?.MLSData?.map((listing, index) => (
        <div key={index} className={s.furnishCategory_container}>
          <div className={s.furnish_category}>
            <h6 className={style.sub_headings}>STRUCTURE</h6>
            <ul className={style.ListingDetails_listMain}>
              {data?.FeatureAndFurnishData[0]?.structure.map((item, i) => {
                return <li key={i}>{item}</li>
              })}
            </ul>
          </div>

          <div className={s.furnish_category}>
            <h6 className={style.sub_headings}>INTERIOR PAINT & TRIMWORK</h6>
            <ul className={style.ListingDetails_listMain}>
              {data?.FeatureAndFurnishData[0]?.trimWork.map((item, i) => {
                return <li key={i}>{item}</li>
              })}
            </ul>
          </div>

          <div className={s.furnish_category}>
            <h6 className={style.sub_headings}>KITCHEN</h6>
            <ul className={style.ListingDetails_listMain}>
              {data?.FeatureAndFurnishData[0]?.kitchen.map((item, i) => {
                return <li key={i}>{item}</li>
              })}
            </ul>
          </div>

          <div className={s.furnish_category}>
            <h6 className={style.sub_headings}>BATHROOMS</h6>
            <ul className={style.ListingDetails_listMain}>
              {data?.FeatureAndFurnishData[0]?.bathrooms.map((item, i) => {
                return <li key={i}>{item}</li>
              })}
            </ul>
          </div>

          <div className={s.furnish_category}>
            <h6 className={style.sub_headings}>FLOORING</h6>
            <ul className={style.ListingDetails_listMain}>
              {data?.FeatureAndFurnishData[0]?.flooring.map((item, i) => {
                return <li key={i}>{item}</li>
              })}
            </ul>
          </div>

          <div className={s.furnish_category}>
            <h6 className={style.sub_headings}>HVAC</h6>
            <ul className={style.ListingDetails_listMain}>
              {data?.FeatureAndFurnishData[0]?.HVAC.map((item, i) => {
                return <li key={i}>{item}</li>
              })}
            </ul>
          </div>

          <div className={s.furnish_category}>
            <h6 className={style.sub_headings}>ELECTRICAL</h6>
            <ul className={style.ListingDetails_listMain}>
              {data?.FeatureAndFurnishData[0]?.electrical.map((item, i) => {
                return <li key={i}>{item}</li>
              })}
            </ul>
          </div>

          <div className={s.furnish_category}>
            <h6 className={style.sub_headings}>
              INTECH – INTEGRATED TECHNOLOGY
            </h6>
            <ul className={style.ListingDetails_listMain}>
              {data?.FeatureAndFurnishData[0]?.technology.map((item, i) => {
                return <li key={i}>{item}</li>
              })}
            </ul>
          </div>
        </div>
      ))}
    </div>
  )
}

export default FeatureAndFurnishes
