import { ImageGalleryIcon, TourIcon } from '../../../../svgIcons/Icons'
import { data } from '../../PropertyDetailsData'
import s from './ThumbnailImages.module.css'
import { useNavigate } from 'react-router-dom'

const ThumbnailImages = () => {
  const navigate = useNavigate()

  const handeImgClick = ImgNum => {
    navigate(`?type=images&img=${ImgNum}`)
  }

  const handel3DtourClick = () => {
    navigate(`?type=3Dtour`)
  }

  return (
    <div className={s.gallery_container}>
      <div className={s.galleryMainImg} onClick={() => handeImgClick(1)}>
        <img src={data?.propImg[0]} alt='PropertyImg' />
      </div>

      <div className={s.gallery_subImg}>
        <div className={s.sub_thumbnail} onClick={() => handeImgClick(2)}>
          <img src={data?.propImg[1]} alt='PropertyImg' />
        </div>
        <div className={s.thumbail_subcatregorycontainer}>
          <div
            className={s.thumbail_subcatregory}
            onClick={() => handeImgClick(3)}
          >
            <img src={data?.propImg[2]} alt='PropertyImg' />
          </div>
          <div
            className={s.thumbail_subcatregory}
            onClick={() => handeImgClick(4)}
          >
            <img src={data?.propImg[3]} alt='PropertyImg' />
          </div>
        </div>
      </div>

      <button className={s.AllImgBtn} onClick={() => handeImgClick(1)}>
        <ImageGalleryIcon />
        <p>{`View all ${data?.propImg?.length} Photos`}</p>
      </button>

      <button className={s.tourBtn} onClick={() => handel3DtourClick(1)}>
        <TourIcon />
        <p>3D Tour</p>
      </button>
    </div>
  )
}

export default ThumbnailImages
