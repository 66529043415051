export const data = [
  {
    category: 'All about buying',
    imgUrl: '/assets/images/bridge.jpeg',
    title: 'Buying a new or existing home: which is right for you?',
    description:
      'It’s also an excellent first step towards building a portfolio of rental properties if that’s your plan. A duplex can generate attractive income, increase in value over time, and is easier to manage and less costly than a multi-unit building, and is easier to manage and less costly than a double room'
  },
  {
    category: 'All about buying',
    imgUrl: '/assets/images/bridge.jpeg',
    title: 'Buying a new or existing home: which is right for you?',
    description:
      'It’s also an excellent first step towards building a portfolio of rental properties if that’s your plan. A duplex can generate attractive income, increase in value over time, and is easier to manage and less costly than a multi-unit building, and is easier to manage and less costly than a double room'
  },
  {
    category: 'All about selling',
    imgUrl: '/assets/images/bridge.jpeg',
    title: 'Buying a new or existing home: which is right for you?',
    description:
      'It’s also an excellent first step towards building a portfolio of rental properties if that’s your plan. A duplex can generate attractive income, increase in value over time, and is easier to manage and less costly than a multi-unit building, and is easier to manage and less costly than a double room'
  },
  {
    category: 'All about selling',
    imgUrl: '/assets/images/bridge.jpeg',
    title: 'Buying a new or existing home: which is right for you?',
    description:
      'It’s also an excellent first step towards building a portfolio of rental properties if that’s your plan. A duplex can generate attractive income, increase in value over time, and is easier to manage and less costly than a multi-unit building, and is easier to manage and less costly than a double room'
  },
  {
    category: 'All about selling',
    imgUrl: '/assets/images/bridge.jpeg',
    title: 'Buying a new or existing home: which is right for you?',
    description:
      'It’s also an excellent first step towards building a portfolio of rental properties if that’s your plan. A duplex can generate attractive income, increase in value over time, and is easier to manage and less costly than a multi-unit building, and is easier to manage and less costly than a double room'
  },
  {
    category: 'All about buying',
    imgUrl: '/assets/images/bridge.jpeg',
    title: 'Buying a new or existing home: which is right for you?',
    description:
      'It’s also an excellent first step towards building a portfolio of rental properties if that’s your plan. A duplex can generate attractive income, increase in value over time, and is easier to manage and less costly than a multi-unit building, and is easier to manage and less costly than a double room'
  },
  {
    category: 'Real estate news',
    imgUrl: '/assets/images/bridge.jpeg',
    title: 'Buying a new or existing home: which is right for you?',
    description:
      'It’s also an excellent first step towards building a portfolio of rental properties if that’s your plan. A duplex can generate attractive income, increase in value over time, and is easier to manage and less costly than a multi-unit building, and is easier to manage and less costly than a double room'
  },
  {
    category: 'Real estate news',
    imgUrl: '/assets/images/bridge.jpeg',
    title: 'Buying a new or existing home: which is right for you?',
    description:
      'It’s also an excellent first step towards building a portfolio of rental properties if that’s your plan. A duplex can generate attractive income, increase in value over time, and is easier to manage and less costly than a multi-unit building, and is easier to manage and less costly than a double room'
  },
  {
    category: 'Heart finds home',
    imgUrl: '/assets/images/bridge.jpeg',
    title: 'Buying a new or existing home: which is right for you?',
    description:
      'It’s also an excellent first step towards building a portfolio of rental properties if that’s your plan. A duplex can generate attractive income, increase in value over time, and is easier to manage and less costly than a multi-unit building, and is easier to manage and less costly than a double room'
  },
  {
    category: 'Heart finds home',
    imgUrl: '/assets/images/bridge.jpeg',
    title: 'Buying a new or existing home: which is right for you?',
    description:
      'It’s also an excellent first step towards building a portfolio of rental properties if that’s your plan. A duplex can generate attractive income, increase in value over time, and is easier to manage and less costly than a multi-unit building, and is easier to manage and less costly than a double room'
  },
  {
    category: 'Heart finds home',
    imgUrl: '/assets/images/bridge.jpeg',
    title: 'Buying a new or existing home: which is right for you?',
    description:
      'It’s also an excellent first step towards building a portfolio of rental properties if that’s your plan. A duplex can generate attractive income, increase in value over time, and is easier to manage and less costly than a multi-unit building, and is easier to manage and less costly than a double room'
  },
  {
    category: 'All about selling',
    imgUrl: '/assets/images/bridge.jpeg',
    title: 'Buying a new or existing home: which is right for you?',
    description:
      'It’s also an excellent first step towards building a portfolio of rental properties if that’s your plan. A duplex can generate attractive income, increase in value over time, and is easier to manage and less costly than a multi-unit building, and is easier to manage and less costly than a double room'
  },
  {
    category: 'All about buying',
    imgUrl: '/assets/images/bridge.jpeg',
    title: 'Buying a new or existing home: which is right for you?',
    description:
      'It’s also an excellent first step towards building a portfolio of rental properties if that’s your plan. A duplex can generate attractive income, increase in value over time, and is easier to manage and less costly than a multi-unit building, and is easier to manage and less costly than a double room'
  },
  {
    category: 'All about selling',
    imgUrl: '/assets/images/bridge.jpeg',
    title: 'Buying a new or existing home: which is right for you?',
    description:
      'It’s also an excellent first step towards building a portfolio of rental properties if that’s your plan. A duplex can generate attractive income, increase in value over time, and is easier to manage and less costly than a multi-unit building, and is easier to manage and less costly than a double room'
  },
  {
    category: 'Heart finds home',
    imgUrl: '/assets/images/bridge.jpeg',
    title: 'Buying a new or existing home: which is right for you?',
    description:
      'It’s also an excellent first step towards building a portfolio of rental properties if that’s your plan. A duplex can generate attractive income, increase in value over time, and is easier to manage and less costly than a multi-unit building, and is easier to manage and less costly than a double room'
  },
  {
    category: 'Real estate news',
    imgUrl: '/assets/images/bridge.jpeg',
    title: 'Buying a new or existing home: which is right for you?',
    description:
      'It’s also an excellent first step towards building a portfolio of rental properties if that’s your plan. A duplex can generate attractive income, increase in value over time, and is easier to manage and less costly than a multi-unit building, and is easier to manage and less costly than a double room'
  },
  {
    category: 'Real estate news',
    imgUrl: '/assets/images/bridge.jpeg',
    title: 'Buying a new or existing home: which is right for you?',
    description:
      'It’s also an excellent first step towards building a portfolio of rental properties if that’s your plan. A duplex can generate attractive income, increase in value over time, and is easier to manage and less costly than a multi-unit building, and is easier to manage and less costly than a double room'
  },
  {
    category: 'All about buying',
    imgUrl: '/assets/images/bridge.jpeg',
    title: 'Buying a new or existing home: which is right for you?',
    description:
      'It’s also an excellent first step towards building a portfolio of rental properties if that’s your plan. A duplex can generate attractive income, increase in value over time, and is easier to manage and less costly than a multi-unit building, and is easier to manage and less costly than a double room'
  },
  {
    category: 'All about selling',
    imgUrl: '/assets/images/bridge.jpeg',
    title: 'Buying a new or existing home: which is right for you?',
    description:
      'It’s also an excellent first step towards building a portfolio of rental properties if that’s your plan. A duplex can generate attractive income, increase in value over time, and is easier to manage and less costly than a multi-unit building, and is easier to manage and less costly than a double room'
  },
  {
    category: 'All about selling',
    imgUrl: '/assets/images/bridge.jpeg',
    title: 'Buying a new or existing home: which is right for you?',
    description:
      'It’s also an excellent first step towards building a portfolio of rental properties if that’s your plan. A duplex can generate attractive income, increase in value over time, and is easier to manage and less costly than a multi-unit building, and is easier to manage and less costly than a double room'
  },
  {
    category: 'Heart finds home',
    imgUrl: '/assets/images/bridge.jpeg',
    title: 'Buying a new or existing home: which is right for you?',
    description:
      'It’s also an excellent first step towards building a portfolio of rental properties if that’s your plan. A duplex can generate attractive income, increase in value over time, and is easier to manage and less costly than a multi-unit building, and is easier to manage and less costly than a double room'
  },
  {
    category: 'Real estate news',
    imgUrl: '/assets/images/bridge.jpeg',
    title: 'Buying a new or existing home: which is right for you?',
    description:
      'It’s also an excellent first step towards building a portfolio of rental properties if that’s your plan. A duplex can generate attractive income, increase in value over time, and is easier to manage and less costly than a multi-unit building, and is easier to manage and less costly than a double room'
  }
]
