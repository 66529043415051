import React from "react";
import { Field } from "formik";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

const NumberInput = ({ label, name, data, setData,mainClassName }) => {
  return (
    <div>
      <label>{label}</label>
      <Field name={name}>
        {({ field, form }) => {
          const { value } = field;
          const onChange = (number,country) => {
            form.setFieldValue(name, number);
            setData && setData((prevData) => ({ ...prevData, [name]: number}));
          };

          return (
            <div className={mainClassName}>
              <PhoneInput
                country="ca"
                defaultCountry="ca"
                placeholder="Contact Number"
                value={value}
                onChange={onChange}
              />
            </div>
          );
        }}
      </Field>
    </div>
  );
};

export default NumberInput;
