import s from './PropertyHighlights.module.css'
import style from '../../PropertyDetails.module.css'
import { data } from '../../PropertyDetailsData'
import { TickIcon } from '../../../../svgIcons/Icons'

const PropertyHighlights = () => {
  return (
    <div className='w-100'>
      <h2 className={style.PropDetails_hedings}>Property Highlights</h2>
      <div className='mt-4'>
        <h6
          className={style.sub_headings}
        >{`How ${data?.propFullName} compares to other nearby properties.`}</h6>
      </div>
      <div className={s.highlight_container}>
        <div>
          <h5
            className={style.sub_headings}
            style={{ textDecoration: 'underLine' }}
          >
            Positives
          </h5>
          <div className='ms-2'>
            <h6>
              <TickIcon />
             <span className='ms-1'>Less Expensive</span>
            </h6>
            <p>The 3-bedrooms are cheaper than other similar listings nearby</p>
          </div>
        </div>
        <div>
          <h5
            className={style.sub_headings}
            style={{ textDecoration: 'underLine' }}
          >
            Considerations
          </h5>
          <div className='ms-2'>
            <h6>Less Expensive</h6>
            <p>The 3-bedrooms are cheaper than other similar listings nearby</p>
            <h6>Less Expensive</h6>
            <p>The 3-bedrooms are cheaper than other similar listings nearby</p>
            <h6>Less Expensive</h6>
            <p>The 3-bedrooms are cheaper than other similar listings nearby</p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default PropertyHighlights
