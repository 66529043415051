import React from "react";
import { Field } from "formik";

// Import styles
import s from "./input.module.css";

const InputCheckBoxComponent = ({ label, name, text, data, setData }) => {
  return (
    <div>
      <label className={`label-primary`}>{label}</label>
      <Field type="checkbox" name={name}>
        {({ field, form }) => {
          const { checked } = field;

          const onChange = () => {
            form.setFieldValue(name, !checked);
            setData && setData((prevData) => ({ ...prevData, [name]: !checked }));
          };

          return (
            <div className={s.termsToAgree}>
              <input
                type="checkbox"
                onChange={onChange}
                checked={checked}
              />
              <span className="ms-2" >{text}</span>
            </div>
          );
        }}
      </Field>
    </div>
  );
};

export default InputCheckBoxComponent;
