export const data = [
  {
    id: 1,
    name: "Cristiano Ronaldo",
    tag: "Home Smart",
    imgUrl: "/assets/images/CoShopping1.jpeg",
    priceRange: " $550K - $20.5M",
    totalSale: "305",
    citySale: "105",
    status: "Mortgage Agent",
    reviews: 131,
    rating: "3.0",
    language: "French",
    soldPropImg: "/assets/images/SoldProperty.png",
    soldPropPrice: "$045M",
    ServicesAreas: [
      "Vineland",
      "Grimsby",
      "Cayuga",
      "Hamilton",
      "Brampton",
      "Mississauga",
      "Toronto",
      "Vancouver",
      "Calgary",
      "Montreal",
    ],
  },
  {
    id: 2,
    name: "Cristiano Ronaldo",
    tag: "Home Smart",
    imgUrl: "/assets/images/CoShopping1.jpeg",
    priceRange: " $550K - $20.5M",
    totalSale: "305",
    citySale: "105",
    status: "Admin",
    reviews: 131,
    rating: "3.0",
    language: "French",
    soldPropImg: "/assets/images/SoldProperty.png",
    soldPropPrice: "$045M",
    ServicesAreas: [
      "Vineland",
      "Grimsby",
      "Cayuga",
      "Hamilton",
      "Brampton",
      "Mississauga",
      "Toronto",
      "Vancouver",
      "Calgary",
      "Montreal",
    ],
  },
  {
    id: 3,
    name: "Cristiano Ronaldo",
    tag: "Home Smart",
    imgUrl: "/assets/images/CoShopping1.jpeg",
    priceRange: " $550K - $20.5M",
    totalSale: "305",
    citySale: "105",
    status: "Virtual Agent",
    reviews: 131,
    rating: "4.0",
    language: "English",
    soldPropImg: "/assets/images/SoldProperty.png",
    soldPropPrice: "$045M",
    ServicesAreas: [
      "Vineland",
      "Grimsby",
      "Cayuga",
      "Hamilton",
      "Brampton",
      "Mississauga",
      "Toronto",
      "Vancouver",
      "Calgary",
      "Montreal",
    ],
  },
  {
    id: 3,
    name: "Cristiano Ronaldo",
    tag: "Home Smart",
    imgUrl: "/assets/images/CoShopping1.jpeg",
    priceRange: " $550K - $20.5M",
    totalSale: "305",
    citySale: "105",
    status: "Mobile Broker",
    reviews: 131,
    rating: "5.0",
    language: "English",
    soldPropImg: "/assets/images/SoldProperty.png",
    soldPropPrice: "$045M",
    ServicesAreas: [
      "Vineland",
      "Grimsby",
      "Cayuga",
      "Hamilton",
      "Brampton",
      "Mississauga",
      "Toronto",
      "Vancouver",
      "Calgary",
      "Montreal",
    ],
  },
  {
    id: 4,
    name: "Cristiano Ronaldo",
    tag: "Home Smart",
    imgUrl: "/assets/images/CoShopping1.jpeg",
    priceRange: " $550K - $20.5M",
    totalSale: "305",
    citySale: "105",
    status: "Mortgage Agent",
    reviews: 131,
    rating: "2.0",
    language: "English",
    soldPropImg: "/assets/images/SoldProperty.png",
    soldPropPrice: "$045M",
    ServicesAreas: [
      "Vineland",
      "Grimsby",
      "Cayuga",
      "Hamilton",
      "Brampton",
      "Mississauga",
      "Toronto",
      "Vancouver",
      "Calgary",
      "Montreal",
    ],
  },
  {
    id: 5,
    name: "Cristiano Ronaldo",
    tag: "Home Smart",
    imgUrl: "/assets/images/CoShopping1.jpeg",
    priceRange: " $550K - $20.5M",
    totalSale: "305",
    citySale: "105",
    status: "Mortgage Agent",
    reviews: 131,
    rating: "2.0",
    language: "French",
    soldPropImg: "/assets/images/SoldProperty.png",
    soldPropPrice: "$045M",
    ServicesAreas: [
      "Vineland",
      "Grimsby",
      "Cayuga",
      "Hamilton",
      "Brampton",
      "Mississauga",
      "Toronto",
      "Vancouver",
      "Calgary",
      "Montreal",
    ],
  },
  {
    id: 6,
    name: "Cristiano Ronaldo",
    tag: "Home Smart",
    imgUrl: "/assets/images/CoShopping1.jpeg",
    priceRange: " $550K - $20.5M",
    totalSale: "305",
    citySale: "105",
    status: "Store Agent",
    reviews: 131,
    rating: "5.0",
    language: "French",
    soldPropImg: "/assets/images/SoldProperty.png",
    soldPropPrice: "$045M",
    ServicesAreas: [
      "Vineland",
      "Grimsby",
      "Cayuga",
      "Hamilton",
      "Brampton",
      "Mississauga",
      "Toronto",
      "Vancouver",
      "Calgary",
      "Montreal",
    ],
  },
  {
    id: 7,
    name: "Cristiano Ronaldo",
    tag: "Home Smart",
    imgUrl: "/assets/images/CoShopping1.jpeg",
    priceRange: " $550K - $20.5M",
    totalSale: "305",
    citySale: "105",
    status: "Mobile Broker",
    reviews: 131,
    rating: "5.0",
    language: "French",
    soldPropImg: "/assets/images/SoldProperty.png",
    soldPropPrice: "$045M",
    ServicesAreas: [
      "Vineland",
      "Grimsby",
      "Cayuga",
      "Hamilton",
      "Brampton",
      "Mississauga",
      "Toronto",
      "Vancouver",
      "Calgary",
      "Montreal",
    ],
  },
  {
    id: 8,
    name: "Cristiano Ronaldo",
    tag: "Home Smart",
    imgUrl: "/assets/images/CoShopping1.jpeg",
    priceRange: " $550K - $20.5M",
    totalSale: "305",
    citySale: "105",
    status: "Admin",
    reviews: 131,
    rating: "4.0",
    language: "English",
    soldPropImg: "/assets/images/SoldProperty.png",
    soldPropPrice: "$045M",
    ServicesAreas: [
      "Vineland",
      "Grimsby",
      "Cayuga",
      "Hamilton",
      "Brampton",
      "Mississauga",
      "Toronto",
      "Vancouver",
      "Calgary",
      "Montreal",
    ],
  },
  {
    id: 9,
    name: "Cristiano Ronaldo",
    tag: "Home Smart",
    imgUrl: "/assets/images/CoShopping1.jpeg",
    priceRange: " $550K - $20.5M",
    totalSale: "305",
    citySale: "105",
    status: "Admin",
    reviews: 131,
    rating: "4.0",
    language: "Urdu",
    soldPropImg: "/assets/images/SoldProperty.png",
    soldPropPrice: "$045M",
    ServicesAreas: [
      "Vineland",
      "Grimsby",
      "Cayuga",
      "Hamilton",
      "Brampton",
      "Mississauga",
      "Toronto",
      "Vancouver",
      "Calgary",
      "Montreal",
    ],
  },
  {
    id: 10,
    name: "Tech Smith",
    tag: "Home Smart",
    imgUrl: "/assets/images/CoShopping1.jpeg",
    priceRange: " $550K - $20.5M",
    totalSale: "305",
    citySale: "105",
    status: "Virtual Agent",
    reviews: 131,
    rating: "5.0",
    language: "German",
    soldPropImg: "/assets/images/SoldProperty.png",
    soldPropPrice: "$045M",
    ServicesAreas: [
      "Vineland",
      "Grimsby",
      "Cayuga",
      "Hamilton",
      "Brampton",
      "Mississauga",
      "Toronto",
      "Vancouver",
      "Calgary",
      "Montreal",
    ],
  },
  {
    id: 11,
    name: "Cristiano Ronaldo",
    tag: "Home Smart",
    imgUrl: "/assets/images/CoShopping1.jpeg",
    priceRange: " $550K - $20.5M",
    totalSale: "305",
    citySale: "105",
    status: "Store Agent",
    reviews: 131,
    rating: "4.0",
    language: "English",
    soldPropImg: "/assets/images/SoldProperty.png",
    soldPropPrice: "$045M",
    ServicesAreas: [
      "Vineland",
      "Grimsby",
      "Cayuga",
      "Hamilton",
      "Brampton",
      "Mississauga",
      "Toronto",
      "Vancouver",
      "Calgary",
      "Montreal",
    ],
  },
];
