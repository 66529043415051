import s from "./AboutUs.module.css";
import Footer from "../../common/FooterMain";
import AboutUsCover from "../../../assets/images/AboutUs-cover.png";
import AboutProfile from "../../../assets/images/About_profile.png";

const AboutUs = () => {
  return (
    <div>
      <div className={s.About_main}>
        <div className={s.AboutUs_cover}>
          <img src={AboutUsCover} alt="AboutUs-cover" />
          <div className={s.About_mainHeading}>
            <h1>About Us</h1>
          </div>
        </div>

        <div className={s.withbg_AboutContent}>
          <h2>Who We Are?</h2>
          <p>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
            eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim
            ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut
            aliquip ex ea commodo consequat. Duis aute irure dolor in
            reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
            pariatur. Excepteur sint occaecat cupidatat non proident, sunt in
            culpa qui officia deserunt mollit anim id est laborum. sunt in culpa
            qui officia deserunt mollit anim id est laborum. sunt in culpa qui
            officia deserunt mollit anim id est laborum. sunt in culpa qui
            officia deserunt mollit anim id est laborum.
          </p>
        </div>

        <div className={`${s.withbg_AboutContent} ${s.exbg_AboutContent}`}>
          <h2>Our Mission</h2>
          <p>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
            eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim
            ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut
            aliquip ex ea commodo consequat. Duis aute irure dolor in
            reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
            pariatur. Excepteur sint occaecat cupidatat non proident, sunt in
            culpa qui officia deserunt mollit anim id est laborum. sunt in culpa
            qui officia deserunt mollit anim id est laborum. sunt in culpa qui
            officia deserunt mollit anim id est laborum. sunt in culpa qui
            officia deserunt mollit anim id est laborum.
          </p>
        </div>

        <div className={`${s.withbg_AboutContent}`}>
        <div className={`${s.AboutProfile_content}`}>
        <div className={s.About_profile}>
            <img src={AboutProfile} alt="AboutUs-cover" />
          </div>
          <div>
            <h2>CEO Muhammad Taha</h2>
            <p>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
              enim ad minim veniam, quis nostrud exercitation ullamco laboris
              nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in
              reprehenderit in voluptate velit esse cillum dolore eu fugiat
              nulla pariatur. Excepteur sint occaecat cupidatat non proident,
              sunt in culpa qui officia deserunt mollit anim id est laborum.
              sunt in culpa qui officia deserunt mollit anim id est laborum.
              sunt in culpa qui officia deserunt mollit anim id est laborum.
            </p>
          </div>
          </div>
        </div>

        
          <div className={`${s.withbg_AboutContent} ${s.UnlockFeatures_content}`}>
            <p>
              "Unlock the door to your dreams with a graceful farewell, as we
              guide you home with low commissions, and hearts full of care."
            </p>
            <button className="button mt-4">
              START YOUR FREE TRIAL
            </button>
          </div>
      </div>

      <Footer />
    </div>
  );
};

export default AboutUs;
