import React, { useState, useEffect } from 'react'
import { Swiper, SwiperSlide } from 'swiper/react'

// Import Swiper styles
import 'swiper/css'
import 'swiper/css/free-mode'
import 'swiper/css/navigation'
import 'swiper/css/thumbs'
import 'swiper/css/effect-fade'
import 'swiper/css/pagination'

import './styles.css'

// import required modules
import {
  FreeMode,
  Navigation,
  Thumbs,
  EffectFade,
  Pagination
} from 'swiper/modules'

export default function ImageGallery () {
  const imgData = [
    'https://swiperjs.com/demos/images/nature-1.jpg',
    'https://swiperjs.com/demos/images/nature-2.jpg',
    'https://swiperjs.com/demos/images/nature-3.jpg',
    'https://swiperjs.com/demos/images/nature-4.jpg',
    'https://swiperjs.com/demos/images/nature-5.jpg',
    'https://swiperjs.com/demos/images/nature-6.jpg',
    'https://swiperjs.com/demos/images/nature-7.jpg',
    'https://swiperjs.com/demos/images/nature-8.jpg',
    'https://swiperjs.com/demos/images/nature-9.jpg',
    'https://swiperjs.com/demos/images/nature-10.jpg',
    'assets/images/bridge.jpeg'
  ]

  const [thumbsSwiper, setThumbsSwiper] = useState(null)
  const [thumbsUpdated, setThumbsUpdated] = useState(false)
  const [slidePerScr, setSlidePerScr] = useState(10)

  useEffect(() => {
    function handleResize () {
      const imgsOnScr = Math.floor((0.93 * window.innerWidth) / 120)
      setSlidePerScr(imgsOnScr)
    }
    window.addEventListener('resize', handleResize())
    return () => {
      window.removeEventListener('resize', handleResize())
    }
  }, [])

  useEffect(() => {
    if (thumbsSwiper) {
      if (thumbsSwiper.activeIndex === 0) {
        setThumbsUpdated(true)
      }
    }
  }, [thumbsSwiper])

  return (
    <>
      {thumbsUpdated && (
        <Swiper
          style={{
            '--swiper-navigation-color': '#fff',
            '--swiper-pagination-color': '#fff'
          }}
          spaceBetween={10}
          navigation={true}
          thumbs={{ swiper: thumbsSwiper }}
          modules={[EffectFade, Navigation, Thumbs, Pagination]}
          effect='fade'
          pagination={{ type: 'fraction' }}
          className='mySwiper2'
        >
          {imgData?.map((item, i) => {
            return (
              <SwiperSlide key={i}>
                <div className='swiperMainImg'>
                  <img src={item} alt='Property-img' />
                </div>
              </SwiperSlide>
            )
          })}
        </Swiper>
      )}

      <Swiper
        onSwiper={setThumbsSwiper}
        slidesPerView={slidePerScr}
        spaceBetween={20}
        watchSlidesProgress={true}
        modules={[FreeMode, Navigation, Thumbs]}
        className='mySwiper'
      >
        {imgData?.map((item, i) => (
          <SwiperSlide key={i}>
            <img src={item} alt='Property-img' />
            <p>Living Room</p>
          </SwiperSlide>
        ))}
      </Swiper>
    </>
  )
}
