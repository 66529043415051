import { NavLink } from "react-router-dom";

const Footer = () => {
  return (
    <div className="Footer_Main">
      <div className="pt-3 pb-5 footer_above">
        <div className="pt-4 Footer_des">
          <h6>Company</h6>
          <ul>
              <li>
            <NavLink to="/about">
            About
            </NavLink>
            </li>
              <li>
            <NavLink to="/pricing">
            Pricing
            </NavLink>
            </li>
              <li>
            <NavLink to="/jobs">
            Jobs
            </NavLink>
            </li>
              <li>
            <NavLink to="/blogs/buying">
            Blog
            </NavLink>
            </li>
              <li>
            <NavLink to="/career">
            Career
            </NavLink>
            </li>
          </ul>
        </div>

        <div className="pt-4 Footer_des">
          <h6>Support</h6>
          <ul>
          <li>
            <NavLink to="/about">
            Help & Support
            </NavLink>
            </li>
          <li>
            <NavLink to="/privacy-policy">
            Privacy and Policy
            </NavLink>
            </li>
          <li>
            <NavLink to="/signUp">
            Sign Up
            </NavLink>
            </li>
          <li>
            <NavLink to="/login">
            Log In
            </NavLink>
            </li>
          </ul>
        </div>

        <div className="pt-4 FooterContact_des">
          <h6>Contact Us</h6>
          <ul>
            <li>needhome.ca@gmail.com</li>
            <li>Tel: +92 300 0387 335</li>
            <li>Address: Bahawalpur, Punjab, Pakistan</li>
          </ul>
        </div>
      </div>

      <div className="footer_copyRight pt-3 pb-3">
        <p>@copyright 2023 NeedHome.ca</p>
        <NavLink to="/terms-and-conditions">
          <p>Terms of Service</p>
        </NavLink>
        <NavLink to="/privacy-policy">
          <p>Privacy Policy</p>
        </NavLink>
        <NavLink to="/cookies-policy">
          <p>Cookies</p>
        </NavLink>
      </div>
    </div>
  );
};

export default Footer;
