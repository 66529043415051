import ProfessionalsDetailLayout from '../Components/layout/ProfessionalDetailsLayout'

const PhotographerDetails = () => {
  return (
    <div>
      <ProfessionalsDetailLayout selecteddetpage={'photographerDetails'}/>
    </div>
  )
}

export default PhotographerDetails;
