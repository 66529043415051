import { ListingGalleryModal } from '../../common'
import PropertyDetailsContent from './SubComponents/PropertyDetails'
import { useLocation } from 'react-router-dom'

const PropertyDetailsAndModal = () => {

  const { search } = useLocation()
  const queryParams = new URLSearchParams(search)
  const galleryType = queryParams.get('type')
  const gallerySlideNum = queryParams.get('img')

  return (
    <div>
      {queryParams.size === 0 && <PropertyDetailsContent />}
      {queryParams.size > 0 && <ListingGalleryModal galleryType={galleryType} gallerySlideNum={gallerySlideNum}/>}
    </div>
  )
}

export default PropertyDetailsAndModal
