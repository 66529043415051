export const data = {
  propName: 'BW-W (Suite)',
  propFullName:'5601 Hudson Bend Rd',
  priice: 1550000,
  estimatedPayment: 233433,
  propCity: 'Austin, TX 78734',
  propCountry: 'Hudson Bend ',
  propImg :[
    '/assets/images/bridge.jpeg',
    '/assets/images/bridge.jpeg',
    '/assets/images/bridge.jpeg',
    '/assets/images/bridge.jpeg',
    '/assets/images/bridge.jpeg',
    '/assets/images/bridge.jpeg',
  ],
  propType: 'Precon',
  PropRefBy: 'Marydel Homes',
  PropFeaturedBy: 'www.marydelhomes.com',
  bed:4,
  bath:5,
  parking:1,
  sqft:1020,
  propDescription:
    '**MASSIVE PRICE ADJUSTMENT** WHERE ELSE IN AUSTIN CAN YOU GET 2 Single Family HOMES AND A STUDIO ON 6.55 ACRES FOR THIS PRICE? AWESOME & unique opportunity for a family compound or investors looking for a cash-flow opportunity. 2 Single-Family homes with separate utilities on over 6 acres! 5601 & 5701 Hudson Bend offer the savvy buyer a wide array of possibilities–family compound, STR/income producing, horse property, to your future dream home. Each separately metered private residence was built in 2003, has 3 bedrooms and 2 full baths in addition to a separate 350 sqft studio/workshop allowing for endless opportunities.Stuated on a backdrop of 6.55 acres that looks and feels like a sanctuary. A “two for one” opportunity less than 1 mile to the Lake Travis Marina and a short 30-minute drive to downtown Austin. 2 acres of irrigated landscaping sit adjacent to the property’s creek with an abundance of natural wildlife including deer, turkey, fox, and more. In the Main House, double french doors connect the front entryway to an open living and kitchen with a wall of sliding glass doors seamlessly connecting to the backyard nature sanctuary. Finishes include travertine and scored & stained concrete flooring, custom built-ins and cabinetry in maple, mesquite, and hickory, granite countertops, hand-troweled walls, 8ft solid interior doors, and 10 ft ceilings. The large kitchen has a center island with vegetable sink. A separate, 3/2 second house just a short walk across the lawn is equally well appointed with a large living room, a full kitchen, and its own private large covered. Perfect for a luxury rental property or the pickiest of guests, the Guest House provides the best of accommodations for all. Perfect for those with live-in relatives or those who work at home! Enjoy as one large compound or build your dream home while living in one of the guest residences. The Retreat on the Bend is definitely one that should not be missed!',
  HomeDetails: {
    Views: ['Creek or Stream', 'Woods'],
    HomeDesign: [
      'Built in 2003',
      'Estate',
      'Slab Foundation',
      'Metal Roof',
      'Masonry Siding',
      'Stone Siding'
    ],
    interiorSpace: [
      '5,807 Sq Ft Home',
      '1-Story Property',
      'Open Floorplan',
      'Sound System',
      'Wired For Sound',
      'Wired For Data',
      'Bookcases',
      'Built-In Features',
      'Fireplace in Living Room',
      'Stone Fireplace',
      'Insulated Windows',
      'Window Treatments',
      'Dining Area',
      'Workshop'
    ],
    flooring: ['Wood', 'Concrete', 'Tile'],
    bedAndBathDetails: [
      '6 Main Level Bedrooms',
      'Two Primary Bedrooms',
      'His and Hers Closets',
      'Two Primary Bathrooms',
      '4 Full Bathrooms',
      'Double Vanity'
    ],
    laundryDetails: [
      'Laundry Room',
      'Laundry in Multiple Locations',
      'Washer and Dryer'
    ],
    securityDetails: ['Home Security System', 'Security Lights'],
    parkingDetails: [
      '2 Parking Spaces',
      'Carport',
      'Private Parking',
      'Gravel Driveway',
      'Additional Parking'
    ],
    outdoorFeatures: ['Deck', 'Patio', 'Rear Porch', 'Rain Gutters'],
    kitchenDetails: [
      'Built-In Electric Oven',
      'Cooktop',
      'Range Hood',
      'Dishwasher',
      'Wine Refrigerator',
      'Kitchen Island',
      'Granite Countertops',
      'Disposal'
    ],
    schoolDetails: [
      'Lake Travis Elementary School',
      'Hudson Bend Middle School',
      'Lake Travis High School'
    ],
    utilitiesDetails: [
      'Central Heating and Cooling System',
      'Municipal Utilities District Water',
      'Water Purifier',
      'Septic Tank'
    ],
    additionalFeatureDetails: [
      'No Interior Steps',
      'Sustainability products and practices used to construct the property include Conserving Methods',
      'Guest House'
    ],
    lotWidthDetails: [
      '7 Acre Lot',
      'Home fronts a creek',
      'Home Entrance Faces East',
      'Stone Wall',
      'Partially Fenced Property',
      'Wood Fence',
      'Native Plants',
      'Corner Lot',
      'Pie Shaped Lot',
      'Partial Sprinkler System',
      'Mature Trees',
      'Many Trees',
      'Back Yard Fenced',
      'Garden',
      'The community has rules related to exclusive easements'
    ],
    communityDetails: [
      'No Interior Steps',
      'Sustainability products and practices used to construct the property include Conserving Methods',
      'Guest House'
    ],
    financialDetails: [
      '$8,832 Total Annual Tax in 2022',
      'Homeowner Tax Exemptions: See Remarks',
      'Legal Lot and Block 40 / 1',
      'Assessor Parcel Number 01626001110000',
      '2% Total Tax Rate'
    ]
  },
  PropScores: {
    soundScore: 84,
    transitScore: 95,
    walkScore: 45,
    bikeScore: 74,
    driveDistance: '44 min drive'
  },
  PropPriceHistory: [
    {
      date: '06/01/2021',
      event: 'Sold',
      Price: '3434',
      Change: '',
      SqftPrice: '453434'
    },
    {
      date: '06/01/2021',
      event: 'Under Contract',
      Price: '123234',
      Change: '',
      SqftPrice: '453434'
    },
    {
      date: '06/01/2021',
      event: 'Under Contract',
      Price: '',
      Change: 'q32',
      SqftPrice: '453434'
    },
    {
      date: '06/01/2021',
      event: 'For Sale',
      Price: '',
      Change: '234523',
      SqftPrice: '453434'
    }
  ],
  TaxPriceHistory: [
    {
      Year: '2023',
      taxPaid: '9586',
      taxAssessment: '53434',
      land: '41526',
      improvement: '45895'
    },
    {
      Year: '2023',
      taxPaid: '9586',
      taxAssessment: '53434',
      land: '41526',
      improvement: '45895'
    },
    {
      Year: '2023',
      taxPaid: '9586',
      taxAssessment: '53434',
      land: '41526',
      improvement: '45895'
    },
    {
      Year: '2023',
      taxPaid: '9586',
      taxAssessment: '53434',
      land: '41526',
      improvement: '45895'
    },
    {
      Year: '2023',
      taxPaid: '9586',
      taxAssessment: '53434',
      land: '41526',
      improvement: '45895'
    }
  ],
  MLSData: [
    {
      ListingData: {
        'Property Type': 'Residential',
        'Property Sub Type': 'Single Family Residence',
        Restrictions: 'Deed Restrictions',
        'Year Built': 1995,
        'Additional Parcels': 'No',
        'Direction Faces': 'NE',
        Directions: [
          'From westbound RM 2222, turn left onto N FM 620',
          'Turn right onto Hudson Bend Rd',
          'Turn left onto Rocky Ridge Rd',
          'Turn left onto Serrano Trail',
          'Take the second left onto Serrano Trail',
          'The home will be on the right'
        ],
        Disclosures: ['Other Disclosures', 'Seller Disclosure'],
        'Estimated Taxes': 9619.0,
        'ETJ Extra Territorial Jurdn': 'No',
        'FEMA Flood Plain': 'No',
        Fencing: 'Wire',
        'Green Energy Efficient': 'None',
        'Green Sustainability': 'None',
        'Guest Accommodation Description': 'None',
        Intermediary: 'Yes',
        Levels: 'One',
        'Living Area': 1925.0,
        'Other Structures': ['RV/Boat Storage', 'See Remarks'],
        'Special Listing Conditions': 'Standard',
        'Special Features': 'None'
      },
      interiorDetails: {
        'Interior Amenities': [
          'Breakfast Bar',
          'Ceiling Fan(s)',
          'Ceiling-Vaulted',
          'Counter-Quartz',
          'Electric Dryer Hookup',
          'In-Law Floorplan',
          'No Interior Steps',
          'Open Floorplan',
          'Primary Bedroom on Main',
          'Recessed Lighting',
          'Storage',
          'Walk-In Closet(s)',
          'Washer Hookup'
        ],
        'Laundry Location': ['Inside', 'Laundry Room'],
        View: ['Trees/Woods'],
        Flooring: ['Tile', 'Wood'],
        'Num Dining': 1,
        'Num Living': 1,
        'Accessibility Features': 'None',
        Appliances: [
          'Dishwasher',
          'Disposal',
          'Electric Range',
          'Exhaust Fan',
          'Microwave',
          'Stainless Steel Appliance(s)',
          'Water Heater-Electric'
        ],
        'Main Level Bedrooms': 4,
        'Property Condition': 'Resale',
        'Security Features': ['Security System', 'Smoke Detector(s)'],
        'Window Features': ['Screens']
      },
      bedBathDetails: {
        'Full Bathrooms': 1,
        Bathrooms: 1,
        Bedrooms: 1
      },
      buildingInfo: {
        'Construction Materials': ['Masonry – All Sides', 'Stone'],
        'New Construction': 'No'
      },
      garageInfo: {
        'Parking Features': ['Carport', 'Covered'],
        'Total Parking Spaces': '2.00'
      },
      associationAmenities: {
        'Community Features': [
          'Lake',
          'Park',
          'Lake',
          'Picnic Area',
          'Playground',
          'Walk/Bike/Hike/Jog Trail(s, See Remarks'
        ],
        Association: 'No'
      },
      exteriorfeatures: {
        'Exterior Features': ['Exterior Steps', 'Gutters Full', 'Private Yard'],
        Roof: 'Composition',
        Foundation: 'Slab',
        'Patio And Porch Features': [
          'Covered',
          'Deck',
          'Front Porch',
          'See Remarks'
        ],
        'Pool Features': 'None',
        'Private Pool': 'No',
        'Water Body Name': 'Lake Travis',
        'Waterfront Features': 'None',
        Waterfront: 'No'
      },
      utilitiesDetails: {
        Sewer: 'Septic Tank',
        Utilities: [
          'Cable Available',
          'Electricity Available',
          'Internet-Cable',
          'Water Available'
        ],
        Heating: ['Central', 'Electric'],
        'Water Source': 'Municipal Utility District (MUD)',
        Cooling: ['Attic Fan', 'Central Air', 'Electric']
      },
      lotDetails: {
        'Lot Features': [
          'Back Yard',
          'Front Yard',
          'Interior Lot',
          'Native Plants',
          'Sprinkler - In-ground',
          'Many Trees',
          'Trees-Medium (20 Ft - 40 Ft)',
          'Trees-Small (Under 20 Ft)'
        ],
        'Parcel Number': '01626304200000',
        'Lot Size Acres': 0.6119,
        'Lot Size Sq Ft': 26654.36,
        'Subdivision Name': 'Cook Gerald T Sub Sec 2 Amd'
      },
      TaxInfo: {
        'Tax Filled Sqft Total': 1813,
        'Tax Lot': '9A',
        'Tax Rate': 1.77,
        'Tax Year': 2022,
        'Tax Annual Amount': 9619.29,
        'Tax Exemptions': 'None'
      },
      compensationDetails: {
        'Buyer Agency Compensation': 3.0,
        'Sub-Agency Compensation': 0.0,
        'Buyers Agency Compensation Disclaimer':
          'The listing broker’s offer of compensation is made only to participants of the MLS where the listing is filed.'
      }
    }
  ],
  incentiveData: [
    {
      LimitedTimeProgram: [
        'EXTENDED DEPOSIT STRUCTURE*',

        '$30,000 DISCOUNT AT SIGNING ON LOWER LEVEL UNITS*',
        '$40,000 DISCOUNT AT SIGNING ON MAIN LEVEL UNITS*',
        '$50,000 DISCOUNT AT SIGNING ON UPPER LEVEL UNITS*',
        'Plus',
        'FREE ASSIGNMENT (Value of $15,000)*',
        'Plus',
        'RIGHT TO LEASE DURING OCCUPANCY (Value of $1,500)*',
        'Plus',
        'CAPPED DEVELOPMENT CHARGES $15,000*',
        'Plus',
        'UPGRADED GRANITE COUNTERTOP IN KITCHEN*',
        'Plus',
        'FIVE APPLIANCES* *Incentive current as of May 12, 2023'
      ],
      citadelTimeProgram: [
        'INCENTIVE PROGRAM* EXTENDED DEPOSIT STRUCTURE* Plus FREE ASSIGNMENT* (Value of $15,000)',
        'Plus',
        'RIGHT TO LEASE DURING OCCUPANCY* (Value of $1,500)',
        'Plus',
        'CAPPED DEVELOPMENT CHARGES $15,000*',
        'Plus',
        'QUARTZ COUNTERTOP IN KITCHEN (LEVEL 1)*',
        'Plus',
        '5 FREE APPLIANCES (LEVEL 1)*',
        'Plus',
        'FREE BELL HIGH SPEED INTERNET & “BETTER” FIBE',
        '*Incentive current as of May 15, 2023'
      ]
    }
  ],
  PricingAndFee: [
    {
      DepositStructure: [
        'The Citadel:',
        '$10,000 on Signing (BANK DRAFT)',
        'Balance of 5% in 30 days',
        '2.5% in 180 days',
        '2.5% in 270 days',
        '2.5% in 365 days',
        '2.5% in 420 days',
        '5% on Occupancy',
        'Urban Towns: $10,000 on Signing',
        'Balance of 5% in 90 days',
        '5% in 180 days',
        '5% in 365 days',
        '5% on Occupancy',
        'PAYABLE TO: MILLER THOMSON LLP, IN TRUST'
      ]
    }
  ],
  FeatureAndFurnishData: [
    {
      structure: [
        'Engineered steel structure with concrete subfloors.',
        'All units to meet or exceed Ontario Building Code requirements for sound and fire proofing.',
        'Concrete balcony with tinted glass railing.',
        '9’-0” ceilings throughout.'
      ],
      trimWork: [
        'All interior walls will be painted in BENJAMIN MOORE Balboa Mist #OC27.',
        'Classique Series 800 two-panel interior doors and all trim to be painted Benjamin Moore #CC-30 Oxford White.',
        'Bedroom closet doors to be white flat panel sliding doors.',
        'Luxurious trim package including 4 1/4” baseboards and 2 3/4” casings.',
        'Interior doors to include Nickel colored knobs as per Vendor’s standard samples.',
        'Smooth ceilings throughout the entire unit.',
        'White wire shelving in all closets.'
      ],
      kitchen: [
        'Stainless steel sink with a single lever MOEN faucet.',
        'White exhaust hood fan over the stove vented to the exterior.',
        'Premium white electric stove, fridge, and dishwasher installed.',
        'Purchaser’s choice from one of three Designer selected color scale packages from Vendor’s standard samples.'
      ],
      bathrooms: [
        'Quality MOEN chrome faucets for all bathroom vanities and tubs/showers as per Vendor’s standard samples.',
        'White plumbing fixtures in all bathrooms.',
        'Exhaust fans in all bathrooms vented to the exterior.',
        'Privacy locks on all bathroom doors.',
        'Plate mirrors above vanities in all bathrooms.',
        'Designer selected bathroom color package as per Vendor’s standard samples.',
        '13 x 13 Ceramic floor tiles with color-matching tiles in tub/shower enclosures as per Vendor’s standard samples.'
      ],
      flooring: [
        'Composite plank vinyl flooring throughout the unit from Vendor’s standard samples.'
      ],
      HVAC: [
        'Forced air gas high-efficiency vertical HVAC unit (heat and air conditioning) with individual suite control (Rental – Contract to be signed on Closing).',
        'Tankless Hot water on demand gas heater on a rental basis. (Rental -Contract to be signed on Closing).',
        'Programmable thermostat installed in the central location of the suite.'
      ],
      electrical: [
        'All units to have a 100amp breaker panel service.',
        'All electrical in accordance with the Ontario Hydro Standard.',
        'Standard light fixtures throughout.',
        'Smoke and Carbon Monoxide detectors as per Ontario Building Code.',
        'TWO TV Cable rough-in locations (one in the family room and one in the master bedroom).',
        'TWO telephone rough-ins (one in the kitchen and one in the master bedroom).',
        'White DECORA light switches and electrical outlets throughout.'
      ],
      technology: [
        'Keyless entry digital suite door lock.',
        'Building sensors will recognize your phone/fob when entering the building and will open entry doors automatically.',
        'Receive condo management communications and parcel delivery notices to your cell phone.',
        'Adjust the setting on your suite thermostat from anywhere.',
        'Access entry cameras that are strategically placed in the building.',
        'Suite security monitored through both the Concierge and your mobile app.'
      ]
    }
  ],
  ProjectSummary: {
    name: 'The Gates of Thornhill',
    Description: [
      'PHASE 1 NOW OPEN! SALES OFFICE AT 7850 DUFFERIN STREET JUST NORTH OF CENTRE STREET',
      '48 URBAN TOWN-HOMES & 1 MID-RISE BUILDING CONSISTING OF 83 UNITS',
      'Discover a luxury townhome community strategically located north of Centre Street on the west side of Dufferin Street. Just steps away from coffee shops, restaurants, community centres, and places of worship; The Gates community is truly an enhancement to the already beautiful city of Thornhill.',
      'This community will consist of a total of 68 Urban town-homes (48 in Phase 1 and 20 in Phase 2) and approximately 530 units in three mid-rise condominiums (5, 10 and 12 stories high respectively).'
    ],
    source: 'Marydel Homes',
    address: '7850 Dufferin Street., Vaughan ON., L4K 1R6',
    availability: [
      'Monday: 02:00 PM - 07:00 PM',
      'Tuesday: 02:00 PM - 07:00 PM',
      'Wednesday: 02:00 PM - 07:00 PM',
      'Thursday: Closed',
      'Friday: Closed',
      'Saturday: 10:00 AM - 05:00 PM',
      'Sunday: 10:00 AM - 05:00 PM'
    ],
    Phone: '16479093069',
    Email: 'sales@thegatesofthornhill.com',
    Links: '',
    contact: 'Request more information'
  }
}
