import { useState, useEffect } from 'react'
import MarketProgressBarImg from '../../../../../../../assets/images/MarketProgressBar.png'
import MarketTolTip from '../../../../../../../assets/images/MarketBalancedTip.png'
import s from '../../ExpertRating.module.css'

const MarketProgressBar = () => {
  const [showToolTip, setShowToolTip] = useState(false)
  const [ToolTipColor, setToolTipColor] = useState('')
  const value = 55

  useEffect(() => {
    if (value >= 0 && value <= 32) {
      setToolTipColor('#4361EE')
      setShowToolTip(false)
    } else if (value > 3 && value <= 60) {
      setShowToolTip(true)
      setToolTipColor('#A5D448')
    } else if (value > 60 && value <= 100) {
      setToolTipColor('#FB6F92')
      setShowToolTip(false)
    }
  }, [])
  return (
    <div className={s.marketProgressBar}>
      <img src={MarketProgressBarImg} alt='Market-ProgressBar' />

      <div
        className={s.bar_tip}
        style={{ left: `${value}%`, background: ToolTipColor }}
      ></div>
      {showToolTip && (
        <div className={s.Progress_ToolTip} style={{ left: `${value - 7}%` }}>
          <img src={MarketTolTip} alt='ToolTip' />
        </div>
      )}

      <div className={s.market_headings}>
            <h6>Buyer’s Market</h6>
            <h6>Seller’s Market</h6>
        </div>
    </div>
  )
}

export default MarketProgressBar
