import style from '../../PropertyDetails.module.css'
import ExpertRatingChart from './Components/ExpertRatingChart'
import MarketProgressBar from './Components/MarketProgressBar'
import s from './ExpertRating.module.css'

const NeeedHomeExpertRating = () => {
  return (
    <div>
      <h2 className={style.PropDetails_hedings}>NeedHome Expert Rating</h2>
      <div className={s.Ratingmain_container}>
        <div className={s.RatingChartandDes_container}>
          <ExpertRatingChart />
          <div className={s.Rating_des}>
            <p>
              With a solid 8.6 out of 10 overall, 360 Torrance is a great option
              for renters. While some aspects, like Trustworthiness, might not
              be as distinguished as other properties nearby, 360 Torrance has a
              number of attractive features namely its Price so you can be
              assured your rent will come with a high standard of living.
            </p>
          </div>
        </div>
        <div className={s.ProgressBar_container}>
          <MarketProgressBar />
        </div>
      </div>

      <div className={s.Rating_categorydes}>
        <h3>How we Rated!</h3>
        <div className={s.category_container}>
          <div className={s.subCategory_container}>
            <div className={s.sub_category}>
              <h4 className={style.sub_headings}>Price</h4>
              <p>
                360 Torrance stands out with a remarkable rating of 9/10 for its
                cost per square foot compared to other rentals in the area.
                Considering the excellent balance between price
                living experience.
              </p>
            </div>
          </div>

          <div className={s.subCategory_container}>
            <div className={s.sub_category}>
              <h4 className={style.sub_headings}>Amenities</h4>
              <p>
                360 Torrance goes above and beyond to provide sought-after
                conveniences and desirable perks, including cats allowed.
                Renters looking for popular or luxurious features will find them
                here—and for that reason, 360 Torrance receives one of the
                highest amenities scores.
              </p>
            </div>
          </div>

          <div className={s.subCategory_container}>
            <div className={s.sub_category}>
              <h4 className={style.sub_headings}>Trustworthiness</h4>
              <p>
                360 Torrance provides some information about the features
                available in the community and its apartments, making it a
                reliable option for prospective renters. Renters should ask
                additional questions about amenities and non-negotiable items
                they are looking for.
              </p>
            </div>
          </div>

          <div className={s.subCategory_container}>
            <div className={s.sub_category}>
              <h4 className={style.sub_headings}>Location</h4>
              <p>
                360 Torrance is exceptional when it comes to location and
                conveniences. Communication with management or leasing staff is
                usually quick and easy and they often use advanced scheduling
                and communication tools to streamline the process. The location
                of this property offers easy access to popular points.
              </p>
            </div>
          </div>

          <div className={s.subCategory_container}>
            <div className={s.sub_category}>
              <h4 className={style.sub_headings}>Quality</h4>
              <p>
                Anyone looking for quality at a great value will find 360
                Torrance to be an attractive option. Renters can also rely on
                this rental to provide them with accurate information they need
                to find the living experience they desire.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default NeeedHomeExpertRating
