import { NavLink, useNavigate } from "react-router-dom";
import { Form, Formik } from "formik";
import AuthLayout from "../../../layout/AuthSection";
import s from "../../../layout/AuthSection/Auth.module.css";
import { AuthFooter, CustomInput,InputPassComponent } from "../../../common";
import { useState } from "react";
import { LogInValidation } from "../../../../actions/validation";
import { validateEmail } from "../../../../actions/commonFunctions";
import { DropdownInActive, GoogleIcon, AppleIcon } from "../../../svgIcons/Icons";

const LogIn = () => {
  const navigate = useNavigate();
  const [isPasswordScr, setIsPasswordScr] = useState(false);
  const [isEmailSubmited, setIsEmailSubmited] = useState(false);
  const [isSubmited, setIsSubmited] = useState(false);

const handlePassScreen=()=>{
  setIsEmailSubmited(true);
  if (validateEmail(data.email)) {
   setIsPasswordScr(!isPasswordScr)
}
}

  const handleSubmit = () => {
    navigate("/login-password");
  };
  

  const [data, setData] = useState({
    email: "",
    password:''
  });

  return (
    <>
      <Formik
        initialValues={{
          data,
        }}
        validate={(values) => LogInValidation(values)}
        onSubmit={handleSubmit}
      >
        {({ errors, touched }) => (
          <Form>
            <div className={s.AuthAll_main}>
              <AuthLayout />
              <div className={s.AuthField_mainContainer}>
                <div className={s.AuthField_container}>
                  <div className={s.Authmain_heading}>
                    <h1>
                      Log<span>in</span>
                    </h1>
                  </div>

                  {!isPasswordScr && (
                    <>
                    <div className="w-100">
                    <CustomInput
                      placeholder="Enter Email"
                      name="email"
                      type="text"
                      data={data}
                      setData={setData}
                    />
                  </div>
                  {isEmailSubmited && (
                    <div className="w-100 text-start">
                      <p className="Invaild_errors m-0">{errors.email}</p>
                    </div>
                  )}
                  <button
                    type="submit"
                    onClick={(e)=>handlePassScreen(e)}
                    className={`${s.AuthButton} button`}
                  >
                    Continue
                  </button>

                  <div className={s.Forgot_email}>
                    <NavLink to="/forgot-email">
                      <p>Forgot email?</p>
                    </NavLink>
                  </div>

                  <div className={s.Authcustomer_service}>
                    <p>
                      Contact our Customer Service at{" "}
                      <span>+92 300 0387 335</span>
                    </p>
                  </div>

                  <div className={s.login_line}>
                    <span className={s.Loginor_Line}></span>
                    <span className={s.loin_or}>or</span>
                    <span className={s.Loginor_Line}></span>
                  </div>

                  <div className="w-100">
                    <button
                      type="submit"
                      className={`${s.AuthButton} ${s.Auth_externalLogin} button`}
                    >
                      <span
                        style={{
                          display: "flex",
                          justifyContent: "flex-start",
                          width: "fit-content",
                          paddingLeft: "25px",
                        }}
                      >
                        <GoogleIcon />
                      </span>
                      <span
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          width: "100%",
                        }}
                      >
                        Continue with Google
                      </span>
                    </button>
                  </div>

                  <div className="w-100">
                    <button
                      className={`${s.AuthButton} ${s.Auth_externalLogin} button`}
                    >
                      <span
                        style={{
                          display: "flex",
                          justifyContent: "flex-start",
                          width: "fit-content",
                          paddingLeft: "25px",
                        }}
                      >
                       <AppleIcon size={'20'} />
                      </span>
                      <span
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          width: "100%",
                        }}
                      >
                        Continue with Apple
                      </span>
                    </button>
                  </div>

                  <div className={s.LoginCreate}>
                    <p>
                      Don’t have a account let’s&nbsp;
                      <span>
                        <NavLink to="/signUp">Create an Account</NavLink>
                      </span>
                    </p>
                  </div>
                    </>
                  )}

                  {isPasswordScr &&(
                    <>
                      <div className={s.AuthMail_value}>
                    <p>{data?.email}</p>
                    <div style={{cursor:'pointer'}} onClick={()=>setIsPasswordScr(false)}>
                    <DropdownInActive />
                    </div>
                  </div>
                  <div className="w-100">
                    <InputPassComponent
                      setData={setData}
                      placeholder="Password"
                      name="password"
                      type="text"
                      data={data}
                    />
                  </div>
                  {isSubmited && (
                    <div className="w-100 text-start">
                      <p className="Invaild_errors m-0">{errors.password}</p>
                    </div>
                  )}
                  <button
                    type="submit"
                    onClick={() => setIsSubmited(true)}
                    className={`${s.AuthButton} button`}
                  >
                    Log in
                  </button>

                  <div
                    className={s.Forgot_email}
                    style={{ justifyContent: "center" }}
                  >
                    <NavLink to="/reset-password">
                      <p>Forgot password?</p>
                    </NavLink>
                  </div>
                    </>
                  )}
                </div>
              </div>
            </div>
            <div style={{ marginTop: isPasswordScr ? '90px' : '50px'}}>
              <AuthFooter />
            </div>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default LogIn;
