import s from '../../../pages/Notes/Notes.module.css'
import { NotePadModal } from '../../../common'
import {
  BedIcon,
  BathIcon,
  SqftIcon,
  FullScreenIcon,
  DeleteIcon
} from '../../../svgIcons/Icons'
import { useState } from 'react'

const NotesCard = ({ data}) => {

const [isNotepadModalOpen , setIsNotepadModalOpen] = useState(false);

const handleOpen = () => setIsNotepadModalOpen(true);

  return (
    <div className={s.Notes_card}>
      <div className={s.Notesprop_card}>
        <div className={s.propertyImg}>
          <img src={data?.propImg} alt='Property-img' />
        </div>
        <div>
          <h4 className={s.PropPrice}>{data?.propPrice}</h4>
          <p className={s.PropName}>{data?.propName}</p>
          <p className={s.PropAddress}>{data?.propAddress}</p>

          <div className={s.Propdetails}>
            <div className='d-flex align-items-center'>
              <BedIcon />
              <p>{data?.propBed}</p>
            </div>
            <div className='d-flex align-items-center'>
              <BathIcon />
              <p>{data?.propBath}</p>
            </div>
            <div className='d-flex align-items-center'>
              <SqftIcon />
              <p>{`${data?.propArea} Sqft`}</p>
            </div>
          </div>
        </div>
      </div>

      <div className={s.NotesDes}>
        <p>
          {data?.propNotes && data.propNotes.length > 215
            ? `${data.propNotes.slice(0, 215)} ...`
            : data?.propNotes}
        </p>
      </div>

      <div className='d-flex justify-content-between mt-4'>
        <button className={`button ${s.FullViewBtn}`} onClick={handleOpen}>
          <FullScreenIcon /> Full View
        </button>
        <button className={`button ${s.DeleteBtn}`}>
          <DeleteIcon /> Delete
        </button>
      </div>

      <NotePadModal NotesDes={data?.propNotes} isNotepadModalOpen={isNotepadModalOpen} setIsNotepadModalOpen={setIsNotepadModalOpen}/>
    </div>
  )
}

export default NotesCard
