export const langOptionsData = [
  'All Languages',
  'English',
  'Arabic',
  'Bangali',
  'Cantonese',
  'Farsi',
  'French',
  'German',
  'Greek',
  'Hebrew',
  'Hindi',
  'Hungarian',
  'Italian',
  'Japanese',
  'Korean',
  'Mandarin',
  'Polish',
  'Portuguese',
  'Russian',
  'Spanish',
  'Tagalog (Filipino)',
  'Thai',
  'Turkish',
  'Vietnamese'
]

export const RatingStarData = ['All', '1.0', '2.0', '3.0', '4.0', '5.0']

export const EstateAgentSpecialtiesData = [
  'Any',
  "Buyer's Agent",
  'Listing Agent',
  'Relocation',
  'Foreclosure',
  'Short-Sale',
  'Consulting',
  'Other'
]

export const HomeImprSpecialitiesData = [
  'Any',
  'Architecture',
  'Carpentry',
  'Electrical',
  'Engineering',
  'General Contracting',
  'Interior Design',
  'Landscaping',
  'Painting',
  'Plumbing',
  'Other'
]

export const PropertyManSpecialitiesData = [
  'Any',
  'LandLord',
  'Vacation/Short-term Rentals'
]

export const InspectorSpecialitiesData = [
  'Any',
  'General Inspections',
  'Pest Inspections',
  'Chimney Inspections',
  'Sewer Inspections',
  'Roof Inspections',
]

export const PhotographerSpecialitiesData = [
  'Any',
  'NeedHome Video Walkthrough',
  'Hi-Res Photos',
  'Aerial Photgraphy',
  'Video',
  'Twilight Photography',
  '3D Tours',
  'Floor Plans',
  'Marketing Services',
  'NeedHome Certified Phographer',
  'NeedHome Select Phographer',
]

export const OtherAgentSpecialitiesData = [
  'Any',
  'Appraisal',
  'Commercial R.E.',
  'Escrow',
  'Insurance',
  'Legal',
  'Movers',
  'Notaries',
  'Stagers',
  'Title',
  'Other',
]

export const AgentReviewsSorting = [
  'Newest First',
  'Rating (High to Low)',
  'Rating(Low to High)'
]

export const AgentReviewsFilter = [
  'All Reviews',
  'Helped me Buy',
  'Helped me sell',
  'Rating or Property management'
]
