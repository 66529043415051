export const data = [
  {
    id:1,
    name: "Cristiano Ronaldo",
    location: "New York, America",
    imgUrl: "/assets/images/CoShopping1.jpeg",
    rating: "4.0",
    reviews: 131,
    language: "English",
    company: "Szukima Manager Inc",
    description: ' Trust Best Moving Company Toronto to handle your move with professionalism, care, and efficiency, delivering top-notch service every time. For more details visit us at  https://www.bestmovingcompanytoronto.ca or contact us at647-957-2314 Address:- Toronto, ON #BestMovingCompanyToronto #MoversToronto #Toronto #ON.',
    ServicesAreas: [
      "Vineland",
      "Grimsby",
      "Cayuga",
      "Hamilton",
      "Brampton",
      "Mississauga",
      "Toronto",
      "Vancouver",
      "Calgary",
      "Montreal",
    ],
    specialities:[
      'Stagging', 'Property management', 'Vacation / Short-term Rentals'
    ],

  },
  {
    id:2,
    name: "Cristiano Ronaldo",
    location: "New York, America",
    imgUrl: "/assets/images/CoShopping1.jpeg",
    rating: "3.0",
    reviews: 131,
    language: "French",
    company: "Szukima Manager Inc",
    description: ' Trust Best Moving Company Toronto to handle your move with professionalism, care, and efficiency, delivering top-notch service every time. For more details visit us at  https://www.bestmovingcompanytoronto.ca or contact us at647-957-2314 Address:- Toronto, ON #BestMovingCompanyToronto #MoversToronto #Toronto #ON.',
    ServicesAreas: [
      "Vineland",
      "Grimsby",
      "Cayuga",
      "Hamilton",
      "Brampton",
      "Mississauga",
      "Toronto",
      "Vancouver",
      "Calgary",
      "Montreal",
    ],
    specialities:[
      'Stagging', 'Property management', 'Vacation / Short-term Rentals'
    ]
  },
  {
    id:3,
    name: "Cristiano Ronaldo",
    location: "New York, America",
    imgUrl: "/assets/images/CoShopping1.jpeg",
    rating: "4.0",
    reviews: 131,
    language: "French",
    company: "Szukima Manager Inc",
    description: ' Trust Best Moving Company Toronto to handle your move with professionalism, care, and efficiency, delivering top-notch service every time. For more details visit us at  https://www.bestmovingcompanytoronto.ca or contact us at647-957-2314 Address:- Toronto, ON #BestMovingCompanyToronto #MoversToronto #Toronto #ON.',
    ServicesAreas: [
      "Vineland",
      "Grimsby",
      "Cayuga",
      "Hamilton",
      "Brampton",
      "Mississauga",
      "Toronto",
      "Vancouver",
      "Calgary",
      "Montreal",
    ],
    specialities:[
      'Stagging', 'Property management', 'Vacation / Short-term Rentals'
    ]
  },
  {
    id:4,
    name: "Cristiano Ronaldo",
    location: "New York, America",
    imgUrl: "/assets/images/CoShopping1.jpeg",
    rating: "4.0",
    reviews: 131,
    language: "English",
    company: "Szukima Manager Inc",
    description: ' Trust Best Moving Company Toronto to handle your move with professionalism, care, and efficiency, delivering top-notch service every time. For more details visit us at  https://www.bestmovingcompanytoronto.ca or contact us at647-957-2314 Address:- Toronto, ON #BestMovingCompanyToronto #MoversToronto #Toronto #ON.',
    ServicesAreas: [
      "Vineland",
      "Grimsby",
      "Cayuga",
      "Hamilton",
      "Brampton",
      "Mississauga",
      "Toronto",
      "Vancouver",
      "Calgary",
      "Montreal",
    ],
    specialities:[
      'Stagging', 'Property management', 'Vacation / Short-term Rentals'
    ]
  },
  {
    id:5,
    name: "Cristiano Ronaldo",
    location: "New York, America",
    imgUrl: "/assets/images/CoShopping1.jpeg",
    rating: "4.0",
    reviews: 131,
    language: "German",
    company: "Szukima Manager Inc",
    description: ' Trust Best Moving Company Toronto to handle your move with professionalism, care, and efficiency, delivering top-notch service every time. For more details visit us at  https://www.bestmovingcompanytoronto.ca or contact us at647-957-2314 Address:- Toronto, ON #BestMovingCompanyToronto #MoversToronto #Toronto #ON.',
    ServicesAreas: [
      "Vineland",
      "Grimsby",
      "Cayuga",
      "Hamilton",
      "Brampton",
      "Mississauga",
      "Toronto",
      "Vancouver",
      "Calgary",
      "Montreal",
    ],
    specialities:[
      'Stagging', 'Property management', 'Vacation / Short-term Rentals'
    ]
  },
  {
    id:6,
    name: "Cristiano Ronaldo",
    location: "New York, America",
    imgUrl: "/assets/images/CoShopping1.jpeg",
    rating: "4.0",
    reviews: 131,
    language: "English",
    company: "Szukima Manager Inc",
    description: ' Trust Best Moving Company Toronto to handle your move with professionalism, care, and efficiency, delivering top-notch service every time. For more details visit us at  https://www.bestmovingcompanytoronto.ca or contact us at647-957-2314 Address:- Toronto, ON #BestMovingCompanyToronto #MoversToronto #Toronto #ON.',
    ServicesAreas: [
      "Vineland",
      "Grimsby",
      "Cayuga",
      "Hamilton",
      "Brampton",
      "Mississauga",
      "Toronto",
      "Vancouver",
      "Calgary",
      "Montreal",
    ],
    specialities:[
      'Stagging', 'Property management', 'Vacation / Short-term Rentals'
    ]
  },
  {
    id:7,
    name: "Cristiano Ronaldo",
    location: "New York, America",
    imgUrl: "/assets/images/CoShopping1.jpeg",
    rating: "4.0",
    reviews: 131,
    language: "German",
    company: "Szukima Manager Inc",
    ServicesAreas: [
      "Vineland",
      "Grimsby",
      "Cayuga",
      "Hamilton",
      "Brampton",
      "Mississauga",
      "Toronto",
      "Vancouver",
      "Calgary",
      "Montreal",
    ],
    specialities:[
      'Stagging', 'Property management', 'Vacation / Short-term Rentals'
    ]
  },
  {
    id:8,
    name: "Cristiano Ronaldo",
    location: "New York, America",
    imgUrl: "/assets/images/CoShopping1.jpeg",
    rating: "4.0",
    reviews: 131,
    language: "English",
    company: "Szukima Manager Inc",
    ServicesAreas: [
      "Vineland",
      "Grimsby",
      "Cayuga",
      "Hamilton",
      "Brampton",
      "Mississauga",
      "Toronto",
      "Vancouver",
      "Calgary",
      "Montreal",
    ],
    specialities:[
      'Stagging', 'Property management', 'Vacation / Short-term Rentals'
    ]
  },
  {
    id:9,
    name: "Cristiano Ronaldo",
    location: "New York, America",
    imgUrl: "/assets/images/CoShopping1.jpeg",
    rating: "4.0",
    reviews: 131,
    language: "English",
    company: "Szukima Manager Inc",
    ServicesAreas: [
      "Vineland",
      "Grimsby",
      "Cayuga",
      "Hamilton",
      "Brampton",
      "Mississauga",
      "Toronto",
      "Vancouver",
      "Calgary",
      "Montreal",
    ],
    specialities:[
      'Stagging', 'Property management', 'Vacation / Short-term Rentals'
    ]
  },
  {
    id:10,
    name: "Cristiano Ronaldo",
    location: "New York, America",
    imgUrl: "/assets/images/CoShopping1.jpeg",
    rating: "4.0",
    reviews: 131,
    language: "German",
    company: "Szukima Manager Inc",
    ServicesAreas: [
      "Vineland",
      "Grimsby",
      "Cayuga",
      "Hamilton",
      "Brampton",
      "Mississauga",
      "Toronto",
      "Vancouver",
      "Calgary",
      "Montreal",
    ],
    specialities:[
      'Stagging', 'Property management', 'Vacation / Short-term Rentals'
    ]
  },
  {
    id:11,
    name: "Cristiano Ronaldo",
    location: "New York, America",
    imgUrl: "/assets/images/CoShopping1.jpeg",
    rating: "4.0",
    reviews: 131,
    language: "French",
    company: "Szukima Manager Inc",
    ServicesAreas: [
      "Vineland",
      "Grimsby",
      "Cayuga",
      "Hamilton",
      "Brampton",
      "Mississauga",
      "Toronto",
      "Vancouver",
      "Calgary",
      "Montreal",
    ],
    specialities:[
      'Stagging', 'Property management', 'Vacation / Short-term Rentals'
    ]
  },
  {
    id:12,
    name: "Cristiano Ronaldo",
    location: "New York, America",
    imgUrl: "/assets/images/CoShopping1.jpeg",
    rating: "4.0",
    reviews: 131,
    language: "English",
    company: "Szukima Manager Inc",
    ServicesAreas: [
      "Vineland",
      "Grimsby",
      "Cayuga",
      "Hamilton",
      "Brampton",
      "Mississauga",
      "Toronto",
      "Vancouver",
      "Calgary",
      "Montreal",
    ],
    specialities:[
      'Stagging', 'Property management', 'Vacation / Short-term Rentals'
    ]
  },
];

