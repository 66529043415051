 export const data = [
    {
      id:1,
      name: "Cristiano Ronaldo",
      location: "New York, America",
      imgUrl: "/assets/images/CoShopping1.jpeg",
      rating: "4.0",
      reviews: 131,
      language: "English",
      status: "Store Agent",
    },
    {
      id:2,
      name: "Cristiano Ronaldo",
      location: "New York, America",
      imgUrl: "/assets/images/CoShopping1.jpeg",
      rating: "3.0",
      reviews: 131,
      language: "French",
      status: "Virtual Agent",
    },
    {
      id:3,
      name: "Cristiano Ronaldo",
      location: "New York, America",
      imgUrl: "/assets/images/CoShopping1.jpeg",
      rating: "4.0",
      reviews: 131,
      language: "French",
      status: "Store Agent",
    },
    {
      id:4,
      name: "Cristiano Ronaldo",
      location: "New York, America",
      imgUrl: "/assets/images/CoShopping1.jpeg",
      rating: "4.0",
      reviews: 131,
      language: "English",
      status: "Mobile Broker",
    },
    {
      id:5,
      name: "Cristiano Ronaldo",
      location: "New York, America",
      imgUrl: "/assets/images/CoShopping1.jpeg",
      rating: "4.0",
      reviews: 131,
      language: "German",
      status: "Admin",
    },
    {
      id:6,
      name: "Cristiano Ronaldo",
      location: "New York, America",
      imgUrl: "/assets/images/CoShopping1.jpeg",
      rating: "4.0",
      reviews: 131,
      language: "English",
      status: "Mobile Broker",
    },
    {
      id:7,
      name: "Cristiano Ronaldo",
      location: "New York, America",
      imgUrl: "/assets/images/CoShopping1.jpeg",
      rating: "4.0",
      reviews: 131,
      language: "German",
      status: "Store Agent",
    },
    {
      id:8,
      name: "Cristiano Ronaldo",
      location: "New York, America",
      imgUrl: "/assets/images/CoShopping1.jpeg",
      rating: "4.0",
      reviews: 131,
      language: "English",
      status: "Admin",
    },
    {
      id:9,
      name: "Cristiano Ronaldo",
      location: "New York, America",
      imgUrl: "/assets/images/CoShopping1.jpeg",
      rating: "4.0",
      reviews: 131,
      language: "English",
      status: "Virtual Agent",
    },
    {
      id:10,
      name: "Cristiano Ronaldo",
      location: "New York, America",
      imgUrl: "/assets/images/CoShopping1.jpeg",
      rating: "4.0",
      reviews: 131,
      language: "German",
      status: "Admin",
    },
    {
      id:11,
      name: "Cristiano Ronaldo",
      location: "New York, America",
      imgUrl: "/assets/images/CoShopping1.jpeg",
      rating: "4.0",
      reviews: 131,
      language: "French",
      status: "Mobile Brokers",
    },
    {
      id:12,
      name: "Cristiano Ronaldo",
      location: "New York, America",
      imgUrl: "/assets/images/CoShopping1.jpeg",
      rating: "4.0",
      reviews: 131,
      language: "English",
      status: "Admin",
    },
  ];

