import style from '../../PropertyDetails.module.css'
import s from './OverView.module.css'

const OverView = () => {
  return (
    <div>
      <h2 className={style.PropDetails_hedings}>Overview</h2>
      <div className={s.OverView}>
        <p>
          The Gates of Thornhill is a new condo and townhouse community by
          Marydel Homes currently in preconstruction at 7850 Dufferin Street,
          Vaughan. The community is scheduled for completion in 2025. Available
          units range in price from $419,900 to over $1,029,900. The Gates of
          Thornhill has a total of 598 units. Sizes range from 325 to 1375
          square feet.
        </p>
      </div>
    </div>
  )
}

export default OverView
