import s from '../Professionals.module.css'
import { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { data } from './EstateAgentData'
import { Footer } from '../../../common'
import { EstateAgentSpecialtiesData } from '../../../../data/constant'
import {
  AgentEveryone,
  VirtualAgent,
  StoreAgent,
  MobileBrokers,
  AdminAgents,
  AgentCall,
  AgentRatingStar
} from '../../../svgIcons/Icons'
import FilterPagesHeader from '../Components/FilterPagesHeader'
import FilterSearchCriteria from '../Components/FilterSearchCriteria'

const EstateAgent = () => {
  const navigate = useNavigate()
  const [filterIndex, setFilterIndex] = useState(0)
  const [filterCriteria, setFilterCriteria] = useState('')
  const [filterData, setFilterData] = useState([])
  const [filteredCardData, setFilteredCardData] = useState([])

  const filterAgents = [
    { name: 'Everyone', icon: AgentEveryone },
    { name: 'Virtual Agents', icon: VirtualAgent },
    { name: 'Store Agents', icon: StoreAgent },
    { name: 'Mobile Brokers', icon: MobileBrokers },
    { name: 'Admin', icon: AdminAgents }
  ]

  const handleFilterAgents = i => {
    setFilterIndex(i)
  }

  useEffect(() => {
    if (filterIndex === 0) {
      setFilterCriteria('Everyone')
    } else if (filterIndex === 1) {
      setFilterCriteria('Virtual Agent')
    } else if (filterIndex === 2) {
      setFilterCriteria('Store Agent')
    } else if (filterIndex === 3) {
      setFilterCriteria('Mobile Broker')
    } else if (filterIndex === 4) {
      setFilterCriteria('Admin')
    }
  }, [filterIndex])

  useEffect(() => {
    setFilterData(data)
  }, [])

  useEffect(() => {
    const filteredData = data?.filter(
      item => filterCriteria === 'Everyone' || item.status === filterCriteria
    )
    setFilterData(filteredData)
  }, [filterCriteria])

  const handleDetails = id => {
    navigate(`/estate-agent-details/${id}`)
  }

  return (
    <>
      <div className={s.AgentMain}>
        <div>
          <FilterPagesHeader page={'Agents'} />
        </div>

        <div className={`${s.Prof_mainContainer} mt-4`}>
          <FilterSearchCriteria
            data={filterData}
            setFilteredCardData={setFilteredCardData}
            placeholder={'Agent Name'}
            options={EstateAgentSpecialtiesData}
          />

          <div className={`${s.pageMain_heading} mt-4`}>
            <h2>Real Estate Agents</h2>
            {filteredCardData?.length > 0 ? (
              <p>{`${filteredCardData?.length.toLocaleString(
                'en-Us'
              )} agents found`}</p>
            ) : (
              <p>{`No agents found`}</p>
            )}
          </div>

          <hr
            className='mt-4 mb-4'
            style={{
              color: '#d9d9d9',
              height: '2.5px',
              backgroundColor: '#d9d9d9'
            }}
          />

          <div className={s.agentsCardSec}>
            <div className={s.AllAgent_filter}>
              {filterAgents?.map((item, i) => (
                <div
                  key={i}
                  className={`${s.filterAgent} ${
                    filterIndex === i ? s.ActiveDiv : ''
                  }`}
                  onClick={() => handleFilterAgents(i)}
                >
                  <item.icon color={filterIndex === i ? '#222' : '#7E7E7E'} />
                  <p>{item.name}</p>
                </div>
              ))}
            </div>

            <div className={`mt-4 ${s.Agent_cards}`}>
              {filteredCardData?.map((item, i) => {
                return (
                  <div className={s.Agent_card} key={i}>
                    <div
                      className='d-flex w-100'
                      onClick={() => handleDetails(item?.id)}
                    >
                      <div className={s.Agent_cardprofile}>
                        <img
                          src={`/assets/images/CoShopping1.jpeg`}
                          alt='AgentProfilePic'
                        />
                      </div>
                      <div className={s.AgentCardContent}>
                        <h3>{item?.name}</h3>
                        <p className={s.AgentTag}>{item?.tag}</p>
                        <div
                          className={`${s.Agent_rating} ${s.esAgent_rating}`}
                        >
                          <AgentRatingStar />
                          <p>
                            {item?.rating}{' '}
                            <span>{`(${item?.reviews} Reviews)`}</span>
                          </p>
                          <div className={s.esLan_speaking}>
                            <p>{`${item?.language} Speaking`}</p>
                          </div>
                        </div>
                        <p className={s.AgentPr_range}>
                          Price Range: <span>{item?.priceRange}</span>
                        </p>
                        <div className='d-flex'>
                          <div className={s.AgentSale}>
                            <p>{`${item?.totalSale} Total Sales`}</p>
                          </div>
                          <div
                            className={`${s.AgentSale} ms-2 ${s.AgentcitySale}`}
                          >
                            <p>{`${item?.citySale} in Toronto`}</p>
                          </div>
                        </div>
                        <div className={`${s.Agent_Status}`}>
                          <p>{item?.status}</p>
                        </div>
                      </div>
                    </div>
                    <div className={s.AgentCallIcon}>
                      <AgentCall />
                    </div>
                  </div>
                )
              })}
            </div>
          </div>
        </div>
      </div>

      <div className='mt-5'>
        <Footer />
      </div>
    </>
  )
}

export default EstateAgent
