import style from '../../PropertyDetails.module.css'
import s from './PriceAndTaxHistory.module.css'
import { data } from '../../PropertyDetailsData'

const PriceHistory = () => {
  const tableHeadings = ['Date', 'Event', 'Price', 'Change', 'Sqft Price']

  return (
    <div className={s.PropertyHistorycontainer}>
      <h2 className={style.PropDetails_hedings}>Price History</h2>
     <div className={s.PropertyHistoryTable_container}>
     <table>
        <thead>
          <tr>
            {tableHeadings?.map((item, i) => {
              return <th key={i}>{item}</th>
            })}
          </tr>
        </thead>
        <tbody>
          {data?.PropPriceHistory?.map((item, index) => {
            return (
              <tr key={index}>
                <td>{item?.date === '' ? '---' : `${item?.date}`}</td>
                <td>{item?.event === '' ? '---' : `${item?.event}`}</td>
                <td>
                  {item?.Price === ''
                    ? '---'
                    : `$${item?.Price.toLocaleString('en-Us')}`}
                </td>
                <td>
                  {item?.Change === ''
                    ? '---'
                    : `$${item?.Change.toLocaleString('en-Us')}`}
                </td>
                <td>
                  {item?.SqftPrice === ''
                    ? '---'
                    : `$${item?.SqftPrice.toLocaleString('en-Us')}`}
                </td>
              </tr>
            )
          })}
        </tbody>
      </table>
     </div>
    </div>
  )
}

export default PriceHistory
