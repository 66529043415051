import { useState } from 'react'
import { NavLink, useParams } from 'react-router-dom'
import { data } from './EstateAgentData'
import s from '../Professionals.module.css'
import {
  ProfessionalReviewCard,
  Footer,
  StarRating,
  AgentSoldPropertyCard
} from '../../../common'
import EstateLogo from '../../../../assets/images/RealEstate_logo.png'
import { formatPhoneNumber } from 'react-phone-number-input'
import {
  FacebookIcon,
  AttachementIcon,
  LinkdinIcon,
  GoogleIcon,
} from '../../../svgIcons/Icons'
import WriteReviews from '../Components/WriteReviews'
import RatingReviewsFilter from '../Components/RatingAndReviewsFilter'
import { AgentReviewsFilter } from '../../../../data/constant'
import BreadCrumbs from '../Components/BreadCrumbs'

const EstateAgentDetails = () => {
  const { id } = useParams()
  const detailsData = data.find(card => card.id === parseInt(id))
  const AgentlastName = detailsData.name.split(' ').pop()
  const [ratingFilter, setRatingFilter] = useState('')
  const [reviewsFilter, setReviewsFilter] = useState('All Reviews')
  const [sortByFilter, setSortByFilter] = useState('Newest First')
  const [addReviews, setAddReviews] = useState(false)

  return (
    <div>
      <div className='container mt-5'>
        <BreadCrumbs />
        <div className='row d-flex justify-content-between mt-3'>
          <div className='col-lg-6'>
            <div className={`${s.img_container}`}>
              <img src={detailsData?.imgUrl} alt='EstateAgent_profile' />
            </div>
            <div className={`${s.Agent_profileDes} mt-3`}>
              <h3>{detailsData?.name}</h3>
              <p>Denver, CO</p>
              <p>Keller Williams Integrity Real Estate LLC</p>
              <p className='fW-4'>
                Agent License <span className='fW-6'> {`#432334423`}</span>
              </p>
              <div className={s.AgentD_rating}>
                <div style={{ marginRight: '0.7rem' }}>
                  <GoogleIcon size={'20'} />
                </div>
                <StarRating
                  rating={detailsData?.rating}
                  color={'#B0D95F'}
                  size={'20'}
                />
                <p>
                  {detailsData?.rating}
                  <span className='fW-5'>{`(${detailsData?.reviews} reviews)`}</span>
                </p>
              </div>
              <div>
                <p className='fW-6'>
                  17 <span className='fW-4'>Years of experience</span>
                </p>
              </div>

              <div className={`${s.EsAgent_sale} mt-1`}>
                <div>
                  <p className='fW-6'>
                    321 <span className='fW-4'>Closed Sales</span>
                  </p>
                </div>
                <div>
                  <p className='fW-6'>
                    321 <span className='fW-4'>Total Value</span>
                  </p>
                </div>
              </div>

              <div>
                <p className='fW-6'>{`${detailsData?.language} Speaking`}</p>
              </div>

              <div className={s.esAgent_links}>
                <NavLink>
                  <div className={s.esAgent_linkIcon}>
                    <AttachementIcon size={'30'} color={'black'} />
                  </div>
                </NavLink>
                <NavLink>
                  <div className={s.esAgent_linkIcon}>
                    <LinkdinIcon size={'17'} color={'black'} />
                  </div>
                </NavLink>
                <NavLink>
                  <div className={s.esAgent_linkIcon}>
                    <FacebookIcon size={'30'} color={'black'} />
                  </div>
                </NavLink>
              </div>

              <div className={`${s.EsAgent_Price} mt-3`}>
                <div>
                  <p className='fW-6'>
                    {`$365.4K-$4M`} <span className='fW-4'>Price Range</span>
                  </p>
                </div>
                <div>
                  <p className='fW-6'>
                    {`$932.8`} <span className='fW-4'>Average Price</span>
                  </p>
                </div>
              </div>

              <div className='mt-1'>
                <p className='fW-6'>
                  Home Types:{' '}
                  <span className='fW-4'>{`Other, Condo, House, TownHouse`}</span>
                </p>
              </div>
            </div>
          </div>

          <div className='col-lg-6 d-flex flex-column justify-content-end align-items-end'>
            <AgentSoldPropertyCard data={detailsData} />
            <div className={s.SeeAllBtn}>
              <NavLink>
                <button className='button'>See All</button>
              </NavLink>
            </div>
          </div>
        </div>

        <hr className='mt-5 mb-5' />

        <div className='row d-flex justify-content-center'>
          <div className={s.EsAgent_desMain}>
            <div className={s.EsAgent_des}>
              <h2>{`About ${AgentlastName}`}</h2>
              <div>
                <p>
                  Kevin attended the University of Colorado as an engineering
                  student, has owned several successful entrepreneurial
                  businesses, enjoys the outdoors, international travel, and
                  currently resides in the Belcaro neighborhood. Seventeen years
                  ago Kevin found his passion for Real Estate. He discovered a
                  phenomenon in which he was able to combine his enthusiasm for
                  design, outstanding attention to detail, drive and dedication,
                  with his knowledge and love of Denver.
                </p>
                <p>
                  Kevin offers these talents to the benefit of all his clients-
                  whether they home-buyers, investors or developers. He has
                  implemented the success of several well known projects
                  throughout Denver, including: Lodo, Cherry Creek, Golden
                  Triangle. Kevin has proven to be of invaluable service from
                  inception to completion- specializing in pricing and creative
                  marketing and, of course, sales.
                </p>
                <p>
                  Kevin has been a member of the Denver Board of Realtors since
                  1990 and has served on the Ethics Committee since 1994, and
                  Chairman since 1997 and is well known throughout this industry
                  for his incredible talent of bringing people and properties
                  together.
                </p>
              </div>
            </div>

            <div className={s.EsAgentListing}>
              <h5 className='mb-4 mt-5'>{`${detailsData?.name}'s Listing`}</h5>
              <div className='w-100' style={{ height: '390px' }}>
                <iframe
                  title='Google Map'
                  width='100%'
                  height='100%'
                  style={{ border: '1px solid #676767', borderRadius: '20px' }}
                  src='https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3243.595248679713!2d-122.08398878461643!3d37.4224243396231!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x808f80e66614f07d%3A0xd8f1e4b24d887f87!2sGolden%20Gate%20Bridge!5e0!3m2!1sen!2sus!4v1632809337498!5m2!1sen!2sus'
                  allowFullScreen
                ></iframe>
              </div>
            </div>
          </div>

          {/* -------Agent Profile Card Section-----*/}

          <div className={s.EsAgentcont_cardMain}>
            <div className={s.EsAgentcont_card}>
              <div className='d-flex w-100 justify-content-between'>
                <div className={s.EsAgentcont_profile}>
                  <img src={detailsData?.imgUrl} alt='EstateAgent_profile' />
                  <div className='flex-column ms-3'>
                    <h6>{detailsData.name}</h6>
                    <p>Real Estate Agent</p>
                  </div>
                </div>
                <div className={s.realEstate_logo}>
                  <img src={EstateLogo} alt='RealEstate-logo' />
                </div>
              </div>
              <div>
                <p
                  className=' mb-0 mt-4 fW-4'
                  style={{ fontSize: '14px', color: '#7e7e7e' }}
                >
                  Contact
                </p>
                <p
                  className='mb-0 mt-1 fW-5'
                  style={{ fontSize: '16px', color: '#7e7e7e' }}
                >
                  {formatPhoneNumber('+12134567568')}
                </p>
              </div>
              <div className={`mt-4 ${s.EsSend_message}`}>
                <h6>{`Message ${AgentlastName}`}</h6>
                <textarea rows={5} placeholder='Type Your message.'></textarea>
                <button className={`button mt-3`}>Send Message</button>
                <button className={`button mt-3 ${s.esAgent_conBtn}`}>
                  Connect with Agent
                </button>
              </div>
            </div>
          </div>
        </div>

        {/* -------Rating Section-----*/}

        <RatingReviewsFilter
          options={AgentReviewsFilter}
          reviewsFilter={reviewsFilter}
          sortByFilter={sortByFilter}
          ratingFilter={ratingFilter}
          setRatingFilter={setRatingFilter}
          setReviewsFilter={setReviewsFilter}
          setSortByFilter={setSortByFilter}
          setAddReviews={setAddReviews}
        />

        {addReviews && (
          <div className={s.IntFormopen}>
            <WriteReviews data={detailsData} selectedForm={'WriteAgentRev'} />
          </div>
        )}

        {/* -------Reviews Card Section-----*/}

        <div className={`row mt-5 ${s.reviewsAll_cards}`}>
          <ProfessionalReviewCard />
          <ProfessionalReviewCard />
          <ProfessionalReviewCard />
          <ProfessionalReviewCard />
        </div>

        {/* -------Servises Area Section-----*/}

        <div className={`mt-5 ${s.Rating_heading}`}>
          <h5>Services Areas {`(9)`}</h5>

          <div className='row mt-4'>
            <div className='col-lg-8 col-12 row'>
              {detailsData?.ServicesAreas.map((city, index) => (
                <div key={index} className='col-md-3'>
                  <p className={s.services_area}>
                    <NavLink>{city}</NavLink>
                  </p>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>

      <div className='mt-5'>
        <Footer />
      </div>
    </div>
  )
}

export default EstateAgentDetails
