import s from '../../Professionals.module.css'
import { useState } from 'react'

const AgentReviewsForm = ({ detailsData }) => {
  const [interactionOpt, setInteractionOpt] = useState()
  const [day, setDay] = useState('')
  const [month, setMonth] = useState('')
  const [year, setYear] = useState('')

  const handleDayChange = e => {
    const value = e.target.value
    if (/^\d*$/.test(value) && value <= 31) {
      setDay(value)
    }
  }

  const handleMonthChange = e => {
    const value = e.target.value
    if (/^\d*$/.test(value) && value <= 12) {
      setMonth(value)
    }
  }

  const handleYearChange = e => {
    const currentYear = new Date().getFullYear()
    const value = e.target.value
    if (/^\d*$/.test(value) && value <= currentYear) {
      setYear(value)
    }
  }

  return (
    <>
      <div className={s.ShareMore_details}>
        <h5>
          Share more details about your interactions with {detailsData.name}
        </h5>
      </div>

      <div className={s.ReviewsRadio_inputs}>
        <label>
          <input
            type='radio'
            name='AgentInteractions'
            onClick={() => setInteractionOpt('HelpMeBuySell')}
          />
          <span>Help me Buy and Sell</span>
        </label>
        {interactionOpt === 'HelpMeBuySell' && (
          <div
            className={`row ${
              interactionOpt === 'HelpMeBuySell' ? `${s.IntFormopen}` : ''
            }`}
          >
            <div className={`col-md-8 col-12 ps-5 ${s.experience_form}`}>
              <div className='w-100 mt-3'>
                <label>Which property did they assist you with?</label>
                <input placeholder='Search for an address' type='text' />
              </div>

              <label className='mt-3'>When was the closing date of your home purchase?</label>
              <div className={`row`}>
                <div className='col-md-4 col-12'>
                  <input
                    type='text'
                    value={month}
                    onChange={handleMonthChange}
                    maxLength='2'
                    placeholder='MM*'
                  />
                </div>

                <div className='col-md-4 col-12'>
                  <input
                    type='text'
                    value={day}
                    onChange={handleDayChange}
                    maxLength='2'
                    placeholder='DD'
                  />
                </div>

                <div className='col-md-4 col-12'>
                  <input
                    type='text'
                    value={year}
                    onChange={handleYearChange}
                    placeholder='YYYY*'
                  />
                </div>
              </div>
            </div>
          </div>
        )}

        <label>
          <input
            type='radio'
            name='AgentInteractions'
            onClick={() => setInteractionOpt('HelpMeBuy')}
          />
          <span>Help me Buy</span>
        </label>
        {interactionOpt === 'HelpMeBuy' && (
          <div
            className={`row ${
              interactionOpt === 'HelpMeBuy' ? `${s.IntFormopen}` : ''
            }`}
          >
            <div className={`col-md-8 mt-3 col-12 ps-5 ${s.experience_form}`}>
              <div className='w-100'>
                <label>Which property did they assist you with?</label>
                <input placeholder='Search for an address' type='text' />
              </div>

              <label className='mt-3'>When was the closing date of your home purchase?</label>
              <div className={`row`}>
                <div className='col-md-4 col-12'>
                  <input
                    type='text'
                    value={month}
                    onChange={handleMonthChange}
                    maxLength='2'
                    placeholder='MM*'
                  />
                </div>

                <div className='col-md-4 col-12'>
                  <input
                    type='text'
                    value={day}
                    onChange={handleDayChange}
                    maxLength='2'
                    placeholder='DD'
                  />
                </div>

                <div className='col-md-4 col-12'>
                  <input
                    type='text'
                    value={year}
                    onChange={handleYearChange}
                    placeholder='YYYY*'
                  />
                </div>
              </div>
            </div>
          </div>
        )}

        <label>
          <input
            type='radio'
            name='AgentInteractions'
            onClick={() => setInteractionOpt('HelpMeSell')}
          />
          <span>Helped me buy and sell</span>
        </label>
        {interactionOpt === 'HelpMeSell' && (
          <div
            className={`row ${
              interactionOpt === 'HelpMeSell' ? `${s.IntFormopen}` : ''
            }`}
          >
            <div className={`col-md-8 col-12 ps-5 ${s.experience_form}`}>
              <div className='w-100 mt-3'>
                <label>Which property did they assist you with?</label>
                <input placeholder='Search for an address' type='text' />
              </div>

              <label className='mt-3'>When was the closing date of your home purchase?</label>
              <div className={`row`}>
                <div className='col-md-4 col-12'>
                  <input
                    type='text'
                    value={month}
                    onChange={handleMonthChange}
                    maxLength='2'
                    placeholder='MM*'
                  />
                </div>

                <div className='col-md-4 col-12'>
                  <input
                    type='text'
                    value={day}
                    onChange={handleDayChange}
                    maxLength='2'
                    placeholder='DD'
                  />
                </div>

                <div className='col-md-4 col-12'>
                  <input
                    type='text'
                    value={year}
                    onChange={handleYearChange}
                    placeholder='YYYY*'
                  />
                </div>
              </div>
            </div>
          </div>
        )}

        <label>
          <input
            type='radio'
            name='AgentInteractions'
            onClick={() => setInteractionOpt('NotConnected')}
          />
          <span>We connected, but it did not work out </span>
        </label>
        {interactionOpt === 'NotConnected' && (
          <div
            className={`ps-5 ${
              interactionOpt === 'NotConnected' ? `${s.IntFormopen}` : ''
            }`}
          >
            <label>
              Which services did they offer you? (Please select all relevant
              options)
            </label>
            <label>
              <input type='checkbox' name='ServicesOfferCheck' />
              <span>Showed me homes or lots</span>
            </label>
            <label>
              <input type='checkbox' name='ServicesOfferCheck' />
              <span>Consulted me on buying or selling a home</span>
            </label>
            <label>
              <input type='checkbox' name='ServicesOfferCheck' />
              <span>Listed home, but didn’t sell</span>
            </label>
            <label>
              <input type='checkbox' name='ServicesOfferCheck' />
              <span>Made offer that weren’t accepted</span>
            </label>
            <label>
              <input type='checkbox' name='ServicesOfferCheck' />
              <span>Something else</span>
            </label>

            <div className='row'>
              <div className={`col-md-6 col-12 ${s.experience_form}`}>
                <div className='w-100 mt-3'>
                  <label>What city or ZIP code were you searching in?</label>
                  <input placeholder='Search for city or ZIP' type='text' />
                </div>

                <label className='mt-3'>What year did you work together??</label>
                <div className={`row`}>
                  <div className='col-md-6 col-12'>
                    <input
                      type='text'
                      value={year}
                      onChange={handleYearChange}
                      placeholder='YYYY'
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}

        <label>
          <input
            type='radio'
            name='AgentInteractions'
            onClick={() => setInteractionOpt('PropertyManagement')}
          />
          <span>Renting or property managment</span>
        </label>
        {interactionOpt === 'PropertyManagement' && (
          <div
            className={`ps-5 ${
              interactionOpt === 'PropertyManagement' ? `${s.IntFormopen}` : ''
            }`}
          >
            <label>Which services did they offer you?</label>
            <label>
              <input type='radio' name='PropertyServicesOffer' />
              <span>Helped me find a tenant for rental</span>
            </label>
            <label>
              <input type='radio' name='PropertyServicesOffer' />
              <span>Helped me find a home to rent</span>
            </label>
            <label>
              <input type='radio' name='PropertyServicesOffer' />
              <span>Property manage a home I own</span>
            </label>
            <label>
              <input type='radio' name='PropertyServicesOffer' />
              <span>Property manage/Landlord for a home I rented</span>
            </label>
            <div className='row'>
              <div className={`col-md-8 col-12 ${s.experience_form}`}>
                <div className='w-100 mt-3'>
                  <label>What city or ZIP code were you searching in?</label>
                  <input placeholder='Search for city or ZIP' type='text' />
                </div>

                <label className='mt-3'>When did your lease start?</label>
                <div className={`row`}>
                  <div className='col-md-4 col-12'>
                    <input
                      type='text'
                      value={month}
                      onChange={handleMonthChange}
                      maxLength='2'
                      placeholder='MM'
                    />
                  </div>
                  <div className='col-md-4 col-12'>
                    <input
                      type='text'
                      value={day}
                      onChange={handleDayChange}
                      maxLength='2'
                      placeholder='DD'
                    />
                  </div>
                  <div className='col-md-4 col-12'>
                    <input
                      type='text'
                      value={year}
                      onChange={handleYearChange}
                      placeholder='YYYY'
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}

        <label>
          <input
            type='radio'
            name='AgentInteractions'
            onClick={() => setInteractionOpt('NoneOfAbove')}
          />
          <span>None of the above</span>
        </label>
        {interactionOpt === 'NoneOfAbove' && (
          <div
            className={`ps-5 ${
              interactionOpt === 'NoneOfAbove' ? `${s.IntFormopen}` : ''
            }`}
          >
            <label>Which services did they offer you?</label>
            <label>
              <input type='radio' name='NoneServicesOffer' />
              <span>They represented the other party's buyer agent</span>
            </label>
            <label>
              <input type='radio' name='NoneServicesOffer' />
              <span>They represented the other party's seller agent</span>
            </label>
            <label>
              <input type='radio' name='NoneServicesOffer' />
              <span>They never responded to my inquiry</span>
            </label>

            <div className='row'>
              <div className={`col-md-6 col-12 ${s.experience_form}`}>
                <div className='w-100 mt-3'>
                  <label>What city or ZIP code were you searching in?</label>
                  <input placeholder='Search for city or ZIP' type='text' />
                </div>

                <label className='mt-3'>When did You interact with {detailsData.name}?</label>

                <div className={`row`}>
                  <div className='col-md-6 col-12'>
                    <input
                      type='text'
                      value={year}
                      onChange={handleYearChange}
                      placeholder='YYYY'
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  )
}

export default AgentReviewsForm
