import { NavLink } from "react-router-dom";

const Home = () => {
  return (
    <div>
      <h1 style={{ color: "var(--primary-color)" }}>
        Welcome To NeedHome Home
      </h1>
      <NavLink to="/logIn">
        <p>Login</p>
      </NavLink>
      <NavLink to="/SignUp">
        <p>Register</p>
      </NavLink>
      <NavLink to="/reset-password">
        <p>Reset Password</p>
      </NavLink>
      <NavLink to="/account-created">
        <p>Account Successfully Created</p>
      </NavLink>
      <NavLink to="/schedule-consultation">
        <p>Schedule Consultation</p>
      </NavLink>
      <NavLink to="/property-details/1">
        <p>Property Details</p>
      </NavLink>
      <NavLink to="/about">
        <p>About</p>
      </NavLink>
      <NavLink to="/contact-us">
        <p>Contact US</p>
      </NavLink>
      <NavLink to="/co-shopping">
        <p>Co-Shopping</p>
      </NavLink>
      <NavLink to="/notes">
        <p>NoteStream</p>
      </NavLink>
      <NavLink to="/advisors">
        <p>Mortgage Advisor</p>
      </NavLink>
      <NavLink to="/advisor-details/:id">
        <p>Mortgage Advisor Details</p>
      </NavLink>
      <NavLink to="/estate-agent">
        <p>Real Estate</p>
      </NavLink>
      <NavLink to="/estate-agent-details/:id">
        <p>Real Estate Details</p>
      </NavLink>
      <NavLink to="/property-managers">
        <p>Property Mangers</p>
      </NavLink>
      <NavLink to="property-manager-details/1">
        <p>Property Manger Details</p>
      </NavLink>
      <NavLink to="/Builders">
        <p>Builders</p>
      </NavLink>
      <NavLink to="/home-improvement">
        <p>Home Improvement</p>
      </NavLink>
      <NavLink to="/home-improvement-details/1">
        <p>Home Improvement details</p>
      </NavLink>
      <NavLink to="/inspectors">
        <p>Inspectors</p>
      </NavLink>
      <NavLink to="/inspector-details/1">
        <p>Inspector Details</p>
      </NavLink>
      <NavLink to="/photographers">
        <p>Photographers</p>
      </NavLink>
      <NavLink to="/photographer-details/1">
        <p>Photographer details</p>
      </NavLink>
      <NavLink to="/other-agents">
        <p>Other Agents</p>
      </NavLink>
      <NavLink to="/blogs/buying">
        <p>All About buying Blogs</p>
      </NavLink>
      <NavLink to="/blogs/selling">
        <p>All About buying Blogs</p>
      </NavLink>
      <NavLink to="/blogs/real-estate-news">
        <p>Real Estate News Blogs</p>
      </NavLink>
      <NavLink to="/blogs/heart-find-homes">
        <p>Heart Find Home Blogs</p>
      </NavLink>
    </div>
  );
};

export default Home;
