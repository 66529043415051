import AuthLayout from "../../../layout/AuthSection";
import { Form, Formik } from "formik";
import s from "../../../layout/AuthSection/Auth.module.css";
import {
  AuthFooter,
  CustomInput,
  InputCheckBoxComponent,
  InputPassComponent,
} from "../../../common";
import { useState } from "react";
import MenuItem from "@mui/material/MenuItem";
import { Box, FormControl } from "@mui/material";
import { SignUpInfo } from "../../../../actions/validation";
import Select from "@mui/material/Select";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

const SignUpComponent = () => {
  const [propertySell, setPropertySell] = useState("Yes");
  const [isSubmited, setIsSubmited] = useState(false);

  const handlePropertySell = (event) => {
    setPropertySell(event.target.value);
    setData((prevData) => ({
      ...prevData,
      propertySell: event.target.value,
    }));
  };

  const handleSubmit = () => {
    console.log(data);
  };

  const [data, setData] = useState({
    email: "",
    password: "",
    propertySell: propertySell,
    newsTerms: false,
    dataTerms: false,
  });

  return (
    <>
      <Formik
        initialValues={{
          data,
        }}
        validate={(values) => SignUpInfo(values)}
        onSubmit={handleSubmit}
      >
        {({ errors, touched }) => (
          <Form>
            <div className={s.AuthAll_main}>
              <AuthLayout />
              <div className={s.AuthField_mainContainer}>
                <div className={s.AuthField_container}>
                  <div className={s.Authmain_heading}>
                    <h1>Register</h1>
                  </div>

                  <div className="w-100">
                    <CustomInput
                      placeholder="Enter Email"
                      name="email"
                      type="text"
                      data={data}
                      setData={setData}
                    />
                  </div>
                  {isSubmited && (
                    <div className="w-100 text-start">
                      <p className="Invaild_errors m-0">{errors.email}</p>
                    </div>
                  )}

                  <div className="w-100 mt-3">
                    <InputPassComponent
                      setData={setData}
                      placeholder="Password"
                      name="password"
                      type="text"
                      data={data}
                    />
                  </div>
                  {isSubmited && (
                    <div className="w-100 text-start">
                      <p className="Invaild_errors m-0">{errors.password}</p>
                    </div>
                  )}

                  <button
                    type="submit"
                    onClick={() => setIsSubmited(true)}
                    className={`${s.AuthButton} button`}
                  >
                    Register
                  </button>

                  <div className={s.planing_sell}>
                    <p>Are you planning to sell a property?</p>
                  </div>

                  <div style={{ width: "100%" }} className="mt-3 mb-3">
                    <Box>
                      <FormControl fullWidth>
                        <Select
                          labelId="Select"
                          id="sellingProperty"
                          value={propertySell}
                          onChange={handlePropertySell}
                          sx={{
                            color: "#76787A",
                            fontWeight: "500",
                          }}
                          IconComponent={KeyboardArrowDownIcon}
                        >
                          <MenuItem value={"Yes"}>Yes</MenuItem>
                          <MenuItem value={"No"}>No</MenuItem>
                        </Select>
                      </FormControl>
                    </Box>
                  </div>

                  <div>
                    <InputCheckBoxComponent
                      data={data}
                      setData={setData}
                      type="checkbox"
                      name="newsTerms"
                      text={`Yes! I agree to receive commercial electronic messages,
                    such as newsletters, promotions, invitations, important
                    updates and recl estate content, from NeedHome and
                    entities belonging to the sorne Of companies. I
                    understand that I can withdraw my consent at any time.`}
                    />
                    {isSubmited && (
                      <div className="w-100 text-start">
                        <p className="Invaild_errors m-0">{errors.newsTerms}</p>
                      </div>
                    )}

                    <InputCheckBoxComponent
                      data={data}
                      setData={setData}
                      type="checkbox"
                      name="dataTerms"
                      text={`Yes! I agree that NeedHome uses my informer-ion to
                      better understand my preferences and areas of interest,
                      Io iurther personalize the content it sends to me by
                      email and SMS, end to deliver advertisements that are
                      more likely Io interest me, including on sociol media
                      plaliorms. I also accept that NeedHome uses date
                      collection technologies in the emcils it sends me to
                      belt-er understand how I interact wilh its
                      communicctions end better personalize them. I understand
                      that I can withdraw my consent for these purposes at any
                      time.`}
                    />
                    {isSubmited && (
                      <div className="w-100 text-start">
                        <p className="Invaild_errors m-0">{errors.dataTerms}</p>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div style={{ marginTop: "70px" }}>
              <AuthFooter />
            </div>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default SignUpComponent;
