import style from '../../PropertyDetails.module.css'
import s from './MLsData.module.css'
import { data } from '../../PropertyDetailsData'

const MLSData = () => {
  return (
    <div>
      <h2 className={style.PropDetails_hedings}>MLS Data</h2>
      {data?.MLSData?.map((listing, index) => (
        <div key={index} className={s.mlsData_des}>
          <div className={s.mlsData_category}>
            <h6 className={style.sub_headings}>Listing Data</h6>
            <ul className={style.ListingDetails_listMain}>
              {Object.entries(listing.ListingData).map(([key, value]) => (
                <li key={key}>
                  <span>{key}:</span>{' '}
                  {Array.isArray(value) ? value.join(', ') : value}
                </li>
              ))}
            </ul>
          </div>

          <div className={s.mlsData_category}>
            <h6 className={style.sub_headings}>Interior Details</h6>
            <ul className={style.ListingDetails_listMain}>
              {Object.entries(listing.interiorDetails).map(([key, value]) => (
                <li key={key}>
                  <span>{key}:</span>{' '}
                  {Array.isArray(value) ? value.join(', ') : value}
                </li>
              ))}
            </ul>
          </div>

          <div className={s.mlsData_category}>
            <h6 className={style.sub_headings}>Beds/Baths</h6>
            <ul className={style.ListingDetails_listMain}>
              {Object.entries(listing.bedBathDetails).map(([key, value]) => (
                <li key={key}>
                  <span>{key}:</span>{' '}
                  {Array.isArray(value) ? value.join(', ') : value}
                </li>
              ))}
            </ul>
          </div>

          <div className={s.mlsData_category}>
            <h6 className={style.sub_headings}>Building Info</h6>
            <ul className={style.ListingDetails_listMain}>
              {Object.entries(listing.buildingInfo).map(([key, value]) => (
                <li key={key}>
                  <span>{key}:</span>{' '}
                  {Array.isArray(value) ? value.join(', ') : value}
                </li>
              ))}
            </ul>
          </div>

          <div className={s.mlsData_category}>
            <h6 className={style.sub_headings}>Exterior Features</h6>
            <ul className={style.ListingDetails_listMain}>
              {Object.entries(listing.exteriorfeatures).map(([key, value]) => (
                <li key={key}>
                  <span>{key}:</span>{' '}
                  {Array.isArray(value) ? value.join(', ') : value}
                </li>
              ))}
            </ul>
          </div>

          <div className={s.mlsData_category}>
            <h6 className={style.sub_headings}>Garage/Parking</h6>
            <ul className={style.ListingDetails_listMain}>
              {Object.entries(listing.garageInfo).map(([key, value]) => (
                <li key={key}>
                  <span>{key}:</span>{' '}
                  {Array.isArray(value) ? value.join(', ') : value}
                </li>
              ))}
            </ul>
          </div>

          <div className={s.mlsData_category}>
            <h6 className={style.sub_headings}>Utilities</h6>
            <ul className={style.ListingDetails_listMain}>
              {Object.entries(listing.utilitiesDetails).map(([key, value]) => (
                <li key={key}>
                  <span>{key}:</span>{' '}
                  {Array.isArray(value) ? value.join(', ') : value}
                </li>
              ))}
            </ul>
          </div>

          <div className={s.mlsData_category}>
            <h6 className={style.sub_headings}>Association/Amenities</h6>
            <ul className={style.ListingDetails_listMain}>
              {Object.entries(listing.garageInfo).map(([key, value]) => (
                <li key={key}>
                  <span>{key}:</span>{' '}
                  {Array.isArray(value) ? value.join(', ') : value}
                </li>
              ))}
            </ul>
          </div>

          <div className={s.mlsData_category}>
            <h6 className={style.sub_headings}>Lot Info</h6>
            <ul className={style.ListingDetails_listMain}>
              {Object.entries(listing.lotDetails).map(([key, value]) => (
                <li key={key}>
                  <span>{key}:</span>{' '}
                  {Array.isArray(value) ? value.join(', ') : value}
                </li>
              ))}
            </ul>
          </div>
          
          <div className={s.mlsData_category}>
            <h6 className={style.sub_headings}>Tax Info</h6>
            <ul className={style.ListingDetails_listMain}>
              {Object.entries(listing.TaxInfo).map(([key, value]) => (
                <li key={key}>
                  <span>{key}:</span>{' '}
                  {Array.isArray(value) ? value.join(', ') : value}
                </li>
              ))}
            </ul>
          </div>

          <div className={s.mlsData_category}>
            <h6 className={style.sub_headings}>Buyer Broker Compensation</h6>
            <ul className={style.ListingDetails_listMain}>
              {Object.entries(listing.compensationDetails).map(([key, value]) => (
                <li key={key}>
                  <span>{key}:</span>{' '}
                  {Array.isArray(value) ? value.join(', ') : value}
                </li>
              ))}
            </ul>
          </div>
        </div>
      ))}
    </div>
  )
}

export default MLSData
