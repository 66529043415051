import { ShareIcon } from '../../../svgIcons/Icons'

const ShareBtn = ({ mainClassName }) => {
  return (
    <div>
      <button className={mainClassName}>
        <ShareIcon />
      </button>
    </div>
  )
}

export default ShareBtn
