import s from '../Professionals.module.css'
import { useParams, NavLink } from 'react-router-dom'
import { data } from './AdvisorData'
import { StarRating, Footer } from '../../../common'
import {
  CallIcon,
  MessageIcon,
  GoogleIcon,
  LocationIcon,
  CalendarIcon
} from '../../../svgIcons/Icons'
import BreadCrumbs from '../Components/BreadCrumbs'

const AdvisorDetails = () => {
  const { id } = useParams()
  const detailsData = data.find(card => card.id === parseInt(id))

  return (
    <div>
      <div className='container mt-5'>
        <BreadCrumbs />

        <div className='d-flex mt-3'>
          <div className={`${s.img_container}`}>
            <img src={detailsData?.imgUrl} alt='EstateAgent_profile' />
          </div>
          <div className={`${s.Agent_profileDes} ms-4`}>
            <h3>{detailsData?.name}</h3>
            <p className='fW-6'>{`Agent Level 2`}</p>
            <p className='fW-4 mt-1'>
              Agent License <span className='fW-6'> {`#432334423`}</span>
            </p>

            <p className='fW-6 mt-1'>{`${detailsData?.language} Speaking`}</p>

            <div className={s.AgentD_rating}>
              <div style={{ marginRight: '0.7rem' }}>
                <GoogleIcon size={'20'} />
              </div>
              <StarRating
                rating={detailsData?.rating}
                color={'#B0D95F'}
                size={'20'}
              />
              <p className=''>
                {detailsData?.rating}
                <span className='fW-5'>{`(${detailsData?.reviews} reviews)`}</span>
              </p>
            </div>
            <NavLink>
              <p style={{ color: '#7E7E7E' }}>Read Reviews</p>
            </NavLink>
            <div className={`mt-3 ${s.AdvisorDbtn}`}>
              <button className={`button ${s.AdvisorDMsg_btn}`}>
                <MessageIcon /> <span>Message</span>
              </button>
              <button className={`button ${s.AdvisorDCall_btn}`}>
                <CallIcon /> <span>Call</span>
              </button>
            </div>
          </div>
        </div>

        <hr className='mt-5 mb-5' />

        <div className='row mt-5'>
          <div className={`col-md-8 pe-4`}>
            <div className={s.Advisor_des}>
              <h3>Get your best rate and (mortgage) peace of mind.</h3>
              <p>
                In today's lightning-fast housing market, securing a smooth,
                stress-free mortgage experience is paramount.
              </p>
              <p>
                When it comes to this significant financial decision, peace of
                mind is priceless. That's where I come in!
              </p>
              <p>
                In today's lightning-fast housing market, securing a smooth,
                stress-free mortgage experience is paramount.
              </p>
              <p>
                With over two decades of experience in retail banking, I offer
                an exceptional mortgage journey tailored to your unique needs,
                ensuring you get the best rate and mortgage product.
              </p>
              <p>
                I handle all the nitty-gritty details, liaising with lenders on
                your behalf, so you can focus on your busy life! Whether you're
                buying, renewing, or refinancing your home, or eyeing a vacation
                property — I'm here to guide you every step of the way.
              </p>
              <p>
                I'm fluent in both English and Punjabi to address your mortgage
                needs. Contact me today!
              </p>
            </div>
            <div className={s.Advisor_map}>
              <iframe
                title='Google Map'
                width='100%'
                height='100%'
                style={{ border: '1px solid #676767', borderRadius: '20px' }}
                src='https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3243.595248679713!2d-122.08398878461643!3d37.4224243396231!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x808f80e66614f07d%3A0xd8f1e4b24d887f87!2sGolden%20Gate%20Bridge!5e0!3m2!1sen!2sus!4v1632809337498!5m2!1sen!2sus'
                allowFullScreen
              ></iframe>
            </div>
          </div>

          <div className={`col-md-4 ${s.AdvisorD_timingD}`}>
            <div>
              <h5>Metropolis at Metrotown</h5>
              <div className='d-flex mt-3'>
                <LocationIcon size={'25'} />
                <p className={s.AdvisorD_loc}>
                  Unit 1143 - 4700 KingswayBurnaby, BC, V5H 4M1
                </p>
              </div>
            </div>

            <hr className={s.break_line} />

            <h5 className='mt-4'>Call</h5>
            <div className='d-flex mt-3'>
              <div>
                <CallIcon size={'20'} color={'#222'} />
              </div>
              <div className='d-flex w-100 ms-3'>
                <div className={s.AdvisorDay_call}>
                  <p>Local:</p>
                  <p className='mt-2'>Toll Free:</p>
                  <p className='mt-2'>Fax:</p>
                </div>
                <div>
                  <p>1-778-654-7931</p>
                  <p className='mt-2'>1-778-654-7931</p>
                  <p className='mt-2'>1-778-654-7931</p>
                </div>
              </div>
            </div>

            <hr className={s.break_line} />

            <h5 className='mt-4'>Hours</h5>
            <div className='d-flex mt-3'>
              <div>
                <CalendarIcon size={'20'} color={'#222'} />
              </div>
              <div className='d-flex w-100 ms-3'>
                <div className={s.AdvisorDay_call}>
                  <p>Mon-Fri:</p>
                  <p className='mt-2'>Saturday:</p>
                  <p className='mt-2'>Sunday:</p>
                </div>
                <div>
                  <p>9:00 am-6:00 pm</p>
                  <p className='mt-2'>9:00 am-6:00 pm</p>
                  <p className='mt-2'>9:00 am-6:00 pm</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className='mt-5'>
        <Footer />
      </div>
    </div>
  )
}

export default AdvisorDetails
