import "./App.css";
import "swiper/css/bundle";
import "@fontsource/poppins";
import "@fontsource/montserrat";
import "@fontsource/inter";
import Router from "./routes/router";
import { Provider } from "react-redux";
import store from "./redux/configureStore/store";

function App() {
  return (
    <Provider store={store}>
      <Router />
    </Provider>
  );
}

export default App;
