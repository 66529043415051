import s from '../Professionals.module.css'
import { useEffect, useState } from 'react'
import FilterPagesHeader from '../Components/FilterPagesHeader'
import { ProfessionalsCard, Footer } from '../../../common'
import { data } from './AdvisorData'
import {
  AgentEveryone,
  VirtualAgent,
  StoreAgent,
  MobileBrokers,
  AdminAgents
} from '../../../svgIcons/Icons'
import FilterSearchCriteria from '../Components/FilterSearchCriteria'

const Advisor = () => {
  const [filterIndex, setFilterIndex] = useState(0)
  const [filterCriteria, setFilterCriteria] = useState('')
  const [filterData, setFilterData] = useState([])
  const [filteredCardData, setFilteredCardData] = useState([])

  const filterAgents = [
    { name: 'Everyone', icon: AgentEveryone },
    { name: 'Virtual Agents', icon: VirtualAgent },
    { name: 'Store Agents', icon: StoreAgent },
    { name: 'Mobile Brokers', icon: MobileBrokers },
    { name: 'Admin', icon: AdminAgents }
  ]

  const handleFilterAgents = i => {
    setFilterIndex(i)
  }

  useEffect(() => {
    if (filterIndex === 0) {
      setFilterCriteria('Everyone')
    } else if (filterIndex === 1) {
      setFilterCriteria('Virtual Agent')
    } else if (filterIndex === 2) {
      setFilterCriteria('Store Agent')
    } else if (filterIndex === 3) {
      setFilterCriteria('Mobile Broker')
    } else if (filterIndex === 4) {
      setFilterCriteria('Admin')
    }
  }, [filterIndex])

  useEffect(() => {
    setFilterData(data)
  }, [])

  useEffect(() => {
    const filteredData = data?.filter(
      item => filterCriteria === 'Everyone' || item.status === filterCriteria
    )
    setFilterData(filteredData)
  }, [filterCriteria])

  return (
    <>
      <div className={s.AgentMain}>
        <div>
          <FilterPagesHeader page={'Advisors'} />
        </div>

        <div className={`${s.Prof_mainContainer} mt-4`}>
        <FilterSearchCriteria
            data={filterData}
            setFilteredCardData={setFilteredCardData}
            placeholder={'Adviosr Name'}
            isSpecialitiesOption={'No'}
          />

          <div className={`${s.pageMain_heading} mt-3`}>
            <h2>Mortgage Advisors</h2>
            {filteredCardData.length > 0 ? (
              <p>{`${filteredCardData.length.toLocaleString(
                'en-Us'
              )} advisors found`}</p>
            ) : (
              <p>{`No advisors found`}</p>
            )}
          </div>

          <hr
            className='mt-4 mb-4'
            style={{
              color: '#d9d9d9',
              height: '2.5px',
              backgroundColor: '#d9d9d9'
            }}
          />

          <div className={s.agentsCardSec}>
            <div className={s.AllAgent_filter}>
              {filterAgents?.map((item, i) => (
                <div
                  key={i}
                  className={`${s.filterAgent} ${
                    filterIndex === i ? s.ActiveDiv : ''
                  }`}
                  onClick={() => handleFilterAgents(i)}
                >
                  <item.icon color={filterIndex === i ? '#222' : '#7E7E7E'} />
                  <p>{item.name}</p>
                </div>
              ))}
            </div>

            <div className='w-100 mt-4'>
              <ProfessionalsCard
                deatilsPath={'advisor-details'}
                data={filteredCardData}
              />
            </div>
          </div>
        </div>
      </div>

      <div className='mt-5'>
        <Footer />
      </div>
    </>
  )
}

export default Advisor
