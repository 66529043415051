import style from '../../PropertyDetails.module.css'
import s from '../PriceHistory/PriceAndTaxHistory.module.css'
import { data } from '../../PropertyDetailsData'

const TaxHistory = () => {
  const tableHeadings = ['Year', 'Tax Paid', 'Tax Assessment', 'Land', 'Improvement']

  return (
    <div className={s.PropertyHistorycontainer}>
      <h2 className={style.PropDetails_hedings}>Tax History</h2>
     <div className={s.PropertyHistoryTable_container}>
     <table>
        <thead>
          <tr>
            {tableHeadings?.map((item, i) => {
              return <th key={i}>{item}</th>
            })}
          </tr>
        </thead>
        <tbody>
          {data?.TaxPriceHistory?.map((item, index) => {
            return (
              <tr key={index}>
                <td>{item?.Year === '' ? '---' : `${item?.Year}`}</td>
                <td>{item?.taxPaid === '' ? '---' : `$${item?.taxPaid?.toLocaleString('en-Us')}`}</td>
                <td>
                  {item?.taxAssessment === ''
                    ? '---'
                    : `$${item?.taxAssessment?.toLocaleString('en-Us')}`}
                </td>
                <td>
                  {item?.land === ''
                    ? '---'
                    : `$${item?.land?.toLocaleString('en-Us')}`}
                </td>
                <td>
                  {item?.improvement === ''
                    ? '---'
                    : `$${item?.improvement?.toLocaleString('en-Us')}`}
                </td>
              </tr>
            )
          })}
        </tbody>
      </table>
     </div>
    </div>
  )
}

export default TaxHistory
