import s from '../Professionals.module.css'
import { useState } from 'react'
import { Footer, ProfessionalsCard } from '../../../common'
import FilterSearchCriteria from '../Components/FilterSearchCriteria'
import FilterPagesHeader from '../Components/FilterPagesHeader'
import { data } from '../PropertyManagers/PropertyManagerData'
import { OtherAgentSpecialitiesData } from '../../../../data/constant'

const OtherAgents = () => {
  const [filteredCardData, setFilteredCardData] = useState([])

  return (
    <>
      <div className={s.AgentMain}>
        <div>
          <FilterPagesHeader page={'Other'} />
        </div>

        <div className={`${s.Prof_mainContainer} mt-4`}>
        <FilterSearchCriteria
            data={data}
            setFilteredCardData={setFilteredCardData}
            placeholder={'Name'}
            options={OtherAgentSpecialitiesData}
          />

          <div className={`${s.pageMain_heading} mt-3`}>
            <h2>Professionals</h2>
            {filteredCardData?.length > 0 ? (
              <p>{`${filteredCardData?.length.toLocaleString(
                'en-Us'
              )} Professionals found`}</p>
            ) : (
              <p>{`No Professionals found`}</p>
            )}
          </div>

          <hr
            className='mt-4 mb-4'
            style={{
              color: '#d9d9d9',
              height: '2.5px',
              backgroundColor: '#d9d9d9'
            }}
          />

          <div className={s.agentsCardSec}>
            <div className='w-100'>
              <ProfessionalsCard
                deatilsPath={'other-agents-details'}
                data={filteredCardData}
              />
            </div>
          </div>
        </div>
      </div>

      <div className='mt-5'>
        <Footer />
      </div>
    </>
  )
}

export default OtherAgents
