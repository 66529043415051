import * as React from 'react';
import dayjs from 'dayjs';
import { DemoContainer, DemoItem } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';

export default function TimePick({mainclassName,timeChange,setTimeChange}) {
  const currentTime = dayjs();

const handleTimeChange=(selectedTime)=>{
  setTimeChange(selectedTime)
}
  return (
   <div className={mainclassName}>
     <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DemoContainer
        components={[
          'TimePicker',
        ]}
      >
        <DemoItem>
          {/* Set the defaultValue to the current time */}
          <TimePicker defaultValue={currentTime} onChange={(e)=>handleTimeChange(e)}/>
        </DemoItem>
      </DemoContainer>
    </LocalizationProvider>
   </div>
  );
}
