export const data = [
  {
    id: 1,
    name: "Mattamy Homes Canada",
    communities: '17',
    imgUrl: "/assets/images/CoShopping1.jpeg",
    homes: '22',
    reviews: 131,
    rating: "3.0",
    language: "French",
    startingPlan: '0.75M',
    ServicesAreas: [
      "Vineland",
      "Grimsby",
      "Cayuga",
      "Hamilton",
      "Brampton",
      "Mississauga",
      "Toronto",
      "Vancouver",
      "Calgary",
      "Montreal",
    ],
    specialities: [
      'Communities',
      'Condominiums',
      'Eco Friendly',
      'Energy Efficient',
      'First Homes',
      'Luxury Homes',
      'Moveup Homes',
      'Smart Homes',
      'Suburban Living',
      'Townhomes',
      'Urban Living',
    ],
    communityData:[
      {
        name: 'Hawthorne East Village',
        address: 'Toronto, ON',
        price: '$2M',
        imgUrl : "/assets/images/bridge.jpeg"
      },{
        name: 'Hawthorne East Village',
        address: 'Toronto, ON',
        price: '$2M',
        imgUrl : "/assets/images/bridge.jpeg"
      },{
        name: 'Hawthorne East Village',
        address: 'Toronto, ON',
        price: '$2M',
        imgUrl : "/assets/images/bridge.jpeg"
      },{
        name: 'Hawthorne East Village',
        address: 'Toronto, ON',
        price: '$2M',
        imgUrl : "/assets/images/bridge.jpeg"
      },{
        name: 'Hawthorne East Village',
        address: 'Toronto, ON',
        price: '$2M',
        imgUrl : "/assets/images/bridge.jpeg"
      }
    ]
  }, {
    id: 2,
    name: "Mattamy Homes Canada",
    imgUrl: "/assets/images/CoShopping1.jpeg",
    communities: '17',
    homes: '22',
    reviews: 131,
    rating: "3.0",
    language: "French",
    startingPlan: '0.75M',
    ServicesAreas: [
      "Vineland",
      "Grimsby",
      "Cayuga",
      "Hamilton",
      "Brampton",
      "Mississauga",
      "Toronto",
      "Vancouver",
      "Calgary",
      "Montreal",
    ],
    specialities: [
      'Communities',
      'Condominiums',
      'Eco Friendly',
      'Energy Efficient',
      'First Homes',
      'Luxury Homes',
      'Moveup Homes',
      'Smart Homes',
      'Suburban Living',
      'Townhomes',
      'Urban Living',
    ],
  }, {
    id: 3,
    name: "Mattamy Homes Canada",
    imgUrl: "/assets/images/CoShopping1.jpeg",
    communities: '17',
    homes: '22',
    reviews: 131,
    rating: "3.0",
    language: "French",
    startingPlan: '0.75M',
    ServicesAreas: [
      "Vineland",
      "Grimsby",
      "Cayuga",
      "Hamilton",
      "Brampton",
      "Mississauga",
      "Toronto",
      "Vancouver",
      "Calgary",
      "Montreal",
    ],
    specialities: [
      'Communities',
      'Condominiums',
      'Eco Friendly',
      'Energy Efficient',
      'First Homes',
      'Luxury Homes',
      'Moveup Homes',
      'Smart Homes',
      'Suburban Living',
      'Townhomes',
      'Urban Living',
    ],
  }, {
    id: 4,
    name: "Mattamy Homes Canada",
    imgUrl: "/assets/images/CoShopping1.jpeg",
    communities: '17',
    homes: '22',
    reviews: 131,
    rating: "3.0",
    language: "French",
    startingPlan: '0.75M',
    ServicesAreas: [
      "Vineland",
      "Grimsby",
      "Cayuga",
      "Hamilton",
      "Brampton",
      "Mississauga",
      "Toronto",
      "Vancouver",
      "Calgary",
      "Montreal",
    ],
    specialities: [
      'Communities',
      'Condominiums',
      'Eco Friendly',
      'Energy Efficient',
      'First Homes',
      'Luxury Homes',
      'Moveup Homes',
      'Smart Homes',
      'Suburban Living',
      'Townhomes',
      'Urban Living',
    ],
  }, {
    id: 5,
    name: "Mattamy Homes Canada",
    imgUrl: "/assets/images/CoShopping1.jpeg",
    communities: '17',
    homes: '22',
    reviews: 131,
    rating: "3.0",
    language: "French",
    startingPlan: '0.75M',
    ServicesAreas: [
      "Vineland",
      "Grimsby",
      "Cayuga",
      "Hamilton",
      "Brampton",
      "Mississauga",
      "Toronto",
      "Vancouver",
      "Calgary",
      "Montreal",
    ],
    specialities: [
      'Communities',
      'Condominiums',
      'Eco Friendly',
      'Energy Efficient',
      'First Homes',
      'Luxury Homes',
      'Moveup Homes',
      'Smart Homes',
      'Suburban Living',
      'Townhomes',
      'Urban Living',
    ],
  }, {
    id: 6,
    name: "Mattamy Homes Canada",
    imgUrl: "/assets/images/CoShopping1.jpeg",
    communities: '17',
    homes: '22',
    reviews: 131,
    rating: "3.0",
    language: "French",
    startingPlan: '0.75M',
    ServicesAreas: [
      "Vineland",
      "Grimsby",
      "Cayuga",
      "Hamilton",
      "Brampton",
      "Mississauga",
      "Toronto",
      "Vancouver",
      "Calgary",
      "Montreal",
    ],
    specialities: [
      'Communities',
      'Condominiums',
      'Eco Friendly',
      'Energy Efficient',
      'First Homes',
      'Luxury Homes',
      'Moveup Homes',
      'Smart Homes',
      'Suburban Living',
      'Townhomes',
      'Urban Living',
    ],
  }, {
    id: 7,
    name: "Mattamy Homes Canada",
    imgUrl: "/assets/images/CoShopping1.jpeg",
    communities: '17',
    homes: '22',
    reviews: 131,
    rating: "3.0",
    language: "French",
    startingPlan: '0.75M',
    ServicesAreas: [
      "Vineland",
      "Grimsby",
      "Cayuga",
      "Hamilton",
      "Brampton",
      "Mississauga",
      "Toronto",
      "Vancouver",
      "Calgary",
      "Montreal",
    ],
    specialities: [
      'Communities',
      'Condominiums',
      'Eco Friendly',
      'Energy Efficient',
      'First Homes',
      'Luxury Homes',
      'Moveup Homes',
      'Smart Homes',
      'Suburban Living',
      'Townhomes',
      'Urban Living',
    ],
  }, {
    id: 8,
    name: "Mattamy Homes Canada",
    imgUrl: "/assets/images/CoShopping1.jpeg",
    communities: '17',
    homes: '22',
    reviews: 131,
    rating: "3.0",
    language: "French",
    startingPlan: '0.75M',
    ServicesAreas: [
      "Vineland",
      "Grimsby",
      "Cayuga",
      "Hamilton",
      "Brampton",
      "Mississauga",
      "Toronto",
      "Vancouver",
      "Calgary",
      "Montreal",
    ],
  specialities: [
      'Communities',
      'Condominiums',
      'Eco Friendly',
      'Energy Efficient',
      'First Homes',
      'Luxury Homes',
      'Moveup Homes',
      'Smart Homes',
      'Suburban Living',
      'Townhomes',
      'Urban Living',
    ],
    
  }, {
    id: 9,
    name: "Mattamy Homes Canada",
    imgUrl: "/assets/images/CoShopping1.jpeg",
    communities: '17',
    homes: '22',
    reviews: 131,
    rating: "3.0",
    language: "French",
    startingPlan: '0.75M',
    ServicesAreas: [
      "Vineland",
      "Grimsby",
      "Cayuga",
      "Hamilton",
      "Brampton",
      "Mississauga",
      "Toronto",
      "Vancouver",
      "Calgary",
      "Montreal",
    ],
    specialities: [
      'Communities',
      'Condominiums',
      'Eco Friendly',
      'Energy Efficient',
      'First Homes',
      'Luxury Homes',
      'Moveup Homes',
      'Smart Homes',
      'Suburban Living',
      'Townhomes',
      'Urban Living',
    ],
  }, {
    id: 10,
    name: "Mattamy Homes Canada",
    imgUrl: "/assets/images/CoShopping1.jpeg",
    communities: '17',
    homes: '22',
    reviews: 131,
    rating: "3.0",
    language: "French",
    startingPlan: '0.75M',
    ServicesAreas: [
      "Vineland",
      "Grimsby",
      "Cayuga",
      "Hamilton",
      "Brampton",
      "Mississauga",
      "Toronto",
      "Vancouver",
      "Calgary",
      "Montreal",
    ],
    specialities: [
      'Communities',
      'Condominiums',
      'Eco Friendly',
      'Energy Efficient',
      'First Homes',
      'Luxury Homes',
      'Moveup Homes',
      'Smart Homes',
      'Suburban Living',
      'Townhomes',
      'Urban Living',
    ],
  },

];
