import React, { useState } from "react";
import { Field } from "formik";
import { MdOutlineVisibility, MdOutlineVisibilityOff } from "react-icons/md";

//import styles
import s from "./input.module.css";

const InputPassComponent = ({
  label,
  placeholder,
  name,
  mainClassName,
  maxlength,
  data,
  setData,
}) => {
  const [isInputFocus, setIsInputFocus] = useState(false);
  const [showPassword, setshowPassword] = useState(false);
  const handleShowPassword = () => {
    setshowPassword(!showPassword);
  };
  return (
    <div
      className={`${s.Auth_eyeInputs} ${mainClassName} ${
        isInputFocus ? s.AuthPassFocusBorder : s.AuthPassBorder
      }`}
    >
      <label className={`label-primary`}>{label}</label>
      <Field name={name}>
        {({ form, field }) => {
          const { setFieldValue } = form;
          const onChange = (e) => {
            const { value } = e.target;
            if (!maxlength) {
              setFieldValue(name, value);
              setData && setData((data) => ({ ...data, [name]: value }));
            } else {
              value.length <= maxlength && setFieldValue(name, value);
              value.length <= maxlength &&
                setData &&
                setData((data) => ({ ...data, [name]: value }));
            }
          };
          return (
            <>
              <input
                type={showPassword ? "text" : "password"}
                placeholder={placeholder}
                onChange={onChange}
                onFocus={() => setIsInputFocus(true)}
                onBlur={() => setIsInputFocus(false)}
              />
              {showPassword ? (
                <MdOutlineVisibility
                  onClick={handleShowPassword}
                  className={s.PassVisibility}
                />
              ) : (
                <MdOutlineVisibilityOff
                  onClick={handleShowPassword}
                  className={s.PassVisibility}
                />
              )}
            </>
          );
        }}
      </Field>
    </div>
  );
};

export default InputPassComponent;
