import s from "./ContactUs.module.css";
import ContactCover1 from "../../../assets/images/ContactUs-cover1.png";
import ContactCover2 from "../../../assets/images/ContactUs-cover2.png";
import { Faqs } from "../../common";
import Footer from "../../common/FooterMain";
import { RemoveAdIcon } from "../../svgIcons/Icons";
import { BiMessage } from "react-icons/bi";
import { IoCallOutline } from "react-icons/io5";
import { GrLocation } from "react-icons/gr";


const ContactUs = () => {
  return (
    <div>
      <div className={s.Contact_main}>
        <div className={s.ContactUs_cover}>
          <img className={s.cover1} src={ContactCover1} alt="ContactUs-cover" />
          <img className={s.cover2} src={ContactCover2} alt="ContactUs-cover" />
          <div className={s.Conatct_mainHeading}>
            <h1>Contact our friendly team</h1>
            <p className="text-center">Let us know how we can help.</p>
          </div>
        </div>

        <div className={s.contacts_CardsMain}>
          <div className={s.contacts_Cards}>
            <div className={s.contact_card}>
              <div className={s.ContentCard_icon}>
                <BiMessage style={{ fontSize: "25px", color: "#7E7E7E" }} />
              </div>
              <div className={s.contact_cardCotent}>
                <p>Chat to Support</p>
                <p>support@needhome.ca</p>
              </div>
            </div>

            <div className={s.contact_card}>
              <div className={s.ContentCard_icon}>
                <IoCallOutline style={{ fontSize: "25px", color: "#7E7E7E" }} />
              </div>
              <div className={s.contact_cardCotent}>
                <p>Call us</p>
                <p>+92 300 0387 335</p>
              </div>
            </div>

            <div className={s.contact_card}>
              <div className={s.ContentCard_icon}>
                <GrLocation style={{ fontSize: "25px", color: "#7E7E7E" }} />
              </div>
              <div className={s.contact_cardCotent}>
                <p>Visit us</p>
                <p>View on maps</p>
              </div>
            </div>

            <div className={s.contact_card}>
              <div className={s.ContentCard_icon}>
              <RemoveAdIcon />
              </div>
              <div className={s.contact_cardCotent}>
                <p>Cancel Ad</p>
                <p>cancel.ad@needhome.ca</p>
              </div>
            </div>
          </div>
        </div>

        <div className={s.ContactFaq_main}>
          <div className={s.ContactFaq}>
            <h1 className="mb-4">Frequently Asked Questions</h1>
            <Faqs />
          </div>
        </div>

        <div className={`${s.UnlockFeatures_content}`}>
          <p>
            "Unlock the door to your dreams with a graceful farewell, as we
            guide you home with low commissions, and hearts full of care."
          </p>
          <button className="button mt-4">START YOUR FREE TRIAL</button>
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default ContactUs;
