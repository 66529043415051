import { NavLink } from 'react-router-dom'
import s from '../../Professionals.module.css'
import { RightArrowIcon } from '../../../../svgIcons/Icons'

const BreadCrumbs = () => {
  return (
    <div className={s.Bread_Crumbs}>
      <NavLink>
        <p>Toronto-Ontario</p>
      </NavLink>
      <RightArrowIcon />
        <p>Cristiano Ronaldo</p>
    </div>
  )
}

export default BreadCrumbs
