import ArcProgress from 'react-arc-progress'

const ExpertRatingChart = () => {
  const progress = 80 / 100
  const customText3 = [
    { text: 99 ,font:'Inter', size: '24px', color: '#113A00', x: 50, y:65},
    { text: '/100',font:'Inter', size: '14px', color: '#113A00', x: 80, y:67 },
  ];
  return (
    <ArcProgress
      progress={progress}
      customText= {customText3}
      size={130}
      thickness={14}
      fillColor={'#A5D448'}
      lineCap={'round'}
    />
  )
}

export default ExpertRatingChart
