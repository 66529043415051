import style from '../../PropertyDetails.module.css'
import s from './PropertyScores.module.css'
import { data } from '../../PropertyDetailsData'
import {
  AeroplaneIcon,
  BiCycleIcon,
  SoundOffIcon,
  TrainIcon
} from '../../../../svgIcons/Icons'

const PropertyScores = () => {
  return (
    <div>
      <h2 className={style.PropDetails_hedings}>Scores</h2>

      <div className={s.scoreCard_container}>
        <div className={s.score_card}>
          <div className='d-flex align-items-center'>
            <AeroplaneIcon />
            <div className='ms-3'>
              <h3>Car-Dependent</h3>
              <p>Walk Score®</p>
            </div>
          </div>
          <div className={s.total_score_container}>
            <p>
              <span className={s.obtain_score}>
                {data?.PropScores?.walkScore}
              </span>
              <span className={s.total_score}>/100</span>
            </p>
          </div>
        </div>

        <div className={s.score_card}>
          <div className='d-flex align-items-center'>
            <TrainIcon />
            <div className='ms-3'>
              <h3>Minimal Transit</h3>
              <p>Transit Score®</p>
            </div>
          </div>
          <div className={s.total_score_container}>
            <p>
              <span className={s.obtain_score}>
                {data?.PropScores?.transitScore}
              </span>
              <span className={s.total_score}>/100</span>
            </p>
          </div>
        </div>

        <div className={s.score_card}>
          <div className='d-flex align-items-center'>
            <SoundOffIcon />
            <div className='ms-3'>
              <h3>Calm</h3>
              <p>Sound Score®</p>
            </div>
          </div>
          <div className={s.total_score_container}>
            <p>
              <span className={s.obtain_score}>
                {data?.PropScores?.soundScore}
              </span>
              <span className={s.total_score}>/100</span>
            </p>
          </div>
        </div>

        <div className={s.score_card}>
          <div className='d-flex align-items-center'>
            <BiCycleIcon />
            <div className='ms-3'>
              <h3>Somewhat Bikeable</h3>
              <p>Bike Score®</p>
            </div>
          </div>
          <div className={s.total_score_container}>
            <p>
              <span className={s.obtain_score}>
                {data?.PropScores?.bikeScore}
              </span>
              <span className={s.total_score}>/100</span>
            </p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default PropertyScores
