import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import { FormControl, InputLabel } from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

const CustomSelect = ({ name, value, onChange, options, mainClassName, mainDivClassName }) => {
  return (
    <div className={`w-100`}>
      <FormControl fullWidth className={mainDivClassName}>
        <InputLabel id={name}>{name}</InputLabel>
        <Select
          labelId={name}
          id={name}
          label={name}
          value={value}
          onChange={onChange}
          className={mainClassName}
          IconComponent={KeyboardArrowDownIcon}
          sx={{
            '& .MuiSelect-select': {
              padding: '0px'
            },
          }}
        >
          {options?.map((v, i) => (
            <MenuItem key={i} value={v}>
              {v}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  )
}

export default CustomSelect
