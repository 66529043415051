import s from '../PropertyDetails.module.css'
import ContactAgentCard from './ContactAgentCard'
import HomeDetails from './HomeDetails'
import { Footer } from '../../../common'
import PropertyScores from './PropertyScores'
import TransitScores from './TransitScore'
import PriceHistory from './PriceHistory'
import TaxHistory from './TaxHistory'
import NeeedHomeExpertRating from './NeedHomeExpertRating'
import MLSData from './MLSData'
import OverView from './OverView'
import CurrentIncentives from './Current Incentives'
import PricingAndFee from './PricingAndFee'
import FeatureAndFurnishes from './FeatureAndFurnishes'
import PropertySummary from './ProjectSummary'
import FloorPlan from './FloorPlan'
import PropertyHighlights from './PropertyHighlights'
import ThumbnailImages from './ThumbnaiImages'
import CommunityQuestion from './Community'
import BasicDetails from './BasicDetails'

const PropertyDetailsContent = () => {
  return (
    <main>
      <div className={s.listing_mainContainer}>
        <div>
          <ThumbnailImages />
        </div>

        {/* Listing Description Section */}
        <section className={s.ListingDesandContact_main}>
          <div className={s.ListingDes_main}>
            <div>
              <BasicDetails />
            </div>

            <div className='mt-4'>
              <HomeDetails />
            </div>

            <div className='mt-4'>
              <FloorPlan />
            </div>

            <div className='mt-4'>
              <CurrentIncentives />
            </div>

            <div className='mt-4'>
              <PricingAndFee />
            </div>

            <div className='mt-4'>
              <FeatureAndFurnishes />
            </div>

            <div className='mt-4'>
              <PropertySummary />
            </div>

            <div className='mt-4'>
              <CommunityQuestion />
            </div>

            <div className='mt-4'>
              <MLSData />
            </div>

            <div className='mt-5'>
              <TransitScores />
            </div>

            <div className='mt-5'>
              <PropertyScores />
            </div>

            <div className='mt-5'>
              <PriceHistory />
            </div>

            <div className='mt-5'>
              <TaxHistory />
            </div>

            <div className='mt-5'>
              <NeeedHomeExpertRating />
            </div>

            <div className='mt-4'>
              <OverView />
            </div>
            <div className='mt-4'>
              <PropertyHighlights />
            </div>
          </div>

          <div className={s.ContactAgent_main}>
            <ContactAgentCard />
          </div>
        </section>
      </div>
      <div className='mt-5'>
        <Footer />
      </div>
    </main>
  )
}

export default PropertyDetailsContent
