import style from '../../PropertyDetails.module.css'
import { data } from '../../PropertyDetailsData'
import s from './ProjectSummary.module.css'
import { formatPhoneNumber } from '../../../../../actions/commonFunctions'

const PropertySummary = () => {
  return (
    <>
      <div>
        <h2
          className={style.PropDetails_hedings}
        >{`${data?.ProjectSummary?.name} market Summary`}</h2>
        <div className={s.Prop_Des}>
          {data?.ProjectSummary?.Description.map((sentence, index) => (
            <p key={index}>{sentence}</p>
          ))}
          <p>{`Source: ${data?.ProjectSummary?.source}`}</p>
        </div>
      </div>

      <div className='mt-5'>
        <h2
          className={style.PropDetails_hedings}
        >{`${data?.ProjectSummary?.name} sales center`}</h2>

        <div className={`${s.Prop_Des} ${s.PropertySummaryDes}`}>
          <p>{`Address: ${data?.ProjectSummary?.address}`}</p>
        </div>
        <div  className={`${s.Prop_Des} ${s.PropertySummaryDes}`}>
          <p><strong>Availabilty:</strong></p>
          <ul>
          {data?.ProjectSummary?.availability?.map((item,i)=>{
            return(
              <li key={i}>{item}</li>
            )
          })}
          </ul>
        </div>
        <div className={`${s.Prop_Des} ${s.PropertySummaryDes}`}>
          <p><strong>Phone: </strong>
          <span>{formatPhoneNumber(data?.ProjectSummary?.Phone)}</span>
          </p>
        </div>
        <div className={`${s.Prop_Des} ${s.PropertySummaryDes}`}>
          <p><strong>Email: </strong>
          <a href='/'>{data?.ProjectSummary?.Email}</a>
          </p>
        </div>
        <div className={`${s.Prop_Des} ${s.PropertySummaryDes}`}>
          <p><strong>Links: </strong>
          <span>{data?.ProjectSummary?.Links}</span>
          </p>
        </div>
        <div className={`${s.Prop_Des} ${s.PropertySummaryDes}`}>
          <p><strong>Contact: </strong>
          <span>{data?.ProjectSummary?.contact}</span>
          </p>
        </div>
      </div>
    </>
  )
}

export default PropertySummary
