import { useNavigate } from 'react-router-dom'
import s from '../../../pages/Professionals/Professionals.module.css'
import { AgentRatingStar, AgentCall } from '../../../svgIcons/Icons'

const ProfessionalsCard = ({ data, deatilsPath }) => {
  const navigate = useNavigate()

  const handleDetails = id => {
    navigate(`/${deatilsPath}/${id}`)
  }

  return (
    <div className={s.Agent_cards}>
      {data?.map((item, i) => {
        return (
          <div className={s.Agent_card} key={i}>
            <div
              className='d-flex w-100'
              onClick={() => handleDetails(item?.id)}
            >
              <div className={s.Agent_cardprofile}>
                <img
                  src={`/assets/images/CoShopping1.jpeg`}
                  alt='AgentProfilePic'
                />
              </div>
              <div className={s.AgentCardContent}>
                <h3>{item?.name}</h3>
                <p className={s.AgentTag}>{item?.location}</p>
                <div className={`${s.Agent_rating} ${s.esAgent_rating}`}>
                  <AgentRatingStar />
                  <p>
                    {item?.rating} <span>{`(${item?.reviews} Reviews)`}</span>
                  </p>
                  <div className={s.esLan_speaking}>
                    <p>{`${item?.language} Speaking`}</p>
                  </div>
                </div>
                {item?.status && (
                  <div className={`${s.Agent_Status}`}>
                    <p>{item?.status}</p>
                  </div>
                )}
                {item?.company && (
                  <div className={`${s.Agent_Company}`}>
                    <p>{item?.company}</p>
                  </div>
                )}
              </div>
            </div>
            {deatilsPath === 'advisor-details' && (
              <div className={s.AgentCallIcon}>
                <AgentCall />
              </div>
            )}
          </div>
        )
      })}
    </div>
  )
}
export default ProfessionalsCard
