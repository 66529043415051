import * as React from 'react';
import dayjs from 'dayjs';
import { DemoContainer, DemoItem } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

export default function DatePick({mainclassName,dateChange,setDateChange}) {
  const currentDate = dayjs();

const handleDateChange=(selectedDate)=>{
  setDateChange(selectedDate)
}
  return (
   <div className={mainclassName}>
     <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DemoContainer
        components={[
          'TimePicker',
        ]}
      >
        <DemoItem>
          <DatePicker defaultValue={currentDate} onChange={(e)=>handleDateChange(e)}/>
        </DemoItem>
      </DemoContainer>
    </LocalizationProvider>
   </div>
  );
}
