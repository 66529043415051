import s from './FloorPlan.module.css'
import style from '../../PropertyDetails.module.css'
import {
  ArrowRight,
  BathOutlineIcon,
  BedOutlineIcon,
  FloorIcon,
  ParkingIcon,
  SqftIcon
} from '../../../../svgIcons/Icons'
import { data } from '../../PropertyDetailsData'
import { formatNumber } from '../../../../../actions/commonFunctions'
import { NavLink } from 'react-router-dom'

const FloorPlan = () => {
  const perSqftPrice = Math.round(data?.priice / data?.sqft)
  return (
    <div className='w-100'>
      <h2 className={style.PropDetails_hedings}>Floor Plan</h2>

      <div className={s.FloorPlanCard}>
        <div style={{display:'flex',width:'55%'}}>
          <div className={s.FloorCard_icon}>
            <FloorIcon size={'48'} color={'#113A00'} />
          </div>
          <div className={s.card_des}>
            <h6 className={style.sub_headings}>{data?.propName}</h6>
            <div className={s.card_IconAndDes}>
              <p>
                <BedOutlineIcon size={'24'} color={'#7e7e7e'} />
                <span>{data?.bed}</span>
              </p>
              <p>
                <BathOutlineIcon size={'24'} color={'#7e7e7e'} />
                <span>{data?.bed}</span>
              </p>
              <p>
                <ParkingIcon size={'20'} color={'#7e7e7e'} />
                <span>{data?.bed}</span>
              </p>
              <p>
                <SqftIcon size={'20'} color={'#7e7e7e'} />
                <span>{data?.bed}</span>
              </p>
            </div>
          </div>
        </div>
    
    <div style={{display:'flex' , justifyContent:'space-between',width:'45%',alignItems:'center'}}>
    <div>
          <p>{`From $${formatNumber(data?.priice)}`}</p>
        </div>
        <div>
          <p>{`$${formatNumber(perSqftPrice)}/Sqft`}</p>
        </div>
        <div>
          <NavLink>
            <div className={s.card_arrow}>
              <ArrowRight color={'#222'} size={'20'} />
            </div>
          </NavLink>
        </div>
    </div>
      </div>
    </div>
  )
}

export default FloorPlan
