import {
  isValidPhoneNumber,
  formatPhoneNumberIntl
} from 'react-phone-number-input'

export const validateEmail = email => {
  const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
  return regex.test(email)
}

export const validatePassword = password => {
  const regex = /^(?=.*[0-9a-zA-Z]).{8,}$/
  return regex.test(password)
}

export const validatePhoneNumber = phoneNumber => {
  return isValidPhoneNumber(`+${phoneNumber}`)
}

export const formatPhoneNumber = input => {
  return formatPhoneNumberIntl(`+${input}`)
}

export const formatNumber = number => {
  return number?.toLocaleString('en-Us')
}
