import s from '../../galleryModal.module.css'
import mapboxgl from 'mapbox-gl'
import { useState, useRef, useEffect } from 'react'
import {
  GlobalIcon,
  MinusSignIcon,
  PlusSignIcon
} from '../../../../../svgIcons/Icons'

const ListingGalleryMap = () => {
  mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_TOKEN
  const mapContainer = useRef(null)
  const map = useRef(null)
  const marker = useRef(null)
  const longitude = -93.0402687
  const latitude = 44.8727874
  //   const [dataRec, setDataRec] = useState(false)
  const [mapStyle, setMapStyle] = useState('mapbox://styles/mapbox/streets-v12')

  useEffect(() => {
    const initializeMap = () => {
      if (!map.current) {
        map.current = new mapboxgl.Map({
          container: mapContainer.current,
          style: mapStyle,
          center: [longitude, latitude],
          zoom: 12
        })

        marker.current = new mapboxgl.Marker({
          color: 'red'
        })
          .setLngLat([longitude, latitude])
          .addTo(map.current)
        // setDataRec(true)
      } else {
        marker.current.setLngLat([longitude, latitude])
      }
    }

    if (typeof longitude === 'number' && typeof latitude === 'number') {
      initializeMap()
    }
    //  else {
    //   setDataRec(false)
    // }

    return () => {
      if (map.current) {
        map.current.remove()
        map.current = null
      }

      if (marker.current) {
        marker.current.remove()
        marker.current = null
      }
    }
  }, [mapStyle, longitude])

  const toggleMapStyle = () => {
    setMapStyle(prevStyle =>
      prevStyle === 'mapbox://styles/mapbox/streets-v12'
        ? 'mapbox://styles/mapbox/satellite-streets-v12'
        : 'mapbox://styles/mapbox/streets-v12'
    )
  }

  return (
      <div className={s.gallery_map}>
        <div
          ref={mapContainer}
          className='map-container'
          style={{
            width: '100%',
            height: '100%',
            borderRadius: '20px'
          }}
        />
        <div className='d-flex justify-content-center align-items-center mt-3'>
          <button
            onClick={toggleMapStyle}
            className={`${s.Map_satelliteBtn} button`}
          >
            <GlobalIcon />
          </button>
          <div className={s.gallery_mapZoomBtn}>
            <button className='button' onClick={() => map.current.zoomOut()}>
              <MinusSignIcon />
            </button>
            <span>|</span>
            <button className='button' onClick={() => map.current.zoomIn()}>
              <PlusSignIcon />
            </button>
          </div>
        </div>
      </div>
  )
}

export default ListingGalleryMap
