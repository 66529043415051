import { HeartOutlineIcon } from '../../../svgIcons/Icons'

const FavouriteBtn = ({mainClassName}) => {
  return (
    <div>
      <button className={mainClassName}>
        <HeartOutlineIcon />
      </button>
    </div>
  )
}

export default FavouriteBtn
