import s from '../../../pages/Co-Shopping/CoShopping.module.css'

const CoShoppingAndNotesCard = ({item}) => {
  return(
    <div className={s.card} >
    <div className={s.cardHeading}>
      <h4>Co-Shopper</h4>
      {item?.status && <p>{`(${item?.status})`}</p>}
    </div>
    <div className={`${s.card_Profile} mt-2`}>
      <img src={item?.image} alt='coShopperImg' />
      <div className='ms-3'>
        <h6>{item?.name}</h6>
        <p>{item?.email}</p>
      </div>
    </div>
    <div className={`${s.Share_card} mt-4 w-100`}>
      <p>Your saved Favorites and Notes will be shared.</p>
      <button className={`button`}>Remove</button>
    </div>
  </div>
  )
}

export default CoShoppingAndNotesCard;