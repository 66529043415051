import s from '../../Professionals.module.css'
import { CustomSelect } from '../../../../common'
import { PlusSignIcon } from '../../../../svgIcons/Icons'
import {
  AgentReviewsSorting,
  RatingStarData
} from '../../../../../data/constant'

const RatingReviewsFilter = ({
  options,
  reviewsFilter,
  sortByFilter,
  ratingFilter,
  setRatingFilter,
  setReviewsFilter,
  setSortByFilter,
  setAddReviews
}) => {
  const handleRatingFilter = event => {
    setRatingFilter(event.target.value)
  }

  const handleReviewsFilter = event => {
    setReviewsFilter(event.target.value)
  }

  const handleSortByFilter = event => {
    setSortByFilter(event.target.value)
  }

  return (
    <>
      <div className={s.Rating_Section}>
        <div className={s.Rating_heading}>
          <h5>Rating and Reviews {`(19)`}</h5>
        </div>
        <div
          className={`${s.Addreview_btn}`}
          onClick={() => setAddReviews(true)}
        >
          <button className='button'>
            <PlusSignIcon color={'#ffff'} />
           <span> Add your review</span>
          </button>
        </div>
      </div>

      <div className='row'>
        <div className='col-md-4 col-12'>
          <CustomSelect
            name={'Filter Reviews'}
            onChange={handleReviewsFilter}
            value={reviewsFilter}
            mainClassName={s.Agent_ProdropDown}
            options={options}
          />
        </div>
        <div className='col-md-4 col-12'>
          <CustomSelect
            name={'Rating'}
            onChange={handleRatingFilter}
            value={ratingFilter}
            mainClassName={s.Agent_ProdropDown}
            options={RatingStarData}
          />
        </div>
        <div className='col-md-4 col-12'>
          <CustomSelect
            name={'Sort by'}
            onChange={handleSortByFilter}
            value={sortByFilter}
            mainClassName={s.Agent_ProdropDown}
            options={AgentReviewsSorting}
          />
        </div>
      </div>
    </>
  )
}

export default RatingReviewsFilter
